
import ContainerBase from './containerBase/containerBase'
import LoginComponent from './login/login'
import LockScreenComponent from './lockscreen/lockscreen'
import HelperComponent from './helper/helper'
import AthletesComponent from './athletes/athletes'
import DocumentsComponent from './documents/documents'
import EnrollmentsComponent from './enrollments/enrollments'
import EnrollmentDetailComponent from './enrollments/enrollments.detail'
import AddEnrollmentsFormComponent from './enrollments/enrollments.add'
import RoutinesComponent from './routines/routines'
import HistoryComponent from './history/history'
import AddAthleteFormComponent from './athletes/athletes.add'
import StepByStepComponent from './stepByStep/stepByStep'
import InfoAthleteFormComponent from './athletes/athletes.info'
import ContactFormComponent from './contact/contactForm'
import AlertsComponent from './alerts/alertsComponent'
import DrawerInsideComponent from './DrawerInside/DrawerInside'
import ProfileComponent from './profile/profileComponent'
import RegisterComponent from './register/registerComponent'
import RfeaComponent from './rfea/rfeaComponent'
import CounterComponent from './counter/counterComponent'
import ExerciceBoxComponent from './exercices/exerciceBoxComponent'
import SmileyComponent from './result/smilleyComponent'
import DiscountsAlertComponent from './discounts/DiscountsAlertComponent'
import EnrollmentFinantialDetailsComponent from './enrollments/enrollments.finantial.details'
import CookiesComponent from './cookies/CookiesComponent'
import GroupsComponent from './groups/GroupsComponent'

//#region RRHH Login/Register Components
    import GoogleLoginComponent from './google/googleLoginComponent/googleLoginComponent'
    import FacebookLoginComponent from './facebook/facebookLoginComponent/facebookLoginComponent'
//#endregion

//#region Payments Method Components and GateWay
    import StripeRedirectToGateWayComponent from './payments/stripe/StripeRedirectToGateWayComponent';
    import StripeElementWrapperComponent from './payments/stripe/StripeElementWrapper'
    import StripeRegisterBankAccountForm from './payments/stripe/bank/StripeRegisterBankAccountForm'
    import StripeRegisterCreditCardForm from './payments/stripe/card/StripeRegisterCreditCardForm'
    import StripeCreditCardInfoComponent from './payments/stripe/card/StripeCardInfo'
    import StripeBankAccountInfoComponent from './payments/stripe/bank/StripeBankAccountInfo'
    import CheckoutComponent from './payments/checkout/checkoutComponent'
    import CheckoutComponentV2 from './payments/checkoutv2/CheckoutComponentV2'
    import CheckoutComponentV3 from './payments/checkoutv3/CheckoutComponentV3'
    import NavigatorComponent from './payments/checkout/navigator/navigatorComponent'
    import AvailableProductComponent from './payments/checkout/availableProducts/availableProductComponent'

//#endregion


export const Container = ContainerBase  
export const Login = LoginComponent
export const LockScreen = LockScreenComponent
export const Helper = HelperComponent
export const Athletes = AthletesComponent
export const Documents = DocumentsComponent
export const Enrollments = EnrollmentsComponent
export const EnrollmentDetail = EnrollmentDetailComponent
export const Routines = RoutinesComponent
export const History = HistoryComponent
export const AddAthleteForm = AddAthleteFormComponent
export const AddEnrollmentsForm = AddEnrollmentsFormComponent
export const StepByStep = StepByStepComponent; 
export const InfoAthleteForm = InfoAthleteFormComponent;
export const ContactForm = ContactFormComponent;
export const Alerts = AlertsComponent;
export const DrawerInside = DrawerInsideComponent;
export const Profile = ProfileComponent;
export const Register = RegisterComponent;
export const Rfea = RfeaComponent;
export const Counter = CounterComponent;
export const ExerciceBox = ExerciceBoxComponent;
export const SmileyResult = SmileyComponent;
export const DiscountsAlert = DiscountsAlertComponent;
export const EnrollmentFinantialDetails = EnrollmentFinantialDetailsComponent;
export const Cookies = CookiesComponent;
export const Groups = GroupsComponent;


//#region RRHH Login/Register Components
    export const GoogleLogin = GoogleLoginComponent;   
    export const FacebookLogin = FacebookLoginComponent; 
//#endregion

//#region Payments Methods Components
    export const StripeElementWrapper = StripeElementWrapperComponent;
    export const StripeRegisterCreditCard = StripeRegisterCreditCardForm;
    export const StripeRegisterBankAccount = StripeRegisterBankAccountForm;
    export const StripeCreditCardInfo = StripeCreditCardInfoComponent;
    export const StripeBankAccountInfo = StripeBankAccountInfoComponent;
    export const StripeRedirectToGateWay = StripeRedirectToGateWayComponent;
    export const Checkout = CheckoutComponent;
    export const CheckoutV2 = CheckoutComponentV2;
    export const CheckoutV3 = CheckoutComponentV3;
    export const Navigator = NavigatorComponent;
    export const AvailableProduct = AvailableProductComponent;
//#endregion