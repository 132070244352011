import { CalendarOutlined, DribbbleOutlined, QuestionCircleOutlined, ReloadOutlined, SmileOutlined } from '@ant-design/icons';
import { Button, Card, Col, Empty, Row, Skeleton, Tag, Tooltip, Typography } from 'antd';
import React, { useEffect, useState } from 'react'
import RestApiClient from '../../api/restApiClient';
import ApiResponse from '../../models/api/ApiResponse';
import Athlete from '../../models/Athletes';
import Group from '../../models/Group';

import './GroupsComponent.scss'

const { Title, Paragraph } = Typography
const { Meta } = Card

interface GroupsComponentProps{}
const GroupsComponent = (props: GroupsComponentProps) : JSX.Element => {
    const restApiClient : RestApiClient = new RestApiClient();

    const [loadingGroups, setLoadingGroups] = useState<boolean>(false);
    const [groups, setGroups] = useState<Group[]>([]);
    const getMyGroups = () : void => {
        setLoadingGroups(true);
        restApiClient.fetch("GetUserGroups", {}).then((r : ApiResponse | null)=> {
            if (r && r.code === 200){
                setGroups(r.data);
            }
        }).finally(()=>{
            setLoadingGroups(false);
        })
    }

    const getEmpty = () : JSX.Element => {
        if (groups.length == 0){
            return  <Col xs={24}>
                        <br/>
                        <Empty
                            image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                            imageStyle={{
                            height: 60,
                            }}
                            description={
                            <span>
                            Nada que mostrar aún. ¡Estate atento a próximas alertas!
                            </span>
                            }
                        >
                        </Empty>
                        <br/>
                    </Col>
        }else{
            return <></>
        }
    }

    const getActions = (groupData: Group) : any => {

        let result : any[] = [];

        result.push(
            <Tooltip placement="top" title="Su inscripción está activa">
                <SmileOutlined className="enrollment-box-icon active" />
            </Tooltip>
        )

        result.push(
            <Tooltip placement="top" title="Su inscripción está activa">
                <SmileOutlined className="enrollment-box-icon active" />
            </Tooltip>
        )


        return result;
    }


    useEffect(()=> {
        getMyGroups();
    }, []);

    return (
        <Row gutter={16} className="groups-component">
            <Col xs={24}>
                <Title className="c-title"  level={3}>
                    <CalendarOutlined /> &nbsp;
                    Tus clases
                    <small className="c-sub-title">
                        Para que no te despites!
                    </small>
                </Title>
                <Button className="btn-reload" type="primary" onClick={()=>{getMyGroups()}} >
                    {
                        loadingGroups ?
                            <ReloadOutlined spin />
                        :
                            <ReloadOutlined />
                    }
                </Button>
            </Col>
            <Skeleton active loading={loadingGroups}>
                {  getEmpty() }
                {
                    groups.map((group : Group)=>{
                        return <Col xs={24} md={11} lg={11} key={group.id}>
                                <Card
                                    style={{width: "100%", marginTop: 16}}
                                    //actions={getActions(group)}
                                >
                                    <Meta
                                        title={ <>
                                                {`${group.description}`}
                                                </>
                                            }
                                        description={
                                            <div style={{marginBottom: "10px"}}>
                                                {
                                                    group.athletes.map((athlete: Athlete, index: number) => {
                                                        return <Tag key={index} style={{marginBottom: "5px"}}>
                                                                {athlete.surname} {athlete.secondSurname}, {athlete.name}
                                                            </Tag>
                                                    })
                                                }
                                            </div>
                                        } />
                                        <>
                                                <small className="block"><span><strong>Centro: </strong>{group.sportCenter.description}</span></small>
                                                <small className="block"><span><strong>Localización: </strong><a target='_blank' href={`https://www.google.com/maps/place/${group.sportCenter.address}`}>{group.sportCenter.address}</a></span></small>
                                                <small className="block"><span><strong>Contacto: </strong><a href={`tel:${group.sportCenter.phone}`}>{group.sportCenter.phone}</a> | <a href={`mailto:${group.sportCenter.email}`}>{group.sportCenter.email}</a></span></small>
                                                <small className="block"><span><strong>Días: </strong>{group.weekDays}</span></small>
                                                <small className="block">
                                                    <span><strong>Profesor(es):&nbsp;</strong>
                                                    {group.trainers.map((trainer: any, index: number)=>{
                                                        const trainerName : string = `${trainer.name} ${trainer.surname} ${trainer.secondSurname}`;

                                                        return <Tag key={index}>{trainerName}</Tag>;
                                                    })}
                                                    </span>
                                                </small>
                                                
                                                <small className="block"><strong>Horario:</strong> {group.timeToStart} a {group.timeToEnd}</small> 
                                        </>
                                </Card>
                            </Col>
                        
                    })
                }
            </Skeleton>
            
        </Row>
    )
}

export default GroupsComponent;