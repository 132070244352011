import { ReconciliationOutlined, SmileOutlined, MobileOutlined, HeartOutlined} from '@ant-design/icons';
import { Tooltip } from 'antd';
import React from 'react'


interface LoginHelperProps{}

const LoginHelperComponent = (props: LoginHelperProps): JSX.Element => {

    const goOmnicode = () : void => {
        window.open('https://www.omnicode.es','_blank')
    }

    return (
        <ul className="helper-balls">
            <Tooltip placement="left" title="Interfaces más simples">
            <li>
                <SmileOutlined />
            </li>
            </Tooltip>
            <Tooltip placement="left" title="Todas tus inscripciones">
            <li>
                <ReconciliationOutlined />
            </li>
            </Tooltip>
            <Tooltip placement="left" title="Multidispositivo">
            <li>
                <MobileOutlined />
            </li>
            </Tooltip>
            <Tooltip placement="left" title="Hecho con amor por el equipo de Omnicode.es">
            <li className="heart" onClick={goOmnicode}>
                <HeartOutlined />
            </li>
            </Tooltip>
        </ul>
    )
}

export default LoginHelperComponent;