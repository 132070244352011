import React, { useContext } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import { AppCfgContext } from '../../../hooks/context/appConfigContext'

interface StripeElementWrapperProps {
    children: React.ReactNode
}



const StripeElementWrapperComponent = (props : StripeElementWrapperProps): JSX.Element => {
    const appConfigContext = useContext(AppCfgContext);

        
    const stripePromise = loadStripe(appConfigContext.cfg.stripePublicKey);

    // All elements that use useStripe must be wrapper with this component
    return (
        <Elements stripe={stripePromise}>
            {props.children}
        </Elements>
    );
}

export default StripeElementWrapperComponent;