import React, { useEffect, useState } from 'react';

import { Button } from 'antd'
import { Step } from '../../models/Step'



interface StepByStepProperties{
    steps: Step[]
    width: string,
    height: string,
    containerId: string,
    visible: boolean,
    update: number
}

const StepByStepComponent = (props : StepByStepProperties) : JSX.Element => {

    const [steps, setSteps] = useState<Step[]>([]);
    const [current, setCurrent] = useState<Step>();
    const [visible, setVisible] = useState<boolean>(false);

    const isInViewPort = (positions: DOMRect) : boolean => {
        const isInViewport = positions.top >= 0 &&
                             positions.left >= 0 &&
                             positions.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                             positions.right <= (window.innerWidth || document.documentElement.clientWidth);

        return isInViewport;
    } 

    const goCurrent = () : boolean => {
        var container = document.getElementById(props.containerId);

        if (!container || !current){
            return false;
        }
        // Elemento a buscar:
        const element = document.getElementById(current.id)

        // El stepByStep:
        const stepByStep = document.getElementById("step-by-step");

        if (!element || !stepByStep){
            return false;
        }


        // Los datos de posición y ancho del elemento a buscar:
        let elementPositions = element.getBoundingClientRect();


        // Los datos de posición y ancho del step by step:
        const stepByStepsPositions = stepByStep.getBoundingClientRect();

        // Hacemos scroll hasta el elemento contenedor si el elemento está fuera de la pantalla
        if (!isInViewPort(elementPositions)){
            container.scrollTo(elementPositions.x, elementPositions.y);
            elementPositions = element.getBoundingClientRect();
        }

        // Colocamos el stepByStep en su lugar: 
        stepByStep.style.top = `${(elementPositions.y - (stepByStepsPositions.height / 2) + (elementPositions.height / 2))}px`;
        stepByStep.style.left = `${(elementPositions.x - (stepByStepsPositions.width / 2) + (elementPositions.width / 2))}px`;

        return true;
    }

    const closeHelp = () : void => {
        setVisible(false);
    }

    const goNext = () : boolean => {
        if (!current){
            return false;
        }

        let currentIndex = steps.indexOf(current);

        if (currentIndex !== -1 && (currentIndex + 1) < steps.length){
            currentIndex++;
            setCurrent(steps[currentIndex]);
        }else{
            return false;
        }

        return true;
    }

    const goPrevious = () : boolean => {
        if (!current){
            return false;
        }

        let currentIndex = steps.indexOf(current);


        if (currentIndex-1 >= 0){
            currentIndex--;
            setCurrent(steps[currentIndex]);
        }else{
            return false;
        }

        return true;
    }

    useEffect(()=> {
        if (current){
            goCurrent();
        }
    }, [current])


    useEffect(()=>{
        if (props.visible && props.steps && props.steps.length > 0){
            setVisible(props.visible);
            setSteps(props.steps);
            setCurrent(props.steps[0]); 
        }
    }, []);

    useEffect(()=>{
        if (props.visible && props.containerId){
            var container = document.getElementById(props.containerId);
            window.removeEventListener('resize', goCurrent);
            window.addEventListener('resize', goCurrent);
        }
    }, []);

    useEffect(()=>{
        goCurrent();
    }, [props.update])

    return(
        <div id="step-by-step" className={`step-by-step ${current?.position} ${!visible ? 'finish' : ''}`} style={{width:props.width, height: props.height}}>
            <div id="step-by-step-container">
                <div className="header">
                    {current?.header}
                </div>
                <div className="body">
                    {current?.body}
                </div>
                <div className="footer">
                    {current?.footer}
                    <div className="navigation">
                        <Button className="prev" onClick={goPrevious}>Anterior</Button>
                        <Button className="next" onClick={goNext}>Siguiente</Button>
                        <Button className="finish" onClick={closeHelp}>Cerrar</Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StepByStepComponent;