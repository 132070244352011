import React, { useState } from 'react'


import './App.css';
import './styles/theme.scss'
import './styles/custom.scss'

import { PrivateRoutesComponent } from './components/core/routes/PrivateRoutes'
import { PublicRoutesComponent } from './components/core/routes/PublicRoutes'

import { LoginContext, userDefault } from './hooks/context/userContext'
import { AthleteListContext, athletesDefault, AthleteContextClass } from './hooks/context/athleteContext'

import User from './models/Users';

// App
function App() {

  const [ user ] = useState<User>(userDefault)
  const [ athletes ] = useState<AthleteContextClass>(athletesDefault)

  return (
        <LoginContext.Provider value={user}>
            {
                user && user.isLoged ? 
                  <AthleteListContext.Provider value={athletes}>
                    <PrivateRoutesComponent />
                  </AthleteListContext.Provider>
                :
                  <PublicRoutesComponent />
            }
        </LoginContext.Provider>
  );
}

export default App;
