import React, { useEffect, useState } from "react";
import AppCfg from "../../models/AppCfg";
import RestApiClient from "../../api/restApiClient";
import ApiResponse from "../../models/api/ApiResponse";
import { Button, Col, Result, Row, Typography } from "antd";
import { FrownTwoTone } from "@ant-design/icons";

import * as Components from '../../components/index'

import './RemoveAccountPage.scss'
import { useHistory } from "react-router-dom";

const { Title } = Typography;

const RemoveAccountPage = () : JSX.Element => {
    const restApiClient : RestApiClient = new RestApiClient();
    const history = useHistory();
    
    const [loadingConfig, setLoadingConfig] = useState<boolean>(false);
    const [errorInConfig, setErrorInConfig] = useState<boolean>(false);
    const [messageLoading, setMessageLoading] = useState<any>(<p>Por favor, espere, cargando la aplicación...</p>);
    const [app, setApp] = useState<AppCfg>();
    
    const checkAppConfig = () : boolean => {
        let found : boolean = false;
        const actualConfig : string | null = sessionStorage.getItem("app-config");
        if (actualConfig){
            found = true;
            setApp(JSON.parse(actualConfig) as AppCfg);
            setErrorInConfig(false);
            setLoadingConfig(false);
        }

        return found;
    }

    const GetAppConfig = () : void => {
        setErrorInConfig(false);
        setLoadingConfig(true);
        setMessageLoading(<p>Conectando con tu club...</p>);

        checkAppConfig()
        restApiClient.fetch("GetAppConfig", {})
                .then((r : ApiResponse | null)=> {
                    if (r !== null){
                        const code : number = r.code; 
                        setApp(r.data as AppCfg);
                        sessionStorage.setItem("app-config", JSON.stringify(r.data));
                        var favicon = document.getElementById("favicon");

                        if (favicon){
                            favicon?.setAttribute("href", (r.data as AppCfg).cfg.urlsShieldImg);
                        }

                        switch(r.data.status){
                            case 'active':
                                break;
                            case 'unpayment':
                            case 'inactive':
                                setErrorInConfig(true);
                                setMessageLoading(appNotAvailable);
                                break;
                            default:
                                setErrorInConfig(true);
                                setMessageLoading(appInManteinance);
                                break;
                        }
                    }
                    
                })
                .catch(reason => {
                    setErrorInConfig(true);
                    setMessageLoading(appFail);
                })
                .finally(()=>{
                    setLoadingConfig(false);
                })
    }

    const appInManteinance : JSX.Element = <Result
        status="403"
        style={{color: "white"}}
        title={<span className="text-white">Realizando tareas de mantenimiento</span>}
        subTitle={<span  className="text-white">Estamos trabajando en mejorar la aplicación. Vuelve en unos minutos y vuelve a intentarlo.</span>}
        extra={<Button type="primary" onClick={GetAppConfig}>Vuelve a intentarlo</Button>}
    />

    const appFail : JSX.Element = <Result
        icon={<FrownTwoTone twoToneColor="#eb2f96" />}
            title={<span className="text-white">Esta página no tiene actualmente servicio. Por favor, ponte en contacto con tu club para saber más.</span>}
        extra={<Button type="primary" onClick={GetAppConfig}>Vuelve a intentarlo</Button>}
        />

    const appNotAvailable : JSX.Element = <Result
                                                style={{color: "white"}}
                                                status="500"
                                                title={<span className="text-white">No hemos podido obtener la configuración de tu club.</span>}
                                                subTitle={<span className="text-white">Ups... Parece que la aplicación no está disponible en estos momentos. Por favor, inténtalo más tarde y si el problema persiste habla con alguien en tu club.</span>}
                                                extra={<Button type="primary" onClick={GetAppConfig}>Vuelve a intentarlo</Button>}
                                            />
    
    const goLogin = (event: any) : void => {
        history.push('/login');
    }
    
    useEffect(()=>{
        GetAppConfig();
    },[])
                                            
    return (
        <div className="remove-account-page">
            <Components.Cookies />
            {
                loadingConfig || errorInConfig || app === undefined? 
                    <Row gutter={16} className="loading">
                        <Col xs={24} className="loading-container">
                        { loadingConfig ? <div className="lds-ripple"><div></div><div></div></div> : '' }
                        <Title level={3} className="message">
                            {messageLoading}
                        </Title>
                        </Col>
                            <div className="my-waves">
                                <div className="waves wave-1">
                                    <svg width="100%" height="400px" fill="none" version="1.1"
                                    xmlns="http://www.w3.org/2000/svg">
                                        <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="0%">
                                            <stop offset="0%" stop-color="#93dbe9" />
                                            <stop offset="50%" stop-color="#689cc5" />
                                            <stop offset="100%" stop-color="#5e6fa3" />
                                        </linearGradient>
                                        <path 
                                        fill="url(#grad1)" 
                                        d="
                                        M0 67
                                        C 273,183
                                            822,-40
                                            1920.00,106 
                                        
                                        V 400 
                                        H 0 
                                        V 67
                                        Z">
                                        <animate 
                                        repeatCount="indefinite" 
                                        fill="url(#grad1)" 
                                        attributeName="d" 
                                        dur="15s"
                                        attributeType="XML"
                                        values="
                                            M0 77 
                                            C 473,283
                                            822,-40
                                            1920,116 
                                            
                                            V 400 
                                            H 0 
                                            V 67 
                                            Z; 

                                            M0 77 
                                            C 473,-40
                                            1222,283
                                            1920,136 
                                            
                                            V 400 
                                            H 0 
                                            V 67 
                                            Z; 

                                            M0 77 
                                            C 973,260
                                            1722,-53
                                            1920,120 
                                            
                                            V 400 
                                            H 0 
                                            V 67 
                                            Z; 

                                            M0 77 
                                            C 473,283
                                            822,-40
                                            1920,116 
                                            
                                            V 400 
                                            H 0 
                                            V 67 
                                            Z
                                            ">
                                        </animate>
                                    </path>
                                    </svg>
                                </div>
                                <div className="waves wave-2">
                                    <svg width="100%" height="400px" fill="none" version="1.1"
                                    xmlns="http://www.w3.org/2000/svg">
                                        <linearGradient id="grad2" x1="0%" y1="0%" x2="100%" y2="0%">
                                            <stop offset="0%" stop-color="#5e6fa3" />
                                            <stop offset="50%" stop-color="#3b4368" />
                                            <stop offset="100%" stop-color="#191d3a" />
                                        </linearGradient>
                                        <path 
                                        fill="url(#grad2)" 
                                        d="
                                        M0 67
                                        C 273,183
                                            822,-40
                                            1920.00,106 
                                        
                                        V 400 
                                        H 0 
                                        V 67
                                        Z">
                                        <animate 
                                        repeatCount="indefinite" 
                                        fill="url(#grad2)" 
                                        attributeName="d" 
                                        dur="4s"
                                        attributeType="XML"
                                        values="
                                            M0 77 
                                            C 473,283
                                            822,-40
                                            1920,116 
                                            
                                            V 400 
                                            H 0 
                                            V 67 
                                            Z; 

                                            M0 77 
                                            C 473,-40
                                            1222,283
                                            1920,136 
                                            
                                            V 400 
                                            H 0 
                                            V 67 
                                            Z; 

                                            M0 77 
                                            C 973,260
                                            1722,-53
                                            1920,120 
                                            
                                            V 400 
                                            H 0 
                                            V 67 
                                            Z; 

                                            M0 77 
                                            C 473,283
                                            822,-40
                                            1920,116 
                                            
                                            V 400 
                                            H 0 
                                            V 67 
                                            Z
                                            ">
                                        </animate>
                                    </path>
                                    </svg>
                                </div>
                                <div className="waves wave-3">
                                <svg width="100%" height="400px" fill="none" version="1.1"
                                xmlns="http://www.w3.org/2000/svg">
                                    <linearGradient id="grad3" x1="0%" y1="0%" x2="100%" y2="0%">
                                        <stop offset="0%" stop-color="#191d3a" />
                                        <stop offset="50%" stop-color="#d9dbee" />
                                        <stop offset="100%" stop-color="#b3b7e2" />
                                    </linearGradient>
                                    <path 
                                    fill="url(#grad3)" 
                                    d="
                                    M0 67
                                    c 273,183
                                        822,-40
                                        1920.00,106 
                                    
                                    V 400 
                                    H 0 
                                    V 67
                                    Z">
                                    <animate 
                                    repeatCount="indefinite" 
                                    fill="url(#grad3)" 
                                    attributeName="d" 
                                    dur="3s"
                                    attributeType="XML"
                                    values="
                                        M0 77 
                                        C 473,283
                                        822,-40
                                        1920,116 
                                        
                                        V 400 
                                        H 0 
                                        V 67 
                                        Z; 

                                        M0 77 
                                        C 473,-40
                                        1222,283
                                        1920,136 
                                        
                                        V 400 
                                        H 0 
                                        V 67 
                                        Z; 

                                        M0 77 
                                        C 973,260
                                        1722,-53
                                        1920,120 
                                        
                                        V 400 
                                        H 0 
                                        V 67 
                                        Z; 

                                        M0 77 
                                        C 473,283
                                        822,-40
                                        1920,116 
                                        
                                        V 400
                                        H 0 
                                        V 67 
                                        Z
                                        ">
                                    </animate>
                                </path>
                                </svg>
                            </div>
                            </div>
                    </Row>
                :
                    <Row className="login" justify="center" align="middle" style={{backgroundImage: `url(${app.cfg.urlsMainImg})`}}>
                        <div style={{position: "absolute", top: 0, left: 0, zIndex: 1, display:"none"}}> 
                            <button onClick={()=>{
                                setLoadingConfig(true);
                                setTimeout(()=>{
                                    setLoadingConfig(false);
                                }, 3000);
                            }}>Force Loading</button>
                            <button onClick={()=>{
                                setErrorInConfig(true);
                                setMessageLoading(appInManteinance);
                                    setTimeout(()=>{
                                        GetAppConfig();
                                    }, 10000);
                            }}>Force Maintenance</button>
                            <button onClick={()=>{
                                setErrorInConfig(true);
                                setMessageLoading(appNotAvailable);
                                    setTimeout(()=>{
                                        GetAppConfig();
                                    }, 10000);
                            }}>Force Not Available</button>
                        </div>
                        <Components.Helper location="login" />
                        <Col className="container" xs={24} sm={24} md={15} lg={12} xl={9} xxl={6}>
                            <Row className="container-row">
                                <Col xs={24} className="header" style={{backgroundImage: `url(${app.cfg.urlsMainImg})`}}>
                                    
                                </Col>
                                <Col xs={24} className="form">
                                    <div className="circle-title" style={{backgroundImage: `url(${app.cfg.urlsShieldImg})`}}>
                                        <span>
                                        </span>
                                    </div>
                                    
                                    <p>
                                        Si desea eliminar su cuenta pógase en contacto con su centro a través del siguiente correo
                                    </p>
                                    <p className="email">
                                        <a href={`mailto:${app.cfg.emailTechnicalSupport}`}>{app.cfg.emailTechnicalSupport}</a>
                                    </p>
                                    <p>
                                        En un plazo no superior a <strong>48 horas hábiles</strong> responderemos a su solicitud.
                                    </p>
                                    <p>
                                        Recuerde que <u>si tiene alguna inscripción activa esta <strong>deberá ser abonada completamente</strong></u> antes de poder 
                                        eliminar su cuenta.
                                    </p>
                                    <Button className="go-login" type="primary" onClick={goLogin}>Ir al login</Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
            }
        </div>
    )
}

export default RemoveAccountPage;