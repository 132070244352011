import React from 'react'
import AppCfg from '../../models/AppCfg'

export let cfgDefault : AppCfg = {
    address: '',
    cfg: {
        cfgPaymentTypes: '',
        colorsSecondary: '',
        colorsMain: '',
        emailCfgUser: '',
        emailTechnicalSupport: '',
        textDescriptions: '',
        textInitials: '',
        urlsLogoImg: '',
        urlsMainImg: '',
        urlsMainWeb: '',
        urlsShieldImg: '',
        textLegalPrivacity: '',
    },
    contactEmail: '',
    contactPhone: '',
    name: ''
} as AppCfg

const sessionAppConfig = sessionStorage.getItem("app-config");

if (sessionAppConfig !== null){
    cfgDefault = JSON.parse(sessionAppConfig) as AppCfg;

    var favicon = document.getElementById("favicon");

    if (favicon){
        favicon?.setAttribute("href", cfgDefault.cfg.urlsShieldImg);
    }
}

export const AppCfgContext = React.createContext(cfgDefault);
