import EndPointConfig from '../../models/api/EndPointConfig'

const controller = 'Documents'

export const DocumentsEP : EndPointConfig[] =[
    
    new EndPointConfig()
        .setMethod('GET')
        .setController(controller)
        .setActionName('GetMyDocuments')
]


