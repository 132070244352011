import React from 'react'
import Athlete from '../../models/Athletes'

export class AthleteContextClass{
    public list : Athlete[] = [];
    public setList(list: Athlete[]){
        this.list = list;
    }
}
export let athletesDefault : AthleteContextClass = new AthleteContextClass();


export let AthleteListContext = React.createContext(athletesDefault);