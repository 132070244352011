import EndPointConfig from '../../models/api/EndPointConfig'

const controller = 'SportCenters'

export const SportCentersEP : EndPointConfig[] =[
    new EndPointConfig()
        .setMethod('GET')
        .setController(controller)        
        .setActionName("GetSportCenters")
]


