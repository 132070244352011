import React,  { useState, useEffect, useContext } from 'react'

import { Row, Col, Empty, Typography,  List, Avatar, Card  } from 'antd'

import { DribbbleOutlined } from '@ant-design/icons'

import Routines from '../../models/Routines'

import User from '../../models/Users'

import { AppCfgContext } from '../../hooks/context/appConfigContext'

import './routines.scss'

const { Title } = Typography

const RoutinesDemo : Routines[] = [
    {
        id: 1,
        title: 'Calentamiento',
        creationDate: new Date(),
        creator: {
            isLoged: false,
            idRol: 'trainer',
            avatar: 'https://i.pravatar.cc/300?u=a042581f4e29026704e',
            isDummyAccount: false, 
            name: 'Fabián',
            surname: 'Plaza',
            secondSurname: 'Arnau',
        } as User,
        description: 'Ejercicios de calentamiento que servirán para que tu cuerpo entre en calor y no te lesiones cuando estés dándolo todo en la pista.',
        exercises:[
            {
                order: 1,
                name:"Marcha",
                durationTimeSeconds: 3,
                reposeTimeSeconds: 30,
                series: 1,
                repetitionsBySerie: "1 min en la misma posición. 1 min adelante, 1 min hacia atrás",
                description: "Comenzar marchando en una misma posición, luego hacia adelante y hacia atrás, moviendo los brazos hacia arriba y hacia abajo al ritmo de los pasos;",
            },
            {
                order: 2,
                name:"Talón",
                durationTimeSeconds: 60,
                reposeTimeSeconds: 30,
                series: 2,
                repetitionsBySerie: "15 segundos con cada pierna",
                description: "Adelanta una pierna con el talón apoyado en el suelo y el pie hacia arriba, manteniendo una ligera curva en la pierna de apoyo y los brazos elevados perpendiculares al cuero y los puños cerrados",
            },
            {
                order: 3,
                name:"Elevación de rodilla",
                durationTimeSeconds: 20,
                reposeTimeSeconds: 30,
                series: 2,
                repetitionsBySerie: "15 segundos con cada pierna",
                description: "Adelanta una pierna con el talón apoyado en el suelo y el pie hacia arriba, manteniendo una ligera curva en la pierna de apoyo y los brazos elevados perpendiculares al cuero y los puños cerrados",
            },
            {
                order: 4,
                name:"Giro hombros",
                durationTimeSeconds: 20,
                reposeTimeSeconds: 30,
                series: 2,
                repetitionsBySerie: "15 segundos con cada pierna",
                description: "Adelanta una pierna con el talón apoyado en el suelo y el pie hacia arriba, manteniendo una ligera curva en la pierna de apoyo y los brazos elevados perpendiculares al cuero y los puños cerrados",
            },
            {
                order: 5,
                name:"Doblar rodillas",
                durationTimeSeconds: 20,
                reposeTimeSeconds: 30,
                series: 2,
                repetitionsBySerie: "15 segundos con cada pierna",
                description: "Adelanta una pierna con el talón apoyado en el suelo y el pie hacia arriba, manteniendo una ligera curva en la pierna de apoyo y los brazos elevados perpendiculares al cuero y los puños cerrados",
            }
        ],
        totalRutine: "6 minutos",
        starts: 123,
        likes: 300,
        comments: 2,
    },
    {
        id: 2,
        title: 'Cardio',
        creationDate: new Date(),
        creator: {
            idRol: 'trainer',
            isLoged: false,
            avatar: 'https://i.pravatar.cc/300?u=a042581f4e29026704d',
            isDummyAccount: false, 
            name: 'Fabián',
            surname: 'Plaza',
            secondSurname: 'Arnau',
        } as User,
        description: 'Ejercicios de cardio que te ayudarán en el día a día para no tener que sufrir cuando un señor de 80 años te adelante tan solo 10 segundos después de empezar a correr',
        exercises:[
            {
                order: 1,
                name:"Jumpong jacks",
                durationTimeSeconds: 3,
                reposeTimeSeconds: 30,
                series: 1,
                repetitionsBySerie: "1 min en la misma posición. 1 min adelante, 1 min hacia atrás",
                description: "Comenzar marchando en una misma posición, luego hacia adelante y hacia atrás, moviendo los brazos hacia arriba y hacia abajo al ritmo de los pasos;",
                urlVideo: "https://www.youtube.com/watch?v=Bg9yaOYZ6A0&ab_channel=gymvirtual",
            },
            {
                order: 2,
                name:"Salto en estrella",
                durationTimeSeconds: 60,
                reposeTimeSeconds: 30,
                series: 2,
                repetitionsBySerie: "15 segundos con cada pierna",
                description: "Adelanta una pierna con el talón apoyado en el suelo y el pie hacia arriba, manteniendo una ligera curva en la pierna de apoyo y los brazos elevados perpendiculares al cuero y los puños cerrados",
                urlVideo: "https://www.youtube.com/watch?v=Bg9yaOYZ6A0&ab_channel=gymvirtual",
            },
            {
                order: 3,
                name:"Sentadillas",
                durationTimeSeconds: 20,
                reposeTimeSeconds: 30,
                series: 2,
                repetitionsBySerie: "15 segundos con cada pierna",
                description: "Adelanta una pierna con el talón apoyado en el suelo y el pie hacia arriba, manteniendo una ligera curva en la pierna de apoyo y los brazos elevados perpendiculares al cuero y los puños cerrados",
                urlVideo: "https://www.youtube.com/watch?v=Bg9yaOYZ6A0&ab_channel=gymvirtual",
            },
            {
                order: 4,
                name:"Tap backs",
                durationTimeSeconds: 20,
                reposeTimeSeconds: 30,
                series: 2,
                repetitionsBySerie: "15 segundos con cada pierna",
                description: "Adelanta una pierna con el talón apoyado en el suelo y el pie hacia arriba, manteniendo una ligera curva en la pierna de apoyo y los brazos elevados perpendiculares al cuero y los puños cerrados",
                urlImage: "https://tumejorfisico.com/wp-content/uploads/2019/03/ezgif-5-a40f69599a.gif",
            },
            {
                order: 5,
                name:"Burpees",
                durationTimeSeconds: 2,
                reposeTimeSeconds: 30,
                series: 2,
                repetitionsBySerie: "15 segundos con cada pierna",
                description: "Adelanta una pierna con el talón apoyado en el suelo y el pie hacia arriba, manteniendo una ligera curva en la pierna de apoyo y los brazos elevados perpendiculares al cuero y los puños cerrados",
                urlImage: "https://tumejorfisico.com/wp-content/uploads/2019/03/ezgif-5-a40f69599a.gif",
            }
        ],
        totalRutine: "3 minutos",
        starts: 13,
        likes: 3,
        comments: 0,
    }
];




interface RoutinesProps {}

const RoutinesComponent = (props : RoutinesProps) : JSX.Element => {
    const appConfigContext = useContext(AppCfgContext);
    const[ routines, setRoutines ] = useState<Routines[]>([]);

    const getEmpty = () : JSX.Element => {
        if (routines.length == 0){
            return <Empty
                        image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                        imageStyle={{
                        height: 60,
                        }}
                        description={
                        <span>
                        Nada que mostrar aún. ¡Estate atento a próximas alertas!
                        </span>
                        }
                    >
                    </Empty>
        }else{
            return <></>
        }
    }

   
    const getRoutines = () : void => {
        //setRoutines(RoutinesDemo);
        setRoutines([])
    }

    useEffect(()=> {
        getRoutines();
    }, []);

    return(
        <Row gutter={16} className="routines">
            <Col xs={24}>
                <Title className="c-title"  level={3}>
                    <DribbbleOutlined /> &nbsp;
                    Academia Virtual
                    <small className="c-sub-title">
                        Gloria en la arena ... virtual
                    </small>
                </Title>
            </Col>
            <Col xs={24}>
                <br />
                {  getEmpty() }
                <br />


                <List
                    itemLayout="vertical"
                    size="large"
                    className="routine-list"
                    pagination={{
                    onChange: page => {
                    },
                    pageSize: 3,
                    }}
                    dataSource={routines}
                    footer={
                    <div>
                        <b>{appConfigContext.name}</b> academia virtual
                    </div>
                    }
                    renderItem={(item : Routines) => (
                    <List.Item
                        className="routine-item"
                        key={item.id}
                        actions={[
                           // <IconText icon={StarOutlined} text={item.starts} key="list-vertical-star-o" />,
                           // <IconText icon={LikeOutlined} text={item.likes} key="list-vertical-like-o" />,
                           // <IconText icon={MessageOutlined} text={item.comments} key="list-vertical-message" />,
                        ]}
                        extra={
                            <Card
                                style={{ width: "100%", marginTop: 16 }}
                                actions={[
                                    <a href="#" onClick={(e)=>{e.preventDefault(); }}>¡Comenzar!</a>,
                                ]}
                                >
                                    <h4>Ejercicios</h4>
                                    <ul>
                                    {
                                        item.exercises.map((e, index) => {
                                            return <li key={index}>{`${e.name} - ${e.durationTimeSeconds} segundos`}</li>
                                        })
                                    }
                                    </ul>
                                </Card>
                        }
                    >
                        <List.Item.Meta className="routine-header"
                            avatar={<Avatar className="avatar" src={item.creator.avatar} />}
                            title={<p className="title">{item.title}</p>}
                            description={<div>
                                            <span className="author">{`${item.creator.name} ${item.creator.surname} ${item.creator.secondSurname}`}&nbsp;|&nbsp;</span>
                                            <span className="resume">{`Ejercicios: ${item.exercises.length} | Duracion: ${item.totalRutine}`}</span>
                                        </div>}
                        />
                        <p className="description">
                            {item.description}
                        </p>
                    </List.Item>
                    )}
                />
            </Col>
        </Row>
    )
}

export default RoutinesComponent;