import { ReloadOutlined, RocketTwoTone } from '@ant-design/icons'
import { Button } from 'antd';
import React, { useEffect, useState } from 'react'
import RestApiClient from '../../api/restApiClient';
import ApiResponse from '../../models/api/ApiResponse';
import ParamsApi from '../../models/api/ParamsApi';
import { Discount } from '../../models/Discount';

import './DiscountsAlertComponent.scss'

interface DiscountsAlertComponentProps{}

const DiscountsAlertComponent = (props : DiscountsAlertComponentProps) : JSX.Element => {
    const restApiClient : RestApiClient = new RestApiClient();
    
    const [availableDiscounts, setAvailableDiscounts] = useState<Discount[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const getDiscountsInfo = () : void => {
        const params : ParamsApi = {
            query: {
                sessionId: sessionStorage.getItem('token'),
            }
        }

        setLoading(true);
        restApiClient.fetch("GetMyDiscounts", params)
                    .then((r : ApiResponse | null)=> {
                        if (r){
                            setAvailableDiscounts(r.data);
                        }
                    }).finally(()=>{
                        setLoading(false);
                    });
    }

    useEffect(()=>{
        getDiscountsInfo();
    }, [])

    return (
        availableDiscounts && availableDiscounts.length > 0 ? 
            <div className="discount-alert">
                <RocketTwoTone twoToneColor="#074376" className="icon"/> 
                
                <div className="body">
                    <h3>¿Sabías que optas a <strong><u>uno</u></strong> de los siguientes descuentos* en la formalización de tu próxima inscripción?</h3>
                    <ul>
                    {
                        availableDiscounts.map((discount : Discount, index: number) => {
                            return <li className="description" key={index}>- {discount.discountDescription}.</li>
                        })
                    }
                    <li className="description">
                        <h4><br/>*Ten en cuenta que los descuentos se aplicarán de manera <strong><u>automática</u></strong> en el momento de realizar el pago de la inscripción.</h4>
                        <h4>*Tras crear una inscripción pulsa el siguiente botón para recargar tus descuentos.</h4>
                    </li>
                    <li className="description">
                        <Button disabled={loading} size="small" className="refresh" type="primary" onClick={getDiscountsInfo}>
                            {
                                loading ? <ReloadOutlined spin /> : ''
                            }
                            Volver a comprobar
                        </Button>
                    </li>
                    </ul>
                </div>
            </div>
            : 
            <></>
        
    )
}

export default DiscountsAlertComponent;