import EndPointConfig from '../../models/api/EndPointConfig'

const controller = 'Discounts'

export const DiscountsEP : EndPointConfig[] =[
    new EndPointConfig()
        .setMethod('GET')
        .setController(controller)        
        .setActionName("GetMyDiscounts"),
]


