export interface IPData{
    IPv4: string,
    city: string,
    country_code: string,
    country_name: string,
    latitude: number,
    longitude: number,
    postal: string,
    state: string
}

const getUserIP = async (): Promise<Response> => {
    let response : any;
    try{
        response = await fetch("https://api.ipify.org/?format=json")
            .then(response=>response.json())
            .then(data=> data)
    }catch(exception){
        console.log("Is not possible get your IP");
        console.error(exception);
    }

    return Promise.resolve(response);
}

export default getUserIP;