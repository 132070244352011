import React from 'react'

import * as Pages from '../../../pages/index'

import {
    BrowserRouter as Router,
    Switch,
    Route,
  } from "react-router-dom";

export const PrivateRoutesComponent = (props : any) => {

    return(
        <Router forceRefresh={true}>
            <Switch>
                <Route path="/routine/:id">
                    <Pages.RoutineDetail />
                </Route>
                <Route path={["/","/dashboard"]} >
                    <Pages.DashBoard />
                </Route>
            </Switch>
        </Router>
    )
}