import React from 'react'
import User from '../../models/Users'

export let userDefault : User = {
    avatar: '',
    isDummyAccount: false,
    isLoged: false,
    name: '',
    secondSurname: '',
    surname: '',
    notificationNumber: 0,
} as User

const sessionUserData = sessionStorage.getItem("user-data");

if (sessionUserData !== null && sessionUserData.length > 0){
    userDefault = JSON.parse(sessionUserData) as User;
}

export const LoginContext = React.createContext(userDefault);
