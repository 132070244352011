import React, { useContext, useEffect, useState } from 'react'

import QRCode from 'react-qr-code'

import { Row, Col, Skeleton, Card, Typography, Button, Empty, Avatar, Drawer } from 'antd'

import { SettingOutlined, ContactsOutlined, SearchOutlined, SecurityScanTwoTone } from '@ant-design/icons'

import Athlete, * as AthletesModel from '../../models/Athletes';

import * as Components from '../../components/'

import DrawerInsideComponent from '../DrawerInside/DrawerInside';

import './athletes.scss'
import RestApiClient from '../../api/restApiClient';
import ParamsApi from '../../models/api/ParamsApi';
import ApiResponse from '../../models/api/ApiResponse';

import moment from 'moment';

import { LoginContext } from '../../hooks/context/userContext'
import { AthleteListContext } from '../../hooks/context/athleteContext'


const { Meta } = Card

const { Title, Paragraph} = Typography


interface AtletesProps{}

const AthletesComponent = (props : AtletesProps) : JSX.Element => {
    

    const loginContext = useContext(LoginContext);
    let athleteListContext = useContext(AthleteListContext);

    const restApiClient : RestApiClient = new RestApiClient();
    
    const [loading, setLoading] = useState<boolean>(true);

    const [athlete, setAthlete] = useState<AthletesModel.default>(AthletesModel.EmptyAthlete);
    //const [athletes, setAthletes] = useState<AthletesModel.default[]>(AthletesModel.FakeAthletes);
    const [athletes, setAthletes] = useState<AthletesModel.default[]>([]);

    const [showRFEA, setShowRFEA] = useState<boolean>(false);
    const [showAdd, setShowAdd] = useState<boolean>(false);
    const [showInfo, setShowInfo] = useState<boolean>(false);

    

    const [drawerWidth, setDrawerWidth] = useState<string | number>("100%");

    const getEmpty = () : JSX.Element => <Empty
                                            image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                                            imageStyle={{
                                            height: 60,
                                            }}
                                            description={
                                            <span>
                                                Aún no has registrado a nadie para guerrear ¿¡A qué esperas!?
                                            </span>
                                            }
                                        >
                                        </Empty>


    const showRFEADrawer = (athlete: Athlete) : void => {
        setShowRFEA(true);
        setAthlete(athlete);
    }

    const hideRFEADrawer = () : void => {
        setShowRFEA(false);
    }

    const showAddDrawer = () : void => {
        setShowAdd(true);
    }

    const hideAddDrawer = () : void => {
        setShowAdd(false);
    }

    const showInfoDrawer = (athlete: Athlete) : void => {
        setShowInfo(true);
        setAthlete(athlete);
    }

    const hideInfoDrawer = () : void => {
        setShowInfo(false);
    }

    const onEditNewAthlete = () : void => {
        setShowInfo(false);
        setLoading(true);
        getMyAthletes();
        // TODO : Llamar API para actualizar y en finally llamar a getMyAthletes
    }

    const onAddNewAthlete = (newAthlete : Athlete) : void => {
        setShowAdd(false);
        setLoading(true);
        getMyAthletes();
    }

    const getMyAthletes = () => {
        const paramsApi : ParamsApi = {
            body: {
                sessionId: loginContext.sessionId,
                userId: loginContext.id
            }
        }

        restApiClient.fetch("GetUserAthletes", paramsApi).then((r : ApiResponse | null)=> {
            let data : Athlete[] = r?.data;
            setAthletes(data);
            athleteListContext.setList(data);
        }).finally(()=>{
            setLoading(false);
        });
    }

    

    const getEncryptData = (athlete : Athlete) : string => {

        (String.prototype as any).hashCode = function() {
            var hash = 0,
              i, chr;
            if (this.length === 0) return hash;
            for (i = 0; i < this.length; i++) {
              chr = this.charCodeAt(i);
              hash = ((hash << 5) - hash) + chr;
              hash |= 0; // Convert to 32bit integer
            }
            return hash;
          }

        const hashedData = `${(`${athlete.id}` as any).hashCode()}|${(athlete.name as any).hashCode()}|${(athlete.surname as any).hashCode()}|${(athlete.secondSurname as any).hashCode()}`
        
        return hashedData;
    }

    useEffect(()=>{
        getMyAthletes();
    }, []);

    return(
<>
            <Row gutter={16} className="athletes">
            {
                    // Drawer for show RFEA data
                }
                <DrawerInsideComponent innercontainer="no-inner" mobilefixed={showRFEA} showadd={showRFEA} donclose={hideRFEADrawer} dtitle="Datos externos" dwidth={"50%"}>
                    <Components.Rfea athlete={athlete}/>  
                </DrawerInsideComponent>

                {
                    // Drawer for Add Athletes
                }
                <DrawerInsideComponent 
                        mobilefixed={true}
                        innercontainer={'no-inner'}  
                        showadd={showAdd} 
                        donclose={hideAddDrawer} 
                        dtitle="Registro de alumnos" 
                        dwidth={"50%"}>
                    <Components.AddAthleteForm onFinish={onAddNewAthlete} onClose={hideAddDrawer}/>  
                </DrawerInsideComponent>

                {
                    // Drawer for show and edit athlete
                }
                <DrawerInsideComponent mobilefixed={showInfo} showadd={showInfo} donclose={hideInfoDrawer} dtitle="Información del atleta" dwidth={"50%"}>
                    <Components.InfoAthleteForm onFinish={onEditNewAthlete} onClose={hideInfoDrawer} athlete={athlete}/>
                </DrawerInsideComponent>

                <Col xs={24}>
                    <Title className="c-title"  level={3}>
                        <ContactsOutlined /> &nbsp;
                        Alumnos
                        <small className="c-sub-title">
                            ¡Tus jugador@s!
                        </small>
                    </Title>
                    <Button id="btn-add-athletes" className="btn-add" type="primary" onClick={showAddDrawer}>
                        Nuevo
                    </Button>
                    
                </Col>
                {athletes.length === 0 ? <Col xs={24} className="empty-athletes">{getEmpty()}</Col> : '' }

                {athletes.map( (e: AthletesModel.default) => {
                    return (
                        <Col xs={24} md={12} lg={12} key={e.id}>
                                    <Card
                                        style={{ width: "100%", marginTop: 16 }}
                                        actions={[
                                            <SecurityScanTwoTone twoToneColor="#fe0c1a" className="athletes-box-icon" key="setting" onClick={()=>{showRFEADrawer(e)}}/>,
                                            <SettingOutlined className="athletes-box-icon" key="setting" onClick={()=>{showInfoDrawer(e)}}/>,
                                        ]}
                                        >
                                            <Skeleton loading={loading} avatar active>
                                                <Row gutter={16}>
                                                    <Col xs={15}>
                                                        <Meta
                                                            title={`#ATL-${e.id}`}
                                                            description={<Paragraph className="athlete-name" ellipsis={{ rows: 2 }}>{`${e.surname} ${e.secondSurname}, ${e.name}`}</Paragraph>}
                                                        />
                                                            <small className="block"><strong>F. Nacimiento</strong>: {moment(e.birthDate).format("DD/MM/YYYY")}</small>
                                                    </Col>   
                                                    <Col xs={9} className="athlete-avatar-container">
                                                        <div>
                                                            <QRCode value={getEncryptData(e)} size={100}/>
                                                        </div>
                                                    </Col> 
                                                </Row>
                                            </Skeleton>
                                        </Card>
                                        
                                </Col>
                            )
                })}
            </Row>
        </>
    )
}

export default AthletesComponent;