import Athlete from './Athletes';
import moment from 'moment';
import PaymentData from './PaymentData';
import Meta from './Meta';
import ProductInfo from './ProductInfo';
import FinancingData from './FinancingData';

export default interface Enrollment{
    id: number,
    athlete: Athlete,
    location: string,
    idSportCenter: number,
    size: "4XS" | "3XS" | "XXS" | "XS" | "S" | "M" | "L" | "XL" | "XXL" | "3XL" | "4XL" | ''
    season: string,
    categoryId: number,
    category: string,
    status: 'active' | 'cancelled' | 'finished' | 'pre' | 'expired' | 'free' | 'revision' | '',
    enrollDate: any,
    cancelEnrollDate?: Date,
    paymentData?: PaymentData[],
    isDeliveredMaterial: boolean,
    meta: Meta[],
    productInfo: ProductInfo,
    financingData?: FinancingData[]
}

export const EmptyEnrollment : Enrollment = {
    athlete: {
        birthDate: moment(),
        name: '',
        relationship: 'myself',
        externalUrl: '',
        secondSurname: '',
        registerDate: moment().add(-1, "y"),
        surname: '',
        rfeaCode:'',
        personalTrainer:{
            name: '',
            surname: '',
            secondSurname: '',
            birthDate: moment(),
            dniNie: ''
        }
    },
    categoryId: 0,
    category: '',
    enrollDate: moment(),
    id: 0,
    location: '',
    idSportCenter: 0,
    season: '',
    size: '',
    status: '',
    isDeliveredMaterial: false,
    meta: [],
    productInfo: {
        active: false,
        description: '',
        features: [],
        fromDate: null,
        gatewayId: '',
        id: 0,
        idCategory: 0,
        initialPay: 0,
        maxDate: null,
        maxQuotes: 0,
        minQuotes: 0,
        interval: 0,
        intervalCount: 0,
        name: '',
        pvp: 0,
        termsAndConditions:'',
        toDate: null,
        type: '',
        userPayFees: false
    }
}