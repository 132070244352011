import React, { useState, useEffect } from 'react'
import { LinkOutlined, RadarChartOutlined } from '@ant-design/icons';
import { Row, Col, Typography, Alert, Skeleton } from 'antd';

import moment from 'moment';

import './rfeaComponent.scss'
import Athlete from '../../models/Athletes';

const { Title } = Typography;

interface RfeaProps {
    athlete: Athlete
}

const RfeaComponent = (props : RfeaProps) : JSX.Element => {

    const [showLoadingRfeaIframe, setShowLoadingRfeaIframe] = useState<boolean>(false);
    const [rfeaCodPersona, setRfeaCodPersona] = useState<string>('');
    const [link, setLink] = useState<string>('https://www.rfea.es/web/estadisticas/resultados_atleta.asp?cod_temporada=21&cod_persona=0');

    const getActualSeason = () : string => { 
        const actualYear : string = moment().format("YY");
        const numberSeason : number = +actualYear+1;

        return numberSeason +'';
    }

    useEffect(()=>{
        setShowLoadingRfeaIframe(true);
        if (props.athlete && props.athlete.externalUrl && props.athlete.externalUrl.length > 0){
            setRfeaCodPersona(props.athlete.externalUrl)
        }else{
            setLink('');
        }
    }, [props.athlete]);

    useEffect(()=> {
        if (!isNaN(Number.parseInt(rfeaCodPersona))){
            setLink(`https://www.rfea.es/web/estadisticas/resultados_atleta.asp?cod_temporada=${getActualSeason()}&cod_persona=${rfeaCodPersona}`);
        }else{
            //Para aquellos que guardan un link en vez del código
            setLink(rfeaCodPersona);
        }
        
    }, [rfeaCodPersona])

    return (
        <Row className="rfea" gutter={16}>
            <Col xs={24} className="rfea-iframe-container">
                <Title className="c-title"  level={3}>
                    <RadarChartOutlined /> &nbsp;
                    Enlace externo
                </Title>

                {
                    rfeaCodPersona.length === 0 
                    ?
                        <Alert
                        message="Atención"
                        description={<>Si dispones de un enlace externo con más información sobre ti puedes hacérsela llegar al centro para que la añadan y te aparezca aquí.</>}
                        type="error"
                        showIcon
                        closable
                        />
                    :
                    <>
                        {
                            // Borja Vivas Jimenez 15096: MA 2986
                            // https://www.rfea.es/web/estadisticas_terr/ranking.asp?cod_federacion=fedandaluza
                            // https://www.rfea.es/web/estadisticas_terr/resultados_atleta.asp?cod_fed=1&cod_temporada=21&cod_persona=15096
                        
                            // <Alert
                            // message="RFEA"
                            // description={<><p>Información es <strong>obtenida de <a target="_blank" href="https://www.rfea.es">RFEA <LinkOutlined /></a></strong>. Conoce más entrando en  <strong><a target="_blank" href="https://www.rfea.es/web/estadisticas/ranking.asp">RFEA Ranking <LinkOutlined /></a></strong></p></>}
                            // type="info"
                            // showIcon
                            // closable
                            // />
                            // <img className="rfea-logo" src="/rfea_logo.jpg" />
                        }
                        <Skeleton loading={showLoadingRfeaIframe} active/>
                        <a href={link} target="_blank">{link}</a>
                        <iframe id="iframe-rfea" className={`iframe-rfea ${ showLoadingRfeaIframe ?'no-visible':'' }`} src={link} onLoadStart={()=>{alert("Empieza")}} onLoad={()=>{setShowLoadingRfeaIframe(false)}}></iframe>
                    </>
                }
                
            </Col>
        </Row>
    )
}

export default RfeaComponent;