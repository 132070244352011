import React, { useState, useEffect, useContext } from 'react'
import { Row, Col , Typography, Slider, Checkbox, Form, List, Button, Tooltip, Skeleton, notification, Spin, Drawer, Alert} from 'antd'
import parse from 'html-react-parser';
import { loadStripe } from "@stripe/stripe-js";
import { useStripe } from "@stripe/react-stripe-js";

import * as Components from '../../index';

import { LoginContext } from '../../../hooks/context/userContext';

import './checkoutComponent.scss'
import Enrollment from '../../../models/Enrollments'
import { LoadingOutlined, QuestionCircleOutlined, RocketOutlined, SafetyCertificateTwoTone } from '@ant-design/icons'
import ApiResponse from '../../../models/api/ApiResponse';

import { SmileyResult } from '../../index';
import RestApiClient from '../../../api/restApiClient';
import ParamsApi from '../../../models/api/ParamsApi';
import ProductInfo from '../../../models/ProductInfo';

import moment from 'moment'
import 'moment/locale/es' 
import PayEnrollment from '../../../models/PayEnrollment';
import { Discount } from '../../../models/Discount';
import { CalculateDiscount, filterDiscounts } from '../../../utils/ValidationsUtils';
import getUserIP from '../../../utils/IPUtils';

const { Title } = Typography

interface CheckoutComponentProps{
    enrollmentData: Enrollment,
    close: any
}

const CheckoutComponent = (props: CheckoutComponentProps) : JSX.Element => {
    const loginContext = useContext(LoginContext);
    const restApiClient : RestApiClient = new RestApiClient();

    const [checkboxSelected, setCheckboxSelected] = useState<number>(0);
    
    const [monthPrice, setMonthPrice] = useState<string>("-");
    const [monthPriceWithoutDiscount, setMonthPriceWithoutDiscount] = useState<string>("-");
    const [loadingProducts, setLoadingProducts] = useState<boolean>(false);

    const [ availableOnePayMode, setAvailableOnePayMode] = useState<boolean>(false);
    const [ onePayModeData, setOnePayModeData] = useState<ProductInfo>();

    const [ availableMultiplePayMode, setAvailableMultiplePayMode] = useState<boolean>(false);
    const [ enableMultiplePayMode, setEnableMultiplePayMode] = useState<boolean>(false);
    const [ multipleModeData, setMultipleModeData] = useState<ProductInfo[]>([]);

    const [ availablePayPerUseMode, setAvailablePayPerUseMode] = useState<boolean>(false);
    const [ payPerUseModeData, setPayPerUseModeData] = useState<ProductInfo>();

    const [ use3DSecure, setUse3DSecure ] = useState<boolean>(false);


    const [minQuotes, setMinQuotes] = useState<number>();
    const [maxQuotes, setMaxQuotes] = useState<number>();
    const [paymentsNum, setPaymentsNum] = useState<number>();

    const [showNoProductResult, setShowNoProductResult] = useState<boolean>(false);
    const [termnsAndConditions, setTermnsAndConditions] = useState<string>('');

    const [loadingPayment, setLoadingPayment] = useState<boolean>(false);

    const [availableDiscounts, setAvailableDiscounts] = useState<Discount[]>([]);

    const getDiscountsInfo = () : void => {
        const params : ParamsApi = {
            query: {
                sessionId: sessionStorage.getItem('token'),
            }
        }

        restApiClient.fetch("GetMyDiscounts", params)
                    .then((r : ApiResponse | null)=> {
                        if (r){
                            setAvailableDiscounts(r.data);
                        }
                    }).finally(()=>{
                        onChangePaymentsNum(paymentsNum);
                    });
    }

    const onChangePaymentsNum = (e: any) => {
        setPaymentsNum(e);


        var infoQuoteProduct = multipleModeData.find((mmd : ProductInfo) => mmd.minQuotes === e);
        if (infoQuoteProduct !== undefined){
            let finalMonthPrice : number = infoQuoteProduct.pvp / 100;


            const subscriptionDiscounts = filterDiscounts(availableDiscounts, "Subscription", multipleModeData.map(p => p.id), props.enrollmentData.idSportCenter);
            if (subscriptionDiscounts && subscriptionDiscounts.length > 0){
                const completeAmount : number = (finalMonthPrice * infoQuoteProduct.maxQuotes) + infoQuoteProduct.initialPay;
                const finalAmountAfterDiscounts = CalculateDiscount(completeAmount, subscriptionDiscounts);
                const amountDiscountMonthly = (completeAmount - finalAmountAfterDiscounts) / infoQuoteProduct.maxQuotes;

                const priceWidthDiscount = finalMonthPrice - (Math.round(amountDiscountMonthly * 100) / 100);
                
                setMonthPriceWithoutDiscount(finalMonthPrice+'')

                finalMonthPrice = Math.round(priceWidthDiscount * 100) / 100;
            }
            setMonthPrice(finalMonthPrice+'');
        }
    }



    

    //https://stripe.com/docs/payments/integration-builder
    const stripe = useStripe();
    const threeDSecurePayment = async (clientSecret: string, data: PayEnrollment) =>{
        setLoadingPayment(true);
        const payload = await stripe?.confirmCardPayment(clientSecret);

        if (payload?.error){
            notification.error({
                message: 'Error en cargo',
                description: 'No se pudo verificar su identidad por lo que el pago fue rechazado'
            });
            setLoadingPayment(false);
        }else{
            data.payInitialAmount = false;
            createSubscription(data);
        }
        
    }

    const prepareThreeDSecurePayment = (data: PayEnrollment, productInfo: any) : void =>{

        setLoadingPayment(true);
        getUserIP().then(((r : any) =>{
            //if (r && r.IPv4){
            if (r && r.ip){
                data.IP = r.ip;
                // Creamos el PaymentIntent en el servidor y, si ha ido mal mostramos error y si ha ido bien aparecería la modal

                const params : ParamsApi = {
                    body: data
                }
                restApiClient.fetch("ThreeDSecurePaymentStep1", params)
                        .then((r : ApiResponse | null)=> {
                            if (r !== null && r.code === 200){
                                threeDSecurePayment(r.data.clientSecret, data);
                            }else{
                                setLoadingPayment(false);
                            }
                        });
                    }else{
                        notification.error({
                            message: 'Atención',
                            description: 'No se ha podido realizar la operación. Asegúrese de no tener un bloqueador activo en su navegador. (2)'
                        })
                        setLoadingPayment(false);
                    }
        }))
    }

    const onFinish = (selected: string) : void => {
        let data : PayEnrollment = {
            enrollmentId: props.enrollmentData.id,
            productId: 0,
            quotes: 0,
            payInitialAmount: false,
            discounts: [],
            IP: ""
        };

        let productInfo: any ;
        // Pago normal
        switch(selected){
            case "one-pay":
                if (onePayModeData !== undefined){
                    data.productId = onePayModeData.id;
                    productInfo = onePayModeData;
                    data.discounts = filterDiscounts(availableDiscounts, "OnePay", [productInfo.id], props.enrollmentData.idSportCenter);
                }
                break;
            case "pay-per-use":
                if (payPerUseModeData !== undefined){
                    data.productId = payPerUseModeData.id;
                    productInfo = payPerUseModeData;
                    data.discounts = filterDiscounts(availableDiscounts, "PayPerUse", [productInfo.id], props.enrollmentData.idSportCenter);
                }
                break;
            case "multiple":
                if (multipleModeData !== undefined){
                    const selectedQuote = multipleModeData.find(m => m.minQuotes === paymentsNum);

                    if (selectedQuote !== undefined && paymentsNum !== undefined){
                        data.productId = selectedQuote.id;
                        data.quotes = paymentsNum;
                        productInfo = selectedQuote;
                        data.discounts = filterDiscounts(availableDiscounts, "Subscription", multipleModeData.map(p=>p.id), props.enrollmentData.idSportCenter);

                    }
                }
                break;
        }

        if (productInfo){
            setLoadingPayment(true);

            data.payInitialAmount = !(productInfo.initialPay > 0);

            if (use3DSecure && !data.payInitialAmount){
                //Hay que hacerlo mediante pago seguro
                prepareThreeDSecurePayment(data, productInfo);
            }else{
                //No se hace con pago seguro
                createSubscription(data);
            }
        }else{
            setLoadingPayment(false);
            notification.warn({
                message: 'Atención',
                description: 'Por favor, mueva el selector de los meses para indicar nuevamente el número de plazos a pagar.',
              })
        }
       
    }

    const createSubscription = (data: PayEnrollment) => {

        getUserIP().then(((r : any) =>{
            //if (r && r.IPv4){
                if (r && r.ip){
                data.IP = r.ip;
                const params : ParamsApi = {
                    body: data
                }
                
                setLoadingPayment(true);
                restApiClient.fetch("PayEnrollment", params)
                                .then((r : ApiResponse | null)=> {
                                    if (r !== null && r.code === 200){
                                        props.close();
                                    }
                                }).finally(()=>{
                                    setLoadingPayment(false);
                                });
            }else{
                notification.error({
                    message: 'Atención',
                    description: 'No se ha podido realizar la operación. Asegúrese de no tener un bloqueador activo en su navegador. (1)'
                })
                setLoadingPayment(false);
            }
            

        }))

        
    }

    const getProductsForEnroll = (categoryId : number, sportCenterId: number) : void => {
        const params : ParamsApi = {
            query: {
                idCategory: categoryId,
                idSportCenter: sportCenterId,
                sessionId: loginContext.sessionId
            }
        }

        setLoadingProducts(true);
        setShowNoProductResult(false);
        restApiClient.fetch("GetCheckoutProducts", params)
            .then((r : ApiResponse | null) => {
                if (r?.code === 200){
                    if (
                        r.data !== null 
                        && r.data !== undefined 
                        && r.data.list !== null 
                        && r.data.threeDSecure !== null 
                        && r.data.list !== undefined 
                        && r.data.threeDSecure !== undefined 
                        && r.data.list.length > 0){

                            setUse3DSecure(r.data.threeDSecure);

                            var onePay = r.data.list.find((d : ProductInfo) => d.type === "OnePay");
                            var payPerUse = r.data.list.find((d : ProductInfo) => d.type === "PayPerUse");
                            var subscriptions = r.data.list.filter((d : ProductInfo) => d.type === "Subscription");

                            setOnePayModeData(onePay);
                            setMultipleModeData(subscriptions);
                            setPayPerUseModeData(payPerUse);
        
                            setAvailableMultiplePayMode(subscriptions.length > 0);
                            setAvailableOnePayMode(onePay !== undefined);
                            setAvailablePayPerUseMode(payPerUse !== undefined);
                    }else{
                        setOnePayModeData(undefined);
                        setMultipleModeData([]);
                        setPayPerUseModeData(undefined);
                        setShowNoProductResult(true);
                    }
                }
            })
            .finally(()=>{setLoadingProducts(false);})
    }

    const showNoProduct= () => {
        return <SmileyResult text={`Hola los pagos de las inscripciones están cerrados. Vuelve a intentarlo más adelante`} buttonText="Reintentar" action={()=>{ getProductsForEnroll(props.enrollmentData.categoryId, props.enrollmentData.idSportCenter) }} />
    }

    const show3DSecure = () => {
        return <div className="safety-certificate"><SafetyCertificateTwoTone className="shield" twoToneColor="#52c41a"/> ¡Su tarjeta está configurada para realizar pagos seguros!</div>
    }

    const isChecked = (product: ProductInfo) => {
        setCheckboxSelected(multipleModeData[0].id);
        setTermnsAndConditions(multipleModeData[0].termsAndConditions);

    }

    const getIntervalString = (interval: number, intervalCount: number) : string => {
        let result : string = '';
        switch(interval){
            case 0:
                
                result = `dia${intervalCount > 1 ? '(s)' : ''}`
            break;
            case 1:
                result = `semana${intervalCount > 1 ? '(s)' : ''}`
            break;
            case 2:
                result = `mes${intervalCount > 1 ? '(es)' : ''}`
            break;
            case 3:
                result = `aó${intervalCount > 1 ? '(s)' : ''}`
            break;
        }

        return result;
    }

    useEffect(()=>{
        onChangePaymentsNum(maxQuotes); 
    },[minQuotes, maxQuotes]);

    useEffect(()=>{
        if (multipleModeData.length > 0){
            var minSubscriptionValue : ProductInfo = multipleModeData.reduce((min : ProductInfo, p : ProductInfo) => p.minQuotes < min.minQuotes ? p : min, multipleModeData[0]);
            var maxSubscriptionValue : ProductInfo = multipleModeData.reduce((max : ProductInfo, p : ProductInfo) => p.maxQuotes > max.maxQuotes ? p : max, multipleModeData[0]);

            setMinQuotes(minSubscriptionValue.minQuotes);

            /// Si el producto tiene una fecha límite, el número de cuotas no podrá ser superior al 
            /// número de meses que quedan hasta esa fecha límite
            let finalQuotes : number = maxSubscriptionValue.maxQuotes;
            if (maxSubscriptionValue.maxDate !== null && maxSubscriptionValue.maxDate !== undefined){
                var now = moment(new Date()); //todays date
                var duration = moment.duration(moment(maxSubscriptionValue.maxDate).diff(now));
                
                if (duration.asMonths() < maxSubscriptionValue.maxQuotes){
                    finalQuotes = Math.floor(duration.asMonths());
                }
            }
            setMaxQuotes(finalQuotes);
            onChangePaymentsNum(finalQuotes); 
        }
    }, [multipleModeData]);

    useEffect(()=> {
        getDiscountsInfo();
        if (props.enrollmentData.status !== ''){
            setOnePayModeData(undefined);
            setMultipleModeData([]);
            setPayPerUseModeData(undefined);
            setShowNoProductResult(true);
            setTimeout(()=>{getProductsForEnroll(props.enrollmentData.categoryId, props.enrollmentData.idSportCenter)}, 500);
        }
        
    }, [props.enrollmentData]);

    return (
            <>
                {
                    loginContext.paymentMethodExpired ?
                        <Alert
                            message="Atención"
                            description={<div style={{textAlign:"center"}}>Su tarjeta de crédito está caducada. Por favor, añada un método de pago válido. Para ello pulse sobre el icono azúl de la ventana principal 'Modos de pago'. Una vez hecho el cambio de tarjeta deberá <b>volver a iniciar sesión para que los cambios surjan efecto.</b> <br/><br/> <Button type='primary' onClick={()=>{props.close()}}>Cerrar</Button></div>}
                            type="warning"
                            showIcon
                            closable
                            />
                    : 
                        <Row gutter={16} className="checkout" id="checkout"> 
                                {
                                    use3DSecure ?  
                                    <Col xs={24} style={{textAlign:"center"}}>
                                        {show3DSecure()}
                                    </Col>
                                    :
                                    ''
                                }
                            <Components.Navigator containerId={'checkout'}/>

                            <Components.DrawerInside closable={false} showadd={termnsAndConditions.length > 0} donclose={()=>{ setTermnsAndConditions('');}} dtitle="Términos y condiciones del producto" mobilefixed={termnsAndConditions.length > 0}>
                                <Row gutter={16} className="terms-and-conditions">
                                    <Col xs={24}>
                                        {parse(termnsAndConditions)}
                                    </Col>
                                    <Col xs={24} className="col-accept-terms">
                                        <Button type="primary" onClick={()=>{setTermnsAndConditions('')}}>He leído, entiendo y acepto los términos y condiciones descritos en este apartado</Button>
                                    </Col>
                                </Row>
                            </Components.DrawerInside>
                            
                            <Skeleton loading={loadingProducts} active avatar>
                                {
                                    availableOnePayMode && onePayModeData !== undefined ?
                                        <Components.AvailableProduct 
                                            id="one-pay" 
                                            product={onePayModeData} 
                                            onPay={()=>{onFinish('one-pay')}} 
                                            isloading={loadingPayment} 
                                            setselected={setCheckboxSelected} 
                                            selected={checkboxSelected}
                                            termnsandconditions={setTermnsAndConditions}
                                            discounts={availableDiscounts}
                                            idSportCenter={props.enrollmentData.idSportCenter}
                                            />
                                    :
                                    ''
                                }

                                {
                                    availableMultiplePayMode && multipleModeData !== undefined && multipleModeData.length > 0
                                    ?
                                        <Col xs={24} md={{span:8}}  lg={{span:8, offset:1}} xl={{span:6, offset:2}} 
                                                id="multi-pay" 
                                                className="cart-option multiple-pay-mode">
                                            <RocketOutlined className="icon" />
                                            <Title level={2} className="title">{multipleModeData[0].name}</Title>
                                            <small className="sub-title">{multipleModeData[0].description}</small>
                                            <Title level={1} className="price">€{monthPrice}<sup>/{getIntervalString(multipleModeData[0].interval, multipleModeData[0].intervalCount)}</sup></Title>

                                            {
                                                availableDiscounts && availableDiscounts.length > 0
                                                ?
                                                <div style={{color: "white"}}>
                                                    {
                                                        filterDiscounts(availableDiscounts, "Subscription", multipleModeData.map(p=>p.id), props.enrollmentData.idSportCenter).length > 0 
                                                        ? 
                                                            <span className="discount-previous-amount">
                                                                Precio antes de descuentos €{monthPriceWithoutDiscount}<sup>/{getIntervalString(multipleModeData[0].interval, multipleModeData[0].intervalCount)}</sup>
                                                            </span> 
                                                        : 
                                                            ''
                                                        
                                                    }
                                                    {
                                                        filterDiscounts(availableDiscounts, "Subscription", multipleModeData.map(p=>p.id), props.enrollmentData.idSportCenter).map((discount : Discount, index: number) => {
                                                            return <span className="discount-description" key={index}>{discount.discountDescription}</span>
                                                        })
                                                    }
                                                </div>
                                                :
                                                ''
                                            }
                                            
                                            <List
                                                size="small"
                                                header={<>
                                                    <div><br />
                                                        Selecciona la cuota que mejor te venga &nbsp;
                                                        <Tooltip placement="top" title={`El número máximo de cuotas no podrá exceder la fecha límite de ${moment(multipleModeData[0].maxDate).format("DD/MM/YYYY")}.`}>
                                                            <QuestionCircleOutlined />
                                                        </Tooltip>

                                                        <Slider style={{color:"red"}} onChange={onChangePaymentsNum} defaultValue={maxQuotes} max={maxQuotes} min={minQuotes} tooltipPlacement="bottom"/>
                                                    </div>
                                                    <div>Características</div>
                                                </>}
                                                dataSource={[
                                                    parse(`Pago inicial de <b>${multipleModeData[0].initialPay}€</b>`), 
                                                    parse(`Luego financiado a <b>${monthPrice}€</b> ${getIntervalString(multipleModeData[0].interval,multipleModeData[0].intervalCount)}`), 
                                                    parse(`<b>${paymentsNum}</b> plazos`), 
                                                    multipleModeData[0].features.map((feature: any, index: number) =>  <small key={index} className="feature">{feature}</small>),
                                                    <Checkbox
                                                        onChange={(e)=>{setEnableMultiplePayMode(e.target.checked); e.target.checked ? isChecked(multipleModeData[0]) : setCheckboxSelected(0)}} 
                                                    >
                                                        <a style={{color: "white"}} href="#" onClickCapture={(e)=>{e.preventDefault();}}>Acepto los términos y condiciones de esta opción de pago</a>
                                                    </Checkbox>
                                                ]}
                                                renderItem={item => <List.Item>{item}</List.Item>}
                                                />
                                                    
                                                { 
                                                    multipleModeData[0].fromDate !== undefined && moment(new Date()).isSameOrBefore(moment(multipleModeData[0].fromDate))?
                                                            <Button type="ghost" size="large" className="btn-pay danger">¡Disponible {moment(moment(multipleModeData[0].fromDate)).from(new Date())}!
                                                                <small style={{display:"block", fontSize: "12px"}}>A partir del {moment(multipleModeData[0].fromDate).format("DD/MM/YYYY")}</small>
                                                            </Button>
                                                        :
                                                            (checkboxSelected == multipleModeData[0].id && loadingPayment ? 
                                                                <Button type="primary" size="large" className="btn-pay" >
                                                                    <Spin indicator={<LoadingOutlined style={{ fontSize: 24, color:"black", marginRight: "5px" }} spin />} />
                                                                    Realizando pago, 
                                                                    <small style={{display:"block"}}>espere, por favor...</small>
                                                                </Button> 
                                                            : 
                                                                <Button disabled={!enableMultiplePayMode} type="primary" size="large" className="btn-pay" onClick={()=>{onFinish('multiple')}}>
                                                                    {
                                                                        multipleModeData[0]?.initialPay > 0 ?
                                                                            `¡Pagar primeros ${multipleModeData[0].initialPay}€ y financiar!`
                                                                        :
                                                                            `¡Financiar!`
                                                                    }
                                                                    {
                                                                        multipleModeData[0].toDate !== null && multipleModeData[0].toDate !== undefined ? 
                                                                        <small style={{fontSize:"10px", display:"block"}}>Método de pago disponible hasta: {moment(multipleModeData[0].toDate).format("DD/MM/YYYY")}</small>
                                                                        :
                                                                        ''
                                                                    }
                                                                </Button> 
                                                            )
                                                            
                                                }
                                        </Col>
                                    :
                                    ''
                                }

                                {
                                    availablePayPerUseMode && payPerUseModeData !== undefined?
                                        <Components.AvailableProduct 
                                            id="pay-per-use" 
                                            product={payPerUseModeData}  
                                            onPay={()=>{onFinish('pay-per-use')}} 
                                            isloading={loadingPayment} 
                                            setselected={setCheckboxSelected} 
                                            selected={checkboxSelected}
                                            termnsandconditions={setTermnsAndConditions}
                                            discounts={availableDiscounts}
                                            idSportCenter={props.enrollmentData.idSportCenter}
                                        />
                                    :
                                    ''
                                }
                                
                                
                                {
                                    showNoProductResult ? 
                                        <Col xs={24} style={{textAlign:"center"}}>
                                            {showNoProduct()}
                                        </Col>
                                    :
                                    ''
                                }

                                
                            </Skeleton>
                        </Row>
                }
            </>
            

    )
}

export default CheckoutComponent;