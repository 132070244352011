import React, {useEffect, useState }  from 'react'

import DrawerInsideComponent from '../../DrawerInside/DrawerInside'

import * as Components from '../../index'

interface ContactDrawerProps{
    visible: boolean,
    showContactDrawer: any
}

const ContactDrawerComponent = (props : ContactDrawerProps): JSX.Element => {

    const [showDrawer, setShowDrawer] = useState<boolean>(false);

    useEffect(()=>{
        setShowDrawer(props.visible);
    }, [props.visible])

    return(
    <DrawerInsideComponent 
        mobilefixed={showDrawer} 
        showadd={showDrawer} 
        donclose={()=>{ props.showContactDrawer(false); }} 
        dtitle="" 
        dwidth={"45%"}
    >
        <Components.ContactForm />
    </DrawerInsideComponent>)
}

export default ContactDrawerComponent;