import React from 'react'

import { Row, Col, Timeline, Typography } from 'antd'

import { ClockCircleOutlined, OrderedListOutlined } from '@ant-design/icons'

import './history.scss'

interface HistoryProps {}

const { Title, Paragraph} = Typography

const HistoryComponent = (props : HistoryProps) : JSX.Element => {

    return(
        <Row className="history">
            <Col xs={20}>
                <Title className="c-title" level={3}>
                    <OrderedListOutlined /> &nbsp;
                    Historial
                    <small className="c-sub-title">
                        Así conocemos tu historia
                    </small>
                </Title>
            </Col>
            <Col xs={20}>
                <br />
                <br />
                <Timeline mode="alternate">
                    <Timeline.Item color="red" dot={<ClockCircleOutlined style={{ fontSize: '16px' }} />}>
                        <p style={{margin:"0"}}>Inscripción finalizada</p>
                        <small>Inscripción ID #101</small>
                    </Timeline.Item>
                    <Timeline.Item color="green">
                        <p style={{margin:"0"}}>Recobro automático 25€</p>
                        <small>ID Pago: 1234</small>
                    </Timeline.Item>
                    <Timeline.Item color="red">
                        <p style={{margin:"0"}}>Error en cobro 25€</p>
                        <small>ID Pago: 1234</small>
                        <small style={{display:"block"}}>Inscripción ID #101</small>
                    </Timeline.Item>
                    <Timeline.Item>
                        <p style={{margin:"0"}}>Pago inicial 60€</p>
                        <small>ID Pago: 1234</small>
                    </Timeline.Item>
                    <Timeline.Item color="green">
                        <p style={{margin:"0"}}>Recobro automático 25€</p>
                        <small>ID Pago: 1234</small>
                    </Timeline.Item>
                    <Timeline.Item color="red">
                        <p style={{margin:"0"}}>Error en cobro 25€</p>
                        <small>ID Pago: 1234</small>
                        <small style={{display:"block"}}>Inscripción ID #101</small>
                    </Timeline.Item>
                    <Timeline.Item>
                        <p style={{margin:"0"}}>Pago inicial 60€</p>
                        <small>ID Pago: 1234</small>
                    </Timeline.Item>
                    <Timeline.Item color="green">
                        <p style={{margin:"0"}}>Recobro automático 25€</p>
                        <small>ID Pago: 1234</small>
                    </Timeline.Item>
                    <Timeline.Item color="red">
                        <p style={{margin:"0"}}>Error en cobro 25€</p>
                        <small>ID Pago: 1234</small>
                        <small style={{display:"block"}}>Inscripción ID #101</small>
                    </Timeline.Item>
                    <Timeline.Item>
                        <p style={{margin:"0"}}>Pago inicial 60€</p>
                        <small>ID Pago: 1234</small>
                    </Timeline.Item>
                    <Timeline.Item dot={<ClockCircleOutlined style={{ fontSize: '16px' }} />}>
                        <p style={{margin:"0"}}>Creación inscripción</p>
                        <small>Elia Juliana Plaza Arnau</small>
                        <small>ID #103</small>
                    </Timeline.Item>
                </Timeline>
            </Col>
        </Row>
    )
}

export default HistoryComponent;