import React from 'react'

import { Row, Col } from 'antd'


interface RoutineHelperProps {
    onPrev: any,
    onPlay: any,
    onPause: any,
    onInit: any,
    onNext: any,
}

const RoutineHelperComponent = (props: RoutineHelperProps) => {
    return (
            <Row gutter={16} className="routine-helper">
                <Col xs={24}>
                    <button onClick={props.onPrev}>Anterior</button>
                        <button onClick={props.onPlay}>Iniciar</button>
                        <button onClick={props.onPause}>Pausar</button>
                        <button onClick={props.onInit}>Reiniciar</button>
                    <button onClick={props.onNext}>Siguiente</button>
                </Col>
            </Row>
        )
}

export default RoutineHelperComponent;