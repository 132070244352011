import LoginPage from './auth/login/login'
import LockScreenPage from './auth/lockscreen/lockscreen'
import DashBoardPage from './dashboard/dashboard'
import ProfilePage from './user/profile'
import RoutineDetailPage from './academy/routine-detail'
import RegisterPage from './auth/register/register'
import RemoveAccountPage from './account/RemoveAccountPage'

export const Login = LoginPage
export const LockScreen = LockScreenPage 
export const DashBoard = DashBoardPage
export const Profile = ProfilePage
export const RoutineDetail = RoutineDetailPage
export const Register = RegisterPage
export const RemoveAccount = RemoveAccountPage