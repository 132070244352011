import React, { useEffect, useState } from 'react'

import { Row, Col, Empty, Button, Typography, List, Skeleton, Card } from 'antd'

import { AuditOutlined, DownloadOutlined, ReloadOutlined, SearchOutlined } from '@ant-design/icons'

import './documents.scss'
import RestApiClient from '../../api/restApiClient'
import SharedFile from '../../models/SharedFile'
import ApiResponse from '../../models/api/ApiResponse'
import Meta from 'antd/lib/card/Meta'

const { Title, Paragraph } = Typography

interface DocumentsProps {}

const DocumentsComponent = (props : DocumentsProps) : JSX.Element => {
    const restApiClient : RestApiClient = new RestApiClient();

    const [loadingDocuments, setLoadingDocuments] = useState<boolean>(false)
    const [documentList, setDocumentList] = useState<SharedFile[]>([])
    const getMyDocuments = () : void => {
        setLoadingDocuments(true);
        restApiClient.fetch("GetMyDocuments", {}).then((r : ApiResponse | null)=> {
            if (r && r.code === 200){
                setDocumentList(r.data);
            }
        }).finally(()=>{
            setLoadingDocuments(false);
        })
    }
    
    const getEmpty = () : JSX.Element => <Empty
                                            image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                                            imageStyle={{
                                            height: 60,
                                            }}
                                            description={
                                            <span>
                                               El centro aún no ha subido ningún documento.
                                            </span>
                                            }
                                        >
                                        </Empty>

    const downloadFile = (filename: string, url: string) : void => {
        const link = document.createElement("a");
        link.href = url;
        link.download = filename;
        link.click();
    }
    const showFile = (link: string) : void => {
        window.open(link, '_blank', 'toolbar=no,location=no,menubar=no,width=600,height=800');
    }


    useEffect(()=>{
        getMyDocuments();
    },[])

    return(
        <Row gutter={16} className="documents">
            <Col xs={24}>
                <Title className="c-title"  level={3}>
                    <AuditOutlined /> &nbsp;
                    Documentos
                    <small className="c-sub-title">
                        Documentación aportada por el club
                    </small>
                </Title>
                <Button className="btn-reload" type="primary" onClick={()=>{getMyDocuments()}} >
                    {
                        loadingDocuments ?
                            <ReloadOutlined spin />
                        :
                            <ReloadOutlined />
                    }
                </Button>
            </Col>
            <Col xs={24}>
                <Row gutter={16}>
                    <Skeleton active loading={loadingDocuments}>
                        {
                            documentList.length > 0 ?
                                documentList.map((doc: SharedFile, index: number) => {
                                    return <Col xs={24} md={12} lg={12} key={index}>
                                            <Card
                                                style={{width: "100%", marginTop: 16}}
                                            >
                                                <Meta
                                                    title={ <>
                                                            </>
                                                        }
                                                    description={
                                                        <div>
                                                            <p>
                                                                Documento: <strong>{`${doc.fileName}`}</strong>
                                                            </p>
                                                            <div className="actions ">
                                                                <Button type="primary" onClick={()=>{showFile(doc.webLink)}}><SearchOutlined /> Visualizar</Button>
                                                                <Button type="primary" onClick={()=>{downloadFile(doc.fileName, doc.downloadLink)}}><DownloadOutlined /> Descargar</Button>
                                                            </div>
                                                        </div>
                                                        
                                                    } />
                                                    
                                            </Card>
                                        </Col>
                                })
                            :
                                <div style={{textAlign:"center", width: "100%"}}>
                                    <br />
                                        { getEmpty() }
                                    <br />
                                </div>
                            }
                        
                        
                    </Skeleton>
                </Row>
            </Col>
        </Row>
    )
}

export default DocumentsComponent;