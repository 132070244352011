import EndPointConfig from '../../models/api/EndPointConfig'

const controller = 'Payments'

export const PaymentsEP : EndPointConfig[] =[

    new EndPointConfig()
        .setMethod('POST')
        .setController(controller)        
        .setActionName("PayEnrollment"),
    new EndPointConfig()
        .setMethod('POST')
        .setController(controller)        
        .setActionName("ThreeDSecurePaymentStep1"),
    new EndPointConfig()
        .setMethod('POST')
        .setController(controller)        
        .setActionName("ThreeDSecureConfirmPayment"),
    new EndPointConfig()
        .setMethod('GET')
        .setController(controller)        
        .setActionName("GetPendingPayments"),
        
]