import React, {useContext, useState} from "react";
import { useStripe, useElements, CardElement, IbanElement } from "@stripe/react-stripe-js";

import { Row, Col, Typography, Alert, notification, Form, Input, Checkbox, Divider } from 'antd';
import { LoadingOutlined, PlusSquareOutlined } from '@ant-design/icons';

import RestApiClient from '../../../../api/restApiClient';
import ParamsApi from '../../../../models/api/ParamsApi';

import '../../../../styles/stripe/stripeStyles.css';
import './StripeBankAccountForm.scss';
import ApiResponse from "../../../../models/api/ApiResponse";
import { LoginContext } from '../../../../hooks/context/userContext'
import { AppCfgContext } from "../../../../hooks/context/appConfigContext";



const { Title } = Typography;

interface StripeRegisterBankAccountProps {
    requireRefresh: any
}


const StripeRegisterBankAccountForm = (props : StripeRegisterBankAccountProps): JSX.Element => {
    const restApiClient : RestApiClient = new RestApiClient();
    const loginContext = useContext(LoginContext);
    const appConfigContext = useContext(AppCfgContext);



    const [formBankAccount] = Form.useForm();

    const [acceptTermsAndConditions, setAcceptTermsAndConditions] = useState<boolean>(false);
    
    const stripe = useStripe();
    const elements = useElements();
    const [disabledBank, setDisabledBank] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(false);
    const [ ref, setRef ] = useState<any>(null);

    const IBAN_STYLE = {
        base: {
          color: '#32325d',
          fontSize: '16px',
          '::placeholder': {
            color: '#aab7c4'
          },
          ':-webkit-autofill': {
            color: '#32325d',
          },
        },
        invalid: {
          color: '#fa755a',
          iconColor: '#fa755a',
          ':-webkit-autofill': {
            color: '#fa755a',
          },
        }
      };
      
      const IBAN_ELEMENT_OPTIONS = {
        supportedCountries: ['SEPA'],
        // Elements can use a placeholder as an example IBAN that reflects
        // the IBAN format of your customer's country. If you know your
        // customer's country, we recommend that you pass it to the Element as the
        // placeholderCountry.
        placeholderCountry: 'ES',
        style: IBAN_STYLE,
      };

      const handleSubmitBankAccount = async (event: any) => {

        if (!stripe || !elements) {
        // Stripe.js has not loaded yet. Make sure to disable
        // form submission until Stripe.js has loaded.
        return;
        }

        const accountholderName = event['accountholder-name'];
        const iban = elements.getElement(IbanElement);

        if (iban){
            //https://stripe.com/docs/testing
            // Ver doc aquí y enterarse de dónde se consigue esta clave: https://stripe.com/docs/api/setup_intents/object
            
              const payload = await stripe.createPaymentMethod({
                type: 'sepa_debit',
                sepa_debit: iban,
                billing_details: {
                    email: loginContext.username!,
                    name: accountholderName,
                },
              });

              if (!payload.error){
                const params : ParamsApi = {
                    body: {
                        sessionId: sessionStorage.getItem('token'),
                        tokenBankAccount: payload.paymentMethod?.id,
                    }
                }
    
                setLoading(true);
                restApiClient.fetch("AddSEPA", params)
                            .then((r : ApiResponse | null) => {
                                if (r?.code === 200){
                                    props.requireRefresh(true);
    
                                    if (ref !== null){
                                        ref.clear();
                                    }
                                }
                            }).finally(()=>{
                                setLoading(false);
                            })
            }
        }

        return false;
    };

    //Doc para guardar tarjeta: https://stripe.com/docs/payments/sepa-debit/set-up-payment
    return ( 
            <>
                <Row gutter={16}>
                    <Col xs={24}>
                        <Title className="c-title"  level={3}>
                            <PlusSquareOutlined /> &nbsp;
                            Añade tu IBAN
                            <small className="c-sub-title">
                                ¡Completamente seguros gracias a <a href="https://www.stripe.com/es" target="_blank">Stripe!</a>
                            </small>
                        </Title>
                    </Col>
                </Row>

                <Title className="c-title" level={2}>
                            Domiciliación Bancaria
                        </Title>
                        <Form layout="vertical" className="stripe-bank-account-form" size="small" onFinish={handleSubmitBankAccount} form={formBankAccount}>
                            <div className="form-row inline">
                                <div className="col">
                                    <Form.Item
                                        label="Nombre"
                                        help="Indicar sólo si el nombre del titular de la cuenta bancaria es diferente al usuario registrado."
                                        name="accountholder-name"
                                        initialValue={`${loginContext.name} ${loginContext.surname}${loginContext.secondSurname ? ` ${loginContext.secondSurname}` : ''}`}
                                        className="form-item"
                                    >
                                        <Input placeholder="Nombre del titular"/>
                                    </Form.Item>
                                </div>
                            </div>

                            <div className="form-row">
                                <label>
                                IBAN
                                <IbanElement options={IBAN_ELEMENT_OPTIONS} onChange={(e)=>{console.log("e",e); setDisabledBank(!e.complete)}}/>
                                </label>
                            </div>

                            <button type="submit" disabled={!stripe || disabledBank || !acceptTermsAndConditions || loading}>
                                {
                                    !acceptTermsAndConditions  ? 'Debe aceptar los términos y condiciones descritos abajo' : <>{loading ? <><LoadingOutlined /> Registrando cuenta bancaria, espere...</> : 'Registrar cuenta bancaria'}</>
                                }
                            </button>
                            {/* Display mandate acceptance text. */}
                            <Row gutter={16} className="credit-card-warning">
                                <Col xs={24}>
                                    <Alert
                                            message="Información"
                                            description={<>Nosotros <strong>no guardamos información sobre tu cuenta bancaria</strong>. Usamos la pasarela de pago de <a href='https://www.stripe.com' target="_blank">Stripe</a> y ellos son los encargados de almacenar la información de manera segura.</>}
                                            type="info"
                                            showIcon
                                            />
                                            
                                </Col>
                            </Row>
                            <div className="mandate-acceptance" style={{marginTop:"15px"}}>
                                <Alert
                                        message="Atención"
                                        description={<>
                                                        Al proporcionar sus datos de pago y confirmar este pago, usted autoriza a (A) <strong>{appConfigContext.name} y Stripe</strong>, nuestro proveedor de servicios de pago y/o a PPRO, su proveedor de servicios local, a enviar instrucciones a su banco para realizar un débito en su cuenta y (B) a su banco a realizar un cargo en su cuenta de conformidad con dichas instrucciones. Como parte de sus derechos, usted tiene derecho a un reembolso de su banco conforme a los términos y condiciones del contrato con su banco. El reembolso debe reclamarse en un plazo de 8 semanas a partir de la fecha en la que se haya efectuado el cargo en su cuenta. Sus derechos se explican en un extracto que puede obtener en su banco. Usted acepta recibir notificaciones de futuros débitos hasta 2 días antes de que se produzcan.
                                                        <Divider style={{margin: "10px 0"}}/><Checkbox style={{display:"block"}} onChange={(v: any)=>{
                                                            setAcceptTermsAndConditions(v.target.checked);
                                                        }}><strong>Acepto los términos y condiciones expuestos</strong></Checkbox>
                                                    </>}
                                        type="success"
                                        showIcon
                                        />
                                
                            </div>
                        </Form>
            </>
    );
}



export default StripeRegisterBankAccountForm;