import React, { useContext, useEffect, useState } from 'react'
import Enrollment from '../../../models/Enrollments'
import { LoginContext } from '../../../hooks/context/userContext';

import './CheckoutComponentV2.scss'
import RestApiClient from '../../../api/restApiClient';
import ParamsApi from '../../../models/api/ParamsApi';
import ApiResponse from '../../../models/api/ApiResponse';
import { Discount } from '../../../models/Discount';
import { Alert, Button, Carousel, Checkbox, Col, Divider, Input, notification, Row, Skeleton, Slider, Tag, Tooltip, Typography } from 'antd';
import ProductInfo from '../../../models/ProductInfo';
import { ArrowUpOutlined, CalendarTwoTone, CaretUpOutlined, CheckOutlined, EuroCircleOutlined, EuroCircleTwoTone, FlagOutlined, FlagTwoTone, InfoCircleTwoTone, LoadingOutlined, QuestionCircleOutlined, ReloadOutlined, RocketOutlined, SafetyCertificateTwoTone, SearchOutlined, SketchOutlined, TagsTwoTone, TrophyOutlined, WarningTwoTone } from '@ant-design/icons';
import moment from 'moment';
import groupBy from '../../../utils/GroupBy';
import { CalculateDiscountWithSteps, filterDiscounts, getProductPrice } from '../../../utils/ValidationsUtils';
import PayEnrollment from '../../../models/PayEnrollment';
import getUserIP from '../../../utils/IPUtils';
import { useStripe } from '@stripe/react-stripe-js';

const { TextArea } = Input;

interface CheckoutComponentV2Props{
    enrollmentData: Enrollment,
    close: any
}

const CheckoutComponentV2 = (props : CheckoutComponentV2Props) : JSX.Element => {
    const loginContext = useContext(LoginContext);
    const restApiClient : RestApiClient = new RestApiClient();

    const { enrollmentData, close } = props;
    
    const [availableDiscounts, setAvailableDiscounts] = useState<Discount[]>([]);
    const [appliedDiscounts, setAppliedDiscounts] = useState<Discount[]>([]);
    const getDiscountsInfo = () : void => {
        const params : ParamsApi = {
            query: {
                sessionId: sessionStorage.getItem('token'),
            }
        }

        restApiClient.fetch("GetMyDiscounts", params)
                    .then((r : ApiResponse | null)=> {
                        if (r){
                            setAvailableDiscounts(r.data);
                        }
                    });
    }

    const [loadingProducts, setLoadingProducts] = useState<boolean>(false);
    const [ onePayModeData, setOnePayModeData] = useState<ProductInfo[]>([]);
    const [ multipleModeData, setMultipleModeData] = useState<ProductInfo[][]>([]);
    const [ payPerUseModeData, setPayPerUseModeData] = useState<ProductInfo[]>([]);

    const getProductsForEnroll = (categoryId : number, sportCenterId: number) : void => {
        const params : ParamsApi = {
            query: {
                idCategory: categoryId,
                idSportCenter: sportCenterId,
                sessionId: loginContext.sessionId
            }
        }

        setLoadingProducts(true);
        restApiClient.fetch("GetCheckoutProducts", params)
            .then((r : ApiResponse | null) => {
                if (r){

                    setUse3DSecure(r.data.threeDSecure);
                    var onePay = r.data.list.filter((d : ProductInfo) => d.type === "OnePay");
                    var payPerUse = r.data.list.filter((d : ProductInfo) => d.type === "PayPerUse");
                    var subscriptions = groupBy(r.data.list.filter((d : ProductInfo) => d.type === "Subscription"), "hash");

                    setOnePayModeData(onePay ?? []);
                    setMultipleModeData(subscriptions ?? []);
                    setPayPerUseModeData(payPerUse ?? []);

                }
            })
            .finally(()=>{setLoadingProducts(false);})
    }

    const getProductTypeIcon = (type: string) : JSX.Element => {
        var result : JSX.Element = <></>
        switch(type){
            case "OnePay":
                result = <TrophyOutlined className="icon" />
                break;
            case "PayPerUse":
                result = <EuroCircleOutlined className="icon" />
                break;
            case "Subscription":
                result = <RocketOutlined className="icon" />
                break;
            default:
                result = <QuestionCircleOutlined className="icon" />
        }

        return result;
    }

    const [productInfo, setProductInfo] = useState<ProductInfo>();
    const getProductInfo = (product: ProductInfo, key: string) : JSX.Element => {

        return <Col xs={24} sm={12} md={8} lg={8} xl={6} key={key}>
                    <div className={`product-info ${productInfo?.id == product.id ? 'selected' : ''}`}>
                        <div className="product-type">
                            {
                                productInfo?.id == product.id ? <CheckOutlined className="check-product"/> : <></>
                            }
                            {getProductTypeIcon(product.type)}
                        </div>
                        
                        <div className="product-name">
                            <Typography.Title level={4} className="title">{product.name}</Typography.Title>
                            <small>
                                {product.description}
                            </small>
                        </div>
                        
                        <div className="product-actions">
                            <Button type="primary" onClick={()=>{setProductInfo(product)}}><SearchOutlined /> Ver información</Button>
                        </div>
                    </div>
                </Col>
    }

    const getProductInfoMobile = (product: ProductInfo, key: string) : JSX.Element => {

        return <div style={contentStyle} className={`product-info-mobile`} key={key}>
                    <div className="product-type">
                        {getProductTypeIcon(product.type)}
                    </div>
                    
                    <div className="product-name">
                        <Typography.Title level={4} className="title">{product.name}</Typography.Title>
                        <p>
                            {product.description}
                        </p>
                    </div>
                    <div className="button-info">
                        <Button type="primary" onClick={()=>{setProductInfo(product)}}><SearchOutlined /> Ver información</Button>
                    </div>
                </div>
    }

    const init = () => {
        setSelectedSteps(1);
        setAcceptTermsAndConditions(false);
        setProductInfo(undefined);
        setPaymentInfo(undefined);
        setOnePayModeData([]);
        setPayPerUseModeData([]);
        setMultipleModeData([]);
        setAvailableDiscounts([]);
        getProductsForEnroll(enrollmentData.categoryId, enrollmentData.idSportCenter);
        getDiscountsInfo();
    }


    const [stepSelector, setStepSelector] = useState<JSX.Element>(<></>)
    const [selectedSteps, setSelectedSteps] = useState<number>(1);
    const formatter = (value?: number) => `en ${value} meses`;
    const getSteps = () => {
        if (productInfo && productInfo.type == "Subscription"){
            var subscriptionProductInfo = multipleModeData.filter((p: ProductInfo[]) => p.findIndex(pp => pp.hash == productInfo.hash) !== -1)

            var minQuotes = subscriptionProductInfo[0][0].minQuotes;
            var maxQuotes = subscriptionProductInfo[0][subscriptionProductInfo[0].length - 1].maxQuotes;
    
            setStepSelector(<></>);
            setTimeout(()=>{
                setSelectedSteps(maxQuotes)
                setStepSelector(<div className="steps-selector">
                                    <Divider style={{backgroundColor:"white", margin: "3px 0"}}/>
                                    <span>Selecciona la cuota que más te convenga</span>

                                    <Slider
                                            style={{color:"red"}}
                                            tooltipPlacement="bottom"
                                            tipFormatter={formatter}
                                            min={minQuotes}
                                            max={maxQuotes}
                                            defaultValue={maxQuotes}
                                            onChange={setSelectedSteps}
                                        />
                                    <Divider style={{backgroundColor:"white", margin: "3px 0"}}/>
                                </div>) 
            }, 50);
            
        }else{
            setStepSelector(<></>);
            setSelectedSteps(1);
        }
    }

    const [paymentInfo, setPaymentInfo] = useState<ProductInfo>();
    const getPaymentInfo = () => {
        if (productInfo && productInfo.type == "Subscription"){
            var subscriptionProductInfo = multipleModeData.filter((p: ProductInfo[]) => p.findIndex(pp => pp.hash == productInfo.hash) !== -1)

            var minQuotes = subscriptionProductInfo[0][0].minQuotes;
            var maxQuotes = subscriptionProductInfo[0][subscriptionProductInfo[0].length - 1].maxQuotes;

            if (selectedSteps < minQuotes){
                setSelectedSteps(maxQuotes);
                setPaymentInfo(subscriptionProductInfo[0][subscriptionProductInfo[0].length - 1]);
            }else{
                setPaymentInfo(subscriptionProductInfo[0].filter(p => p.minQuotes == selectedSteps)[0]);

            }
        }else{
            setPaymentInfo(productInfo);
        }
    }

    const getIntervalString = (interval: number, intervalCount: number) : string => {
        let result : string = '';
        switch(interval){
            case 0:
                
                result = `dia${intervalCount > 1 ? '(s)' : ''}`
            break;
            case 1:
                result = `semana${intervalCount > 1 ? '(s)' : ''}`
            break;
            case 2:
                result = `mes${intervalCount > 1 ? '(es)' : ''}`
            break;
            case 3:
                result = `año${intervalCount > 1 ? '(s)' : ''}`
            break;
        }

        return result;
    }

    const [acceptTermsAndConditions, setAcceptTermsAndConditions] = useState<boolean>(false);
    const termnsAndConditions = (v: any) : void => {
        setAcceptTermsAndConditions(v.target.checked);
    }

    const [loadingPayment, setLoadingPayment] = useState<boolean>(false);
    const makePayment = () : void => {

        if (paymentInfo){
            setLoadingPayment(true);
            getUserIP().then(((r : any) =>{
                //if (r && r.IPv4){
            if (r && r.ip){

                    let data : PayEnrollment = {
                        enrollmentId: props.enrollmentData.id,
                        productId: paymentInfo?.id,
                        quotes: selectedSteps,
                        payInitialAmount: paymentInfo?.initialPay > 0,
                        discounts: filterDiscounts(availableDiscounts, paymentInfo.type, [paymentInfo.id], props.enrollmentData.idSportCenter),
                        IP: r.ip
                    }
                    
                    if (use3DSecure && data.payInitialAmount){
                        prepareThreeDSecurePayment(data, paymentInfo);
                    }else{
                        createSubscription(data);
                    }

                }else{
                    notification.error({
                        message: 'Atención',
                        description: 'No se ha podido realizar la operación. Asegúrese de no tener un bloqueador activo en su navegador. (1)'
                    })
                    setLoadingPayment(false);
                }
            }))
        }else{
            notification.warn({
                message: 'Atención',
                description: 'Por favor, Seleccione un producto primero.',
              })
        }
    }

    const createSubscription = (data: PayEnrollment) => {
        const params : ParamsApi = {
            body: data
        }

        setLoadingPayment(true);
        restApiClient.fetch("PayEnrollment", params)
                        .then((r : ApiResponse | null)=> {
                            if (r !== null && r.code === 200){
                                props.close();
                            }
                        }).finally(()=>{
                            setLoadingPayment(false);
                        });
    }

    const prepareThreeDSecurePayment = (data: PayEnrollment, productInfo: any) : void =>{
        // Creamos el PaymentIntent en el servidor y, si ha ido mal mostramos error y si ha ido bien aparecería la modal

        const params : ParamsApi = {
            body: data
        }
        setLoadingPayment(true);
        restApiClient.fetch("ThreeDSecurePaymentStep1", params)
                .then((r : ApiResponse | null)=> {
                    if (r !== null && r.code === 200){
                        threeDSecurePayment(r.data.clientSecret, data);
                    }else{
                        setLoadingPayment(false);
                    }
                });
    }

    //https://stripe.com/docs/payments/integration-builder
    const stripe = useStripe();
    const threeDSecurePayment = async (clientSecret: string, data: PayEnrollment) =>{
        setLoadingPayment(true);
        const payload = await stripe?.confirmCardPayment(clientSecret);

        if (payload?.error){
            notification.error({
                message: 'Error en cargo',
                description: 'No se pudo verificar su identidad por lo que el pago fue rechazado'
            });
            setLoadingPayment(false);
        }else{
            data.payInitialAmount = false;
            createSubscription(data);
        }
        
    }


    useEffect(()=>{
        getPaymentInfo();
    },[selectedSteps])

    useEffect(()=>{
        getSteps();
        getPaymentInfo();
        setAcceptTermsAndConditions(false);
    },[productInfo])

    useEffect(()=>{
        if (paymentInfo){
            setAppliedDiscounts(filterDiscounts(availableDiscounts, paymentInfo.type, [paymentInfo.id], enrollmentData.idSportCenter));
        }
    },[paymentInfo])

    useEffect(()=>{
        init();
    },[])


    const [ use3DSecure, setUse3DSecure ] = useState<boolean>(false);
    const show3DSecure = () => {
        return <div className="safety-certificate"><SafetyCertificateTwoTone className="shield" twoToneColor="#52c41a"/> ¡Su tarjeta está configurada para realizar pagos seguros!</div>
    }

    const contentStyle: React.CSSProperties = {
        color: '#fff',
        background: '#364d79',
      };
      

    return(
        <div className="checkout-component-v2">
            <Skeleton active loading={loadingProducts}>
                <div className="product-list">
                    <div className="refresh-button">
                        <Button onClick={init} type="primary" ghost><ReloadOutlined /> Recargar listado</Button>
                    </div>
                    <Divider orientation="left">Seleccione tipo de inscripción</Divider>
                    
                    <Row>
                        <Col xs={24} sm={0}>
                                <Carousel >
                                {
                                    onePayModeData?.map((product: ProductInfo, index: number) => {
                                        return <div>
                                                    {getProductInfoMobile(product, `onepay-mvl-${index}`)}
                                                </div>;
                                    })
                                }
                                {
                                    multipleModeData?.map((product: ProductInfo[], index: number) => {
                                        return <div>
                                                    {getProductInfoMobile(product[0], `multiple-mvl-${index}`)}
                                                </div>;
                                    })
                                }
                                {
                                    payPerUseModeData?.map((product: ProductInfo, index: number) => {
                                        return <div>
                                                    {getProductInfoMobile(product, `pay-per-use-mvl-${index}`)}
                                                </div>;
                                    })
                                }
                            </Carousel>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={0} sm={24}>
                            <Row gutter={16}>
                                {
                                    onePayModeData?.map((product: ProductInfo, index: number) => {
                                        return getProductInfo(product, `onepay-${index}`);
                                    })
                                }

                                {
                                    multipleModeData?.map((product: ProductInfo[], index: number) => {
                                        var p : ProductInfo = product[0];
                                        return getProductInfo(p, `multiple-${index}`);
                                    })
                                }

                                {
                                    payPerUseModeData?.map((product: ProductInfo, index: number) => {
                                        return getProductInfo(product, `pay-per-use-${index}`);
                                    })
                                }
                            </Row>
                        </Col>
                    </Row>
                </div>
                
                {
                    productInfo ? 
                        <>
                            <Divider orientation="left">Información del tipo seleccionado</Divider>
                        
                            <div className="selected-product-info">
                                <Row gutter={16}>
                                    <Col xs={24} sm={24} md={18} lg={18} xl={18}>
                                        <div className="body-info">
                                            {
                                                productInfo ? 
                                                    <>
                                                        <div className="available-date">
                                                            <Tooltip title="A partir de esta fecha dejarán de realizarse cargos por esta inscripción.">
                                                                <InfoCircleTwoTone />
                                                            </Tooltip> 
                                                            &nbsp;Disponible hasta: <strong>{moment(productInfo.toDate).format("DD/MM/YYYY")}</strong>
                                                        </div>

                                                        {getProductTypeIcon(productInfo.type)}

                                                        <Typography.Title level={4}>
                                                            {productInfo.name}
                                                        </Typography.Title>

                                                        <p>
                                                            {productInfo.description}
                                                        </p>

                                                        <div className='features'>
                                                            <Typography.Title level={5}>
                                                                Características
                                                            </Typography.Title>
                                                            {productInfo.features?.map((feature, index)=>{
                                                                return <Tag color="volcano" className="feature" key={index}>
                                                                            {feature}
                                                                        </Tag>
                                                            })}
                                                        </div>

                                                        <div className='particular-conditions'>
                                                            <Typography.Title level={5}>
                                                                Condiciones particulares del producto
                                                            </Typography.Title>
                                                            <TextArea value={productInfo.termsAndConditions} rows={5}/>
                                                            <Checkbox checked={acceptTermsAndConditions} onChange={termnsAndConditions}/> Entiendo y acepto las condiciones
                                                        </div>
                                                    </>
                                                :
                                                    <></>
                                            }
                                        </div>
                                    </Col>
                                    <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                                        <div className="payment-resume">
                                            {
                                               // paymentInfo ? selectedSteps * ((paymentInfo?.pvp/100) - CalculateDiscountWithSteps(paymentInfo.pvp /100, filterDiscounts(availableDiscounts, paymentInfo.type, [paymentInfo.id], enrollmentData.idSportCenter), selectedSteps)) : 0
                                            }

                                            <div className="pricing-resume">
                                                    {
                                                        paymentInfo ? 
                                                            <Typography.Title 
                                                                    level={1} 
                                                                    className="reduced-price">
                                                                        €{CalculateDiscountWithSteps(paymentInfo.pvp /100, appliedDiscounts, selectedSteps)}
                                                                        <sup>/{
                                                                                getIntervalString(paymentInfo.interval, paymentInfo.intervalCount)}
                                                                        </sup>
                                                            </Typography.Title>
                                                        :
                                                            <></>
                                                    }

                                                    <ul>
                                                    {
                                                        //Los descuentos no aplican a "Pago por uso". No se saben cuándo finalizan y por lo tanto no se puede aplicar un descuento.
                                                        appliedDiscounts && appliedDiscounts.length > 0 && paymentInfo && paymentInfo.type !== "PayPerUse" ? 
                                                            <li>Precio antes de descuentos&nbsp;
                                                                <strong>
                                                                    €{(paymentInfo?.pvp/100)}
                                                                    <sup>/{
                                                                        getIntervalString(paymentInfo.interval, paymentInfo.intervalCount)}
                                                                    </sup>
                                                                </strong>
                                                            </li>
                                                        :
                                                            <></>
                                                    }
                                                    {
                                                        //Si tiene pago inicial, indicamos la cantidad
                                                        paymentInfo && paymentInfo.initialPay > 0 ?
                                                            <li>Pago inicial de <strong>{(paymentInfo?.initialPay)}€</strong></li>
                                                        :
                                                            <></>
                                                    }
                                                    {
                                                        //Mostraoms los descuentos. No aplican a pago por uso
                                                        appliedDiscounts.map((discountInfo: Discount)=> {
                                                            return <li>Dcto.: {discountInfo.discountDescription}</li>
                                                        })
                                                    }
                                                    </ul>
                                                    
                                            </div>

                                            

                                            {
                                                stepSelector
                                            }

                                            
                                            <div className='payment-buttons'>
                                                <Button style={{width: "100%"}} className="make-payment" disabled={!acceptTermsAndConditions || loadingPayment} type="primary" size='large' onClick={makePayment}>
                                                    {
                                                        !acceptTermsAndConditions ? <><WarningTwoTone /> Acepte condiciones</> : (loadingPayment ? <><LoadingOutlined /> Pagando...</> : <><EuroCircleOutlined /> Realizar pago</>)
                                                    }
                                                    
                                                </Button>
                                                {
                                                    use3DSecure ?  
                                                        <Col xs={24} style={{textAlign:"center"}}>
                                                            {show3DSecure()}
                                                        </Col>
                                                    :
                                                    <></>
                                                }
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                
                            </div>
                        </>
                    :
                        <div className="select-first">
                            <ArrowUpOutlined className="arrow"/>
                            <Alert className="message" message="¡Seleccione un tipo de inscripción!" type="warning" />
                        </div>
                }
            </Skeleton>
        </div>
    )
}

export default CheckoutComponentV2;