import React, {useState} from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";

import { Row, Col, Typography, Alert, Form } from 'antd';
import { LoadingOutlined, PlusSquareOutlined } from '@ant-design/icons';

import RestApiClient from '../../../../api/restApiClient';
import ParamsApi from '../../../../models/api/ParamsApi';

import '../../../../styles/stripe/stripeStyles.css';
import './StripeCreditCardForm.scss';
import ApiResponse from "../../../../models/api/ApiResponse";

const { Title } = Typography;

interface StripeRegisterCreditCardProps {
    requireRefresh: any
}


const StripeRegisterCreditCardForm = (props : StripeRegisterCreditCardProps): JSX.Element => {
    const restApiClient : RestApiClient = new RestApiClient();

    const [formCreditCard] = Form.useForm();
    
    const stripe = useStripe();
    const elements = useElements();
    const [disabled, setDisabled] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(false);
    const [ ref, setRef ] = useState<any>(null);


    const handleSubmitCreditCard = async (event: any) => {

        if (!stripe || !elements) {
        // Stripe.js has not loaded yet. Make sure to disable
        // form submission until Stripe.js has loaded.
        return;
        }

        const payload = await stripe.createPaymentMethod({
            type: "card",
            card: elements.getElement(CardElement)!
        });

        if (!payload.error){
            const params : ParamsApi = {
                body: {
                    sessionId: sessionStorage.getItem('token'),
                    tokenCreditCard: payload.paymentMethod?.id,
                }
            }

            setLoading(true);
            restApiClient.fetch("AddCreditCard", params)
                        .then((r : ApiResponse | null) => {
                            if (r?.code === 200){
                                props.requireRefresh(true);

                                if (ref !== null){
                                    ref.clear();
                                }
                            }
                        }).finally(()=>{
                            setLoading(false);
                        })
        }
    };

    return ( 
            <>
                <Row gutter={16}>
                    <Col xs={24}>
                        <Title className="c-title"  level={3}>
                            <PlusSquareOutlined /> &nbsp;
                            Añade tu tarjeta
                            <small className="c-sub-title">
                                ¡Completamente seguros gracias a <a href="https://www.stripe.com/es" target="_blank">Stripe!</a>
                            </small>
                        </Title>
                    </Col>
                </Row>
                
                <Title className="c-title" level={2}>
                            Tarjeta de crédito
                        </Title>
                        <Form layout="vertical" className="stripe-credit-card-form" size="small" onFinish={handleSubmitCreditCard} form={formCreditCard}>
                            <label>
                                <CardElement
                                    onReady={(e) => {
                                        setRef(e);
                                    }}
                                    onChange={event => {
                                        setDisabled(!event.complete);
                                    }}
                                    onBlur={() => {
                                    }}
                                    onFocus={() => {
                                    }}
                                />
                            </label>
                            <button type="submit" disabled={(!stripe || (stripe && disabled) || loading)} className="ant-btn ant-btn-primary btn-add">
                                {
                                    loading ? <LoadingOutlined style={{marginRight: "5px"}}/> : ''
                                }
                                Registrar tarjeta de crédito
                            </button>
                        </Form>

                        <Row gutter={16} className="credit-card-warning">
                            <Col xs={24}>
                                <Alert
                                        message="Información"
                                        description={<>Nosotros <strong>no guardamos información sobre tu tarjeta de crédito</strong>. Usamos la pasarela de pago de <a href='https://www.stripe.com' target="_blank">Stripe</a> y ellos son los encargados de almacenar la información de manera segura.</>}
                                        type="info"
                                        showIcon
                                        />
                                        
                            </Col>
                        </Row>

                        <Row gutter={16} className="credit-card-warning">
                            <Col xs={24}>
                                <Alert
                                        message="Atención"
                                        description="Sólo se podrán realizar inscripciones en modo aplazado con aquellas tarjetas de crédito cuya caducidad no transcurra en el periódo de pagos de la inscripción."
                                        type="warning"
                                        showIcon
                                        />
                            </Col>
                        </Row>
                
                

                
            </>
    );
}

export default StripeRegisterCreditCardForm;