import React, { useContext, useEffect, useState } from 'react'

import { Form, Row, Col, Button, Select, Alert } from 'antd'

import { AthleteListContext } from '../../hooks/context/athleteContext'


import ApiResponse from '../../models/api/ApiResponse';
import RestApiClient from '../../api/restApiClient';
import ParamsApi from '../../models/api/ParamsApi';
import SportCenter from '../../models/SportCenter';
import { ReloadOutlined } from '@ant-design/icons';

const { Option } = Select;

interface EnrollmentsAddProperties{
    onFinish: () => void
    onClose: () => void
}

const AddEnrollmentsFormComponent = (props : EnrollmentsAddProperties) : JSX.Element => {

    const restApiClient : RestApiClient = new RestApiClient();

    const athleteListContext = useContext(AthleteListContext);

    const [form] = Form.useForm();

    const [sportCenters, setSportCenters] = useState<SportCenter[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(()=>{
        restApiClient.fetch("GetSportCenters", {})
            .then((r : ApiResponse | null) => {
                if (r?.code === 200){
                    setSportCenters(r.data);
                }
            })
    }, [])

    const onFinish = () : void => {
        const params : ParamsApi = {
            body: {
                athleteId: form.getFieldValue("idAthlete"),
                sportCenterId: form.getFieldValue("location"),
                clothingSize: form.getFieldValue("size")
            }
        }

        setLoading(true);
        restApiClient.fetch("AddEnrollment", params)
            .then((r : ApiResponse | null) => {
                if (r?.code === 200){
                    form.resetFields();
                    props.onFinish();
                }
            }).finally(()=>{
                setLoading(false);
            })
    }


    return (
        <Form layout="vertical" size="small" form={form} onFinish={onFinish}>
            <Row gutter={20}>
                <Col xs={24}>
                    <Form.Item
                        name="idAthlete"
                        label="Seleccione una persona"
                        className="form-item"
                        rules={[{required: true, message: 'Este campo es obligatorio'}]}
                    >
                        <Select placeholder="Seleccione un atleta">
                            {
                                athleteListContext.list.map(athlete => {
                                    return <Option key={athlete.id} value={`${athlete.id}`}>{athlete.name} {athlete.surname} {athlete.secondSurname}</Option>
                                })
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="location"
                        label="Seleccione el centro"
                        className="form-item"
                        rules={[{required: true, message: 'Este campo es obligatorio'}]}
                    >
                        <Select placeholder="Seleccione un centro">
                            {
                                sportCenters.filter(sc => {
                                    return sc.isActive
                                }).map(sc => {
                                    return <Option value={sc.id} key={sc.id}>{sc.description}</Option>
                                })
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="size"
                        label={<label>Seleccione talla de uniformidad <small >&nbsp;&nbsp;<strong>(Solo si el centro lo requiere.)</strong></small></label>}
                        className="form-item"
                        initialValue={""}
                        rules={[{required: true, message: 'Este campo es obligatorio'}]}
                    >
                        <Select placeholder="Seleccione una opción">
                            <Option value="SU">Sin uniformidad</Option>
                            <Option value="6">6</Option>
                            <Option value="8">8</Option>
                            <Option value="10">10</Option>
                            <Option value="12">12</Option>
                            <Option value="XS">XS</Option>
                            <Option value="S">S</Option>
                            <Option value="M">M</Option>
                            <Option value="L">L</Option>
                            <Option value="XL">XL</Option>
                            <Option value="XXL">XXL</Option>
                            <Option value="XXXL">XXXL</Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={24}>
                    <Form.Item className="btn-add-athlete">
                        <Button type="primary" danger onClick={props.onClose}>
                            Cancelar
                        </Button>
                        <Button type="primary" htmlType="submit" disabled={loading} className="btn-save-enrollment">
                            {
                                loading ? <ReloadOutlined spin /> : ''
                            }
                            Guardar
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col xs={24}>
                    <Alert
                        message={<strong>Atención</strong>}
                        description={<>Si pulsa guardar su plaza <u><strong>NO queda asegurada</strong></u>. Tan solo el pago de la inscripción conlleva reserva de plaza.</>}
                        type="warning"
                        showIcon
                        />
                </Col>
            </Row>
        </Form>
    )
}

export default AddEnrollmentsFormComponent;