import React, { useEffect, useState } from 'react'
import { Row, Col, Typography, Empty, Table, Alert, Button } from 'antd'
import { CreditCardOutlined, LoadingOutlined, ReloadOutlined } from '@ant-design/icons'

import ApiResponse from '../../../../models/api/ApiResponse'
import RestApiClient from '../../../../api/restApiClient'
import ParamsApi from '../../../../models/api/ParamsApi'

const { Title } = Typography

interface StripeCardInfoProps{
    refreshData: boolean,
    refreshed: any,
}

const columns = [
    {
      title: 'Tipo',
      dataIndex: 'type',
      key: 'type',
      width: 50,
    },
    {
      title: 'Modalidad',
      dataIndex: 'funding',
      key: 'funding',
      width: 50
    },
    {
        title: 'Últimos 4 dígitos',
        dataIndex: 'last4Numbers',
        key: 'last4Numbers'
        ,width: 150
      },
      {
        title: 'Caducidad',
        dataIndex: 'caducity',
        key: 'caducity'
        ,width: 100
      },
  ];


const StripeCreditCardInfoComponent = (props : StripeCardInfoProps) : JSX.Element => {

    const restApiClient : RestApiClient = new RestApiClient();

    const [data, setData] = useState<any[]>([]);

    const [loadingData, setLoadingData] = useState<boolean>(false);
    const [settingDefault, setSettingDefault] = useState<boolean>(false);

    const setAsDefaultPaymentMethod = () => {
        const params : ParamsApi = {
            query: {
                sessionId: sessionStorage.getItem('token'),
                paymentMethod: 'Card'
            }
        }

        setSettingDefault(true);
        restApiClient.fetch("SetAsDefaultPaymentMethod", params)
                    .then((r : ApiResponse | null)=> {
                        props.refreshed(true);                        
                    })
                    .finally(()=>{
                        setSettingDefault(false);
                    });
    }

    const getCardData = () => {
        const params : ParamsApi = {
            query: {
                sessionId: sessionStorage.getItem('token'),
            }
        }

        setLoadingData(true);
        restApiClient.fetch("GetMyCreditCardData", params)
                    .then((r : ApiResponse | null)=> {
                        setData([r?.data]);
                    }).finally(()=>{setLoadingData(false)});
    }


    useEffect(()=>{
        getCardData();
    }, []);

    useEffect(()=>{
        if (props.refreshData){
            props.refreshed(false);
            getCardData();
        }
    }, [props.refreshData]);

    return(
        <Row gutter={16} className="stripe-credit-card-info">
            <Col xs={24}>
                <Title className="c-title"  level={3}>
                    <CreditCardOutlined /> &nbsp; 
                    Tus tarjetas
                    <small className="c-sub-title">
                        Tus formas de pago
                    </small>
                    <div style={{textAlign: "right"}}>
                        <Button size='small' onClick={getCardData} type="primary" style={{marginRight: "5px"}}>
                            {
                                loadingData ? <><LoadingOutlined /> Cargando...</> : <><ReloadOutlined /> Recargar</>
                            }
                        </Button>
                        <Button danger ghost disabled={data && data[0]?.isDefaultMethod} size='small' onClick={setAsDefaultPaymentMethod} type="primary">
                            {
                                settingDefault ? <><LoadingOutlined /> Marcando...</> : <><ReloadOutlined /> Marcar por defecto</>
                            }
                        </Button>
                    </div>
                </Title> 
            </Col>
            {
                (data && data[0]?.isDefaultMethod) ? <Col xs={24}> <Alert message="Este es el método de pago elegido actualmente" type="info" style={{marginBottom: "5px"}} showIcon/></Col> : ''
            }
            <Col xs={24}>
                {
                    data.length > 0 
                    ?
                    <>
                        <Table rowKey={"type"} dataSource={data} columns={columns} scroll={{ x: 300 }} />
                    </>
                    :
                    <div>
                        <Empty
                            image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                            imageStyle={{
                            height: 60,
                            }}
                            description={
                            <span>
                                Aún no has registrado ninguna tarjeta de crédito.
                            </span>
                            }
                        >
                        </Empty>
                    </div>
                }
            </Col>
        </Row>
    )
}

export default StripeCreditCardInfoComponent;