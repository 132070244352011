import React, { useEffect, useState } from 'react'
import {EuroCircleOutlined, RocketOutlined, TrophyOutlined} from '@ant-design/icons';

import './navigatorComponent.scss';


interface NavigatorProps{
    containerId: string
}

const NavigatorComponent = (props : NavigatorProps) : JSX.Element => {

    const [containerId, setContainerId] = useState<string>('');

    const onScrollTo = (id: string) : boolean => {
        const container = document.getElementById(containerId);
        const element   = document.getElementById(id);

        if (!container || !element){
            return false;
        }

        const parent    = container.parentElement;

        if (!parent){
            return false;
        }


        parent.scrollTo(0, 0);
        const elementPositions = element.getBoundingClientRect();

        parent.scrollTo({
            top: (elementPositions.top-100),
            behavior: 'smooth',
          })

        return true;
    }

    useEffect(()=>{
        setContainerId(props.containerId);
    }, []);

    return(
        <ul className="checkout-navigator-balls">
                            <li className="h-one-pay" onClick={()=>{ onScrollTo('one-pay') }}>
                                <TrophyOutlined />
                            </li>
                            <li className="h-multi-pay" onClick={()=>{  onScrollTo('multi-pay') }}>
                                <RocketOutlined />
                            </li>
                            <li className="h-pay-per-use" onClick={()=>{  onScrollTo('pay-per-use') }}>
                                <EuroCircleOutlined />
                            </li>
                        </ul>
    )
}

export default NavigatorComponent;