import Person from './Person'

import moment from "moment"



export default interface Athlete extends Person{
    id? : number, 
    idUser? : number,
    // Él mismo | Padre, Madre o Tutor Legal (FatherMatherOrLegalGuardian)
    relationship: "myself" | "fmolg" | "couple",
    personalTrainer: Person,
    rfeaCode: string,
    externalUrl: string,
    registerDate: any, // Use moment
}

export const EmptyAthlete : Athlete ={
    birthDate: moment(),
    name: 'test',
    relationship: 'myself',
    secondSurname: '',
    surname: '',
    dniNie: '',
    rfeaCode:'',
    externalUrl: '',
    id: 0,
    registerDate: moment().add(-1, "y"),
    personalTrainer:{
        name: '',
        surname: '',
        secondSurname: '',
        birthDate: moment(),
        dniNie: ''
    }
}
