import React, { useEffect } from 'react'

import * as Pages from '../../../pages/index'

import {
    BrowserRouter as Router,
    Switch,
    Route,
  } from "react-router-dom";

export const PublicRoutesComponent = (props : any) => {

    return(
        <Router forceRefresh={true}>
            <Switch>
                <Route exact path={["/",
                                    "/login", 
                                    "/dashboard", 
                                    "/routine/:id", 
                                    "/validate-account/:validateAccountHash", 
                                    "/change-my-password/:changePasswordHash", 
                                    "/login-as-user/:utk/:uname"
                                    ]}>
                    <Pages.Login />
                </Route>
                <Route path="/LoginAsUser">
                    <Pages.Login />
                </Route>
                <Route path="/lockscreen">
                    <Pages.LockScreen />
                </Route>
                <Route path="/register">
                    <Pages.Register />
                </Route>
                <Route path="/remove-account">
                    <Pages.RemoveAccount />
                </Route>
            </Switch>
        </Router>
    )
}