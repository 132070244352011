import EndPointConfig from '../../models/api/EndPointConfig'

const controller = 'Enrollments'

export const EnrollmentsEP : EndPointConfig[] =[
    new EndPointConfig()
        .setMethod('POST')
        .setController(controller)        
        .setActionName("GetUserEnrollments"),
    new EndPointConfig()
        .setMethod('POST')
        .setController(controller)        
        .setActionName("AddEnrollment"),
    new EndPointConfig()
        .setMethod('PUT')
        .setController(controller)        
        .setActionName("UpdateEnrollment"),
    new EndPointConfig()
        .setMethod('PUT')
        .setController(controller)        
        .setActionName("ChangeEnrollmentClothingSize"),
    new EndPointConfig()
        .setMethod('DELETE')
        .setController(controller)        
        .setActionName("RemovePreEnrollment")

]