import React, { useEffect, useState } from 'react'

import { Row, Col, Typography, Steps, Table, Empty, Divider, Select, Tooltip, Button, notification } from 'antd'

import Enrollment, * as EnrollmentModels from '../../models/Enrollments';
import moment from 'moment';
import 'moment/locale/es' 

import './enrollments.detail.scss'
import PaymentData from '../../models/PaymentData';

import { InfoCircleTwoTone, CheckCircleTwoTone, StarFilled } from '@ant-design/icons';

import ApiResponse from '../../models/api/ApiResponse'
import RestApiClient from '../../api/restApiClient'
import ParamsApi from '../../models/api/ParamsApi';
import Meta from '../../models/Meta';
import { useStripe } from '@stripe/react-stripe-js';

const { Title } = Typography;
const { Step } = Steps;
const { Option } = Select;

interface EnrollmentDetailProps{
    data: Enrollment
}

const EnrollmentDetailComponent = (props : EnrollmentDetailProps) : JSX.Element => {
    const restApiClient : RestApiClient = new RestApiClient();

    const [enrollment, setEnrollment] = useState<Enrollment>(EnrollmentModels.EmptyEnrollment);
    const [currentStep, setCurrentStep] = useState<number>(0);
    const [currentStepStatus, setCurrentStepStatus] = useState<"wait" | "process" | "finish" | "error" | undefined>('wait')
    const [refundedData, setRefundedData] = useState<PaymentData[]>([]);
    const [actualSize, setActualSize] = useState<string>('');

    const paymentsColumns : any[] = [
        {
            title: 'Fecha',
            dataIndex: 'date',
            key: 'date',
            render: (value: any) => { 
                let payDate = moment(value, "DD/MM/YYYY HH:mm", true).format("DD/MM/YYYY HH:mm");

                if (payDate.toString() === "Fecha inválida"){
                    payDate = moment(value).format("DD/MM/YYYY HH:mm");
                }
                
                return payDate.toString()
            }
        },
        {
            title: 'Concepto',
            dataIndex: 'description',
            key: 'description'
        },
        {
            title: 'Cantidad',
            dataIndex: 'amount',
            key: 'amount',
            render: (value: any, row: PaymentData) => {
                const amount = value / 100;
                
                return `${amount}€`;

            }
        },
        {
            title: 'Estado',
            dataIndex: 'status',
            key: 'status',
            render: (value: 'payed' | 'error' | 'refund' | 'voided' | 'processing', row: PaymentData) => {
                let result : any = value;
                switch(value){
                    case 'voided':
                        result = <Tooltip placement='topLeft' title='Este pago requirió de validación por tu parte pero expiró. Este cargo no vuelve a intentarse. La inscripción puede quedar cancelada.'><InfoCircleTwoTone twoToneColor="#eb2f96" /></Tooltip>
                        break;
                    case 'error':
                        const meta : Meta | undefined = row.paymentMeta.find(m => m.key === "stripe_client_secret");
                        const link : Meta | undefined = row.paymentMeta.find(m => m.key === "gateway_payment_link");

                        result = <Tooltip placement='topLeft' title='En el caso de suscripciones el cobro volverá a intentar pasarse proximamente. Si se sigue devolviendo la cuota quedaría pendiente de pago.'><InfoCircleTwoTone twoToneColor="#eb2f96" /></Tooltip>
                        
                        if (meta){
                            result = <>
                                <Button style={{ width: "100%"}} type="ghost" size="small" onClick={()=>{threeDSecurePayment(meta.value)}}>Pago seguro</Button>
                                {
                                    link ? 
                                    <>
                                        <small style={{display:"block"}}>Si cancelaste y ahora no te deja realizar el pago prueba pinchando <a href={link.value} target="_blanck">aquí</a></small>
                                        <small style={{display:"block"}}>El pago no aparecerá hasta refrescar la página.</small>
                                    </> : ''
                                }
                            </>
                        }

                        break;
                    case "refund":
                        result = <Tooltip placement='topLeft' title='Devolución realizada sobre el importe total de la inscripción que consulta.'><InfoCircleTwoTone twoToneColor="#FFC107" /></Tooltip>
                        break;
                    case 'payed':
                        result = <Tooltip placement='topLeft' title='Pago procesado correctamente.'><CheckCircleTwoTone twoToneColor="#52c41a" /></Tooltip>;
                        break;
                    case 'processing':
                        result = <Tooltip placement='topLeft' title='El pago está siendo procesado por su banco pero aún no tenemos confirmación del mismo.'><CheckCircleTwoTone twoToneColor="violet" /></Tooltip>;
                        break;
                    default:
                        break;
                }

                return result;
            }
        }
    ]

    

    const [payed, setPayed] = useState<number>(0);

    //https://stripe.com/docs/payments/integration-builder
    const stripe = useStripe();

    const threeDSecurePayment = async (clientSecret: string) =>{
        const payload = await stripe?.confirmCardPayment(clientSecret);

        if (payload?.error){
            notification.error({
                message: 'Error en cargo',
                description: 'No se pudo verificar su identidad por lo que el pago fue rechazado'
            })
        }else{
            notification.success({
                message: 'Pago realizado',
                description: 'El pago fue realizado con éxito'
            })
        }
    }

    const setResume = () => {
        let _payed = 0;
        let _pending = 0;
        let _quotes = 0;

        if (enrollment.paymentData !== null && enrollment.paymentData !== undefined && enrollment.paymentData.length > 0){
            enrollment.paymentData.forEach((payment : PaymentData) => {
                _payed += payment.amount;
            });
        }

        if (enrollment.status === "free" || !enrollment.productInfo){
            _pending = 0;
            _quotes = 0;
        }else{
            switch(enrollment.productInfo.type){
                case "OnePay":
                    _pending = 0;
                    _quotes = 0;
                    break;
                case "Subscription":
                    const totalAmount = (enrollment.productInfo.maxQuotes * (enrollment.productInfo.pvp / 100)) + enrollment.productInfo.initialPay;
                    _pending = totalAmount - _payed;
                    _quotes = enrollment.productInfo.maxQuotes;
                    break;
                case "PayPerUse":
                    _quotes = 0;
                    break;
            }
        }

        setPayed(_payed / 100);
    }

    const getRefundedData = () => {
        const rows: PaymentData[] | undefined = enrollment.paymentData;
        
        const refundedData : PaymentData[] = [];
        rows?.forEach(row => {
            let refundedAmounts = row.paymentMeta.filter(pm => {
                return pm.key.indexOf("refund") !== -1;
            });
            
            refundedAmounts.forEach(ra => {
                const refundId = ra.key.split("-")[1];
                const refundMetaDate = row.paymentMeta.find(pm => pm.key === refundId+"-id")?.value;
                let refundData : PaymentData = {
                    amount: Number.parseInt(ra.value),
                    date: refundMetaDate,
                    description: "Devolución de cantidad",
                    gateway: "",
                    paymentMeta: [],
                    status: "refund"
                }
    
                refundedData.push(refundData);
    
            });
        });
        setRefundedData(refundedData);
    }

    const changeSize = (size: any) => {
        const params : ParamsApi = {
            body:{
                sessionId: sessionStorage.getItem("token"),
                enrollmentId: enrollment.id,
                newSize: size
            }
        };

        restApiClient.fetch("ChangeEnrollmentClothingSize", params).then((r :ApiResponse | null) => {
            if (r?.code === 200){
                setActualSize(size);
            }
        });
        
    }

    const getEndStep = () : JSX.Element => {
        let result : JSX.Element = <></>;

        if (enrollment && enrollment.status === "active"){
            const expiredAt : any = enrollment.meta.find(m => m.key === "expired_at");

            if (expiredAt){
                result = <Step title="Finalizará" description={`automaticamente al cierre del curso/temporada.`}  />
            }else{
                result = <Step title="Finalizada" description='' />;
            }
        }else{
            result = <Step title="Finalizada" description='' />;
        }

        return result;
    }

    useEffect(()=>{
        setResume();
        getRefundedData();
        setActualSize(enrollment.size);
    },[enrollment])


    useEffect(()=>{
        setEnrollment(props.data);

        switch(props.data.status){
            case 'free':
            case 'active':
                setCurrentStep(1);
                setCurrentStepStatus('process');
                break;
            case 'cancelled':
                setCurrentStep(1);
                setCurrentStepStatus('error');
                break;
            case 'finished':
                setCurrentStep(2);
                setCurrentStepStatus('finish');
                break;
            case 'pre':
                setCurrentStepStatus('wait');
                setCurrentStep(0);
                break;
        }
    }, [props.data])

    return (
        <Row gutter={16} className="subscription-payment-data">
            <Col xs={24} className="detail-info-box-header">
                    <div className="location-category-data">
                        <Title level={5}>{`#INS-${enrollment.id} - ${enrollment.athlete.name} ${enrollment.athlete.surname} ${enrollment.athlete.secondSurname}`}
                        - Talla elegida &nbsp;
                            <Select disabled={enrollment.isDeliveredMaterial || enrollment.status === "cancelled"} placeholder="Indique una talla" value={actualSize} onChange={changeSize}> 
                                <Option value="SU">Sin uniformidad</Option>
                                <Option value="6">6</Option>
                                <Option value="8">8</Option>
                                <Option value="10">10</Option>
                                <Option value="12">12</Option>
                                <Option value="XS">XS</Option>
                                <Option value="S">S</Option>
                                <Option value="M">M</Option>
                                <Option value="L">L</Option>
                                <Option value="XL">XL</Option>
                                <Option value="XXL">XXL</Option>
                                <Option value="XXXL">XXXL</Option>
                            </Select>
                        </Title>
                    </div>
                    
                    <Divider />
                    <Steps current={currentStep} status={currentStepStatus}>
                        <Step title="F. Creación" description={`${moment(enrollment.enrollDate).format("DD/MM/YYYY HH:mm:ss")}`} />
                        {
                            enrollment.status === 'cancelled' 
                            ?
                            <Step 
                                title="F. Cancelación" 
                                description={`${enrollment.meta.find(m => m.key === "cancel_date")?.value}`} 
                                />
                            :
                            <Step 
                                title="Activa" 
                                description={``} 
                                />
                        }
                        {getEndStep()}
                    </Steps>
            </Col>
            {enrollment.status !== "free" ? 
            <>
            <Col xs={24} className="detail-info-box">
                <Title level={4} className="title">
                    Histórico de pagos
                    {
                        enrollment.meta.some(m => m.key === "stripe_coupon_amount") ? <small className="discount-apply">La inscripción tiene aplicado un descuento del <strong>{enrollment.meta.find(m => m.key === "stripe_coupon_amount")?.value}%</strong><StarFilled className="free-enrollment"/></small> : ''
                    }
                </Title>
                <div className="payment-list-container">
                    {
                        enrollment.paymentData && enrollment.paymentData.length > 0 ?
                        <Table 
                            columns={paymentsColumns} 
                            dataSource={enrollment.paymentData} 
                            scroll={{ x: 360 }} 
                            footer={() => <small><b>ATENCIÓN: </b>Algunos pagos pueden tardar unos minutos en aparecer.</small>}
                        />
                        :
                        <Empty description="No ha realizado pagos aún"/>
                    }
                    
                </div>
            </Col>
            <Col xs={24} className="detail-info-box">
                <Title level={4} className="title">
                    Histórico de devoluciones
                </Title>
                <div className="payment-list-container">
                    {
                        refundedData && refundedData.length > 0 ?
                        <Table 
                            columns={paymentsColumns} 
                            dataSource={refundedData} 
                            scroll={{ x: 360 }}
                            footer={() => <small><b>ATENCIÓN: </b>Algunas devoluciones pueden tardar unos minutos en aparecer.</small>}
                         />
                        :
                        <Empty description="No tiene devoluciones aún."/>
                    }
                    
                </div>
            </Col>
            </> 
            : ''}
        </Row>
    )
}

export default EnrollmentDetailComponent;