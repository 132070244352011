import EndPointConfig from '../../models/api/EndPointConfig'

const controller = 'Alerts'

export const AlertsEP : EndPointConfig[] =[
    new EndPointConfig()
        .setMethod('GET')
        .setController(controller)        
        .setActionName("GetUserAlerts"),
    new EndPointConfig()
        .setMethod('PATCH')
        .setController(controller)
        .setPattern('alert/{alertId}/session/{sessionId}')
        .setActionName('MarkReaded'),
    new EndPointConfig()
        .setMethod('GET')
        .setController(controller)
        .setActionName('GetTotalUnreadAlerts'),
        

        
]


