import EndPointConfig from '../../models/api/EndPointConfig'

const controller = 'Products'

export const ProductsEP : EndPointConfig[] =[
        new EndPointConfig()
        .setMethod('GET')
        .setController(controller)        
        .setActionName("GetCheckoutProducts"),
]


