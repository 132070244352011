import React, { useState, useContext } from 'react'
import { Form, Input, Button} from 'antd'
import { useHistory } from "react-router-dom";

import { LoginContext } from '../../hooks/context/userContext'

import RestApiClient from '../../api/restApiClient'

import './login.scss';
import ParamsApi from '../../models/api/ParamsApi';
import ApiResponse from '../../models/api/ApiResponse';
import User from '../../models/Users';

interface FieldsForm{
    username: string,
    password: string
}

const  LoginFormComponentInner = (props : any) => {
    const [form] = Form.useForm();

    const [errorLoginMessage, setErrorLoginMessage] = useState<string>("");
    let loginContext = useContext(LoginContext);

    const restApiClient : RestApiClient = new RestApiClient();

    const history = useHistory();

    const onFinish = (values : FieldsForm) : void => {
        const paramsApi : ParamsApi = {
            body: {
                username: values.username,
                password: values.password
            }
        }

        restApiClient.fetch("DoLogin", paramsApi).then((r : ApiResponse | null)=> {

            if (r !== null && r.code === 500){
                setErrorLoginMessage(r.message);
            }else if (r !== null && r.code === 200){
                const data : User = r.data as User;

                loginContext = data;
                
                sessionStorage.setItem("user-data", JSON.stringify(loginContext));

                history.push("/dashboard");
            }
        })
    }

    const resetErrorLoginMessage = () : void => {
        setErrorLoginMessage('');
    }

    const goResetPasswordStep1 = () : void => {

        form.validateFields(["username"]).then(r => {
            const paramsApi : ParamsApi = {
                body: {
                    username: form.getFieldValue("username")
                }
            }
    
            restApiClient.fetch("ChangeMyPasswordStep1", paramsApi).then((r : ApiResponse | null)=> {
                if (r !== null){
                    setErrorLoginMessage(r.message);
                }
            })
        });
    }

    const goResendEmailAccountValidation = () : void => {

        form.validateFields(["username"]).then(r => {
            const paramsApi : ParamsApi = {
                body: {
                    username: form.getFieldValue("username"),
                    password: "not-provided"
                }
            }
    
            restApiClient.fetch("ResendValidateAccountEmail", paramsApi).then((r : ApiResponse | null)=> {
                if (r !== null){
                    setErrorLoginMessage(r.message);
                }
            })
        });
    }

    return(
           <Form form={form} className="frm-login" onFinish={onFinish} layout="vertical">
                <Form.Item
                    label="Usuario"
                    name="username"
                    className="form-item"
                    rules={[{required: true, message: 'Por favor, introduce tu nombre de usuario...'}]}
                >
                    <Input type="email" placeholder="Introduce tu email" onChange={resetErrorLoginMessage} />
                </Form.Item>
                <Form.Item
                    style={{marginBottom: "0 !important"}}
                    label="Contraseña"
                    name="password"
                    className="form-item no-bottom-space"
                    rules={[{required: true, message: 'Por favor, introduce tu contraseña...'}]}
                >
                    <Input type="password" placeholder="Introduce tu password" onChange={resetErrorLoginMessage} />
                </Form.Item>
                <p className="other-login-actions">
                    <a onClick={goResendEmailAccountValidation}>Reenviar correo activación</a> 
                    &nbsp;  &nbsp; 
                    <a onClick={goResetPasswordStep1}>¿Olvidaste contraseña?</a>
                </p>
                <p className="error-login"><a >{errorLoginMessage}</a></p>

                <Form.Item>
                    <Button type="primary" htmlType="submit" className="btn-login">
                        Acceder
                    </Button>
                </Form.Item>
            </Form>
    )
}

export default LoginFormComponentInner;