import React, { useContext, useState } from 'react'

import { Drawer } from 'antd'

import './drawerInside.scss';
import { AppCfgContext } from '../../hooks/context/appConfigContext'

interface DrawerInsideProperties{
    showadd: boolean,
    donclose: any,
    children: React.ReactNode
    dtitle: any,
    dwidth?: string | number
    mobilefixed?: boolean,
    style?: any
    innercontainer?: 'no-inner' | undefined
    closable?: boolean
}

const DrawerInsideComponent = (props : DrawerInsideProperties) : JSX.Element | any => {

    const appConfigContext = useContext(AppCfgContext);
    
    return (
        <Drawer placement="left"
            title={props.dtitle}
            style={{...props.style, position:"absolute", maxHeight: "100vh"}}
            className={props.mobilefixed?'mobile-fixed':''}
            footer={<><span>¿Acaso hay algo más sencillo? Si tienes alguna duda escribe a <a href={`mailto:${appConfigContext.cfg.emailTechnicalSupport}`}>{appConfigContext.cfg.emailTechnicalSupport}</a></span></>}
            closable={props.closable === undefined || props.closable === null ? true : props.closable}
            onClose={props.donclose}
            visible={props.showadd}
            width={ props.dwidth || "100%"}
            {...props}>
            {props.children}
        </Drawer>
    )
}

export default DrawerInsideComponent;