import { Tabs } from 'antd'
import React, {useContext, useEffect, useState }  from 'react'
import { AppCfgContext } from '../../../hooks/context/appConfigContext'

import DrawerInsideComponent from '../../DrawerInside/DrawerInside'

import * as Components from '../../index'

interface CreditCardDrawerProps{
    visible: boolean,
    setShowCreditCardDrawer: any
}

const CreditCardDrawerComponent = (props : CreditCardDrawerProps): JSX.Element => {
    const appConfigContext = useContext(AppCfgContext);

    const [showCreditCardInfo, setShowCreditcardInfo] = useState<boolean>(false);
    const [refreshData, setRefreshData] = useState<boolean>(false);

    const [usingPaymentMethod, setUsingPaymentMethod] = useState<''|'credit-card'|'bank-account'>('')

    const [imDefault, setImDefault] = useState<''|'credit-card'|'bank-account'>('')

    useEffect(()=>{
        setShowCreditcardInfo(props.visible);
    }, [props.visible])


    return(
    <DrawerInsideComponent 
        mobilefixed={showCreditCardInfo} 
        showadd={showCreditCardInfo} 
        donclose={()=>{ props.setShowCreditCardDrawer(false); }} 
        dtitle="" 
        dwidth={"60%"}
    >

        <Tabs defaultActiveKey={usingPaymentMethod == '' || usingPaymentMethod == 'credit-card' ? "1" : "2"}>
                {
                    ["both", "credit-card"].includes(appConfigContext.cfg.cfgPaymentTypes) ? 
                        <Tabs.TabPane tab="Registrar Tarjeta Bancaria" key="1">
                            <Components.StripeCreditCardInfo refreshData={refreshData} refreshed={setRefreshData} />
                            <Components.StripeElementWrapper>
                                <Components.StripeRegisterCreditCard requireRefresh={setRefreshData} /> 
                            </Components.StripeElementWrapper> 
                        </Tabs.TabPane>
                    : ''
                }
                {
                    ["both", "bank-account"].includes(appConfigContext.cfg.cfgPaymentTypes) ? 
                        <Tabs.TabPane tab="Registrar IBAN" key="2">
                            <Components.StripeBankAccountInfo refreshData={refreshData} refreshed={setRefreshData} />
                            <Components.StripeElementWrapper>
                                <Components.StripeRegisterBankAccount requireRefresh={setRefreshData} /> 
                            </Components.StripeElementWrapper>
                        </Tabs.TabPane>
                    : ''
                }
        </Tabs>

        

        
        
    </DrawerInsideComponent>)
}

export default CreditCardDrawerComponent;