import EndPointConfig from '../../models/api/EndPointConfig'

const controller = 'Test'

export const TestEP : EndPointConfig[] =[
    new EndPointConfig()
        .setMethod('POST')
        .setController(controller) 
        .setActionName("DoSecurityTest"),
    new EndPointConfig()
        .setMethod('GET')
        .setController(controller)        
        .setActionName("DoTest")
]