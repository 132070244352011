import React, { useContext, useState } from 'react'

import { Form, Input, Row, Col, DatePicker, Button, Select, Typography } from 'antd'

import moment from "moment";
import Athlete from '../../models/Athletes';
import RestApiClient from '../../api/restApiClient';
import ParamsApi from '../../models/api/ParamsApi';
import ApiResponse from '../../models/api/ApiResponse';

import 'moment/locale/es';
import locale from 'antd/es/date-picker/locale/es_ES';

import { LoginContext } from '../../hooks/context/userContext'
import { AppCfgContext } from '../../hooks/context/appConfigContext'
import { validateDNINIE } from '../../utils/ValidationsUtils';
import { LoadingOutlined } from '@ant-design/icons';

const { Option } = Select;

interface AddAthleteFormProps{
    onFinish: (athlete : Athlete) => void,
    onClose: ()=>void,
}

const AddAthleteFormComponent = (props : AddAthleteFormProps) : JSX.Element => {
    const appConfigContext = useContext(AppCfgContext);

    const loginContext = useContext(LoginContext);

    const restApiClient : RestApiClient = new RestApiClient();
    
    const [form] = Form.useForm();

    const [savingData, setSavingData] = useState<boolean>(false);
    const onFinish = () : void => {
        const athleteData = form.getFieldsValue() as Athlete;
                  athleteData.idUser = loginContext.id;
                  athleteData.birthDate = moment(athleteData.birthDate).format("YYYY-MM-DD");
                  athleteData.avatar = appConfigContext.cfg.urlsShieldImg;
        const paramsApi : ParamsApi = {
            body: athleteData
        }

        setSavingData(true);
        restApiClient.fetch("AddAthlete", paramsApi).then((r : ApiResponse | null)=> {
            if (r?.code === 200){
                props.onFinish(athleteData);
                // Limpiamos el formulario
                form.resetFields();
            }
            
        }).finally(()=>{
            setSavingData(false);
        });
    }


    return (
        <Form layout="vertical" size="small" onFinish={onFinish} form={form}>
            <Row gutter={20}>
                <Col xs={24}>
                    <Typography.Title level={5}>
                        Datos personales
                    </Typography.Title>
                </Col>
                <Col xs={24}>
                    <Form.Item
                        name="name"
                        className="form-item"
                        label="Nombre"
                        rules={[{required: true, message: 'Este campo es obligatorio'}]}
                    >
                        <Input placeholder="Nombre"/>
                    </Form.Item>
                    <Form.Item
                        name="surname"
                        className="form-item"
                        label="Primer apellido"
                        rules={[{required: true, message: 'Este campo es obligatorio'}]}
                    >
                        <Input placeholder="Primer apellido"/>
                    </Form.Item>
                    <Form.Item
                        name="secondSurname"
                        className="form-item"
                        label="Segundo apellido"
                        rules={[{required: true, message: 'Este campo es obligatorio'}]}
                    >
                        <Input placeholder="Segundo apellido"/>
                    </Form.Item>
                </Col>
                <Col xs={24}>
                    <Typography.Title level={5}>
                        Otros datos
                    </Typography.Title>
                </Col>
                <Col xs={24}>
                    <Form.Item name="birthDate"
                        className="form-item"
                        label="Fecha de nacimiento"
                        rules={[{required: true, message: 'Este campo es obligatorio'}]}
                    >
                        <DatePicker format="DD/MM/YYYY" locale={locale} placeholder="Fecha de nacimiento" style={{width: "100%"}} />
                    </Form.Item>
                    <Form.Item
                        name="dniNie"
                        className="form-item"
                        label="DNI/NIE"
                        rules={
                            [
                                ({ getFieldValue }) => ({
                                    validator(rule, value) {
                                       const birthDate = getFieldValue('birthDate');

                                       if (value === undefined && birthDate  !== undefined && moment().diff(birthDate, 'years') >= 14){
                                           // yo también soy obligatorio
                                           return Promise.reject("Obligatorio para mayores de 14 años");
                                        }else if (value !== undefined && !validateDNINIE(value)){
                                            return Promise.reject("Documento no válido");
                                        }
                                       
                                       return Promise.resolve();
                                      
                                    },
                                  })
                            ]
                        }
                    >
                        <Input placeholder="DNI o NIE"/>
                    </Form.Item>
                    <Form.Item
                        name="relationship"
                        className="form-item"
                        label="Parentesco"
                        rules={[{required: true, message: 'Este campo es obligatorio'}]}
                    >
                        <Select placeholder="Indique relación">
                            <Option value="myself">Soy yo mismo</Option>
                            <Option value="fmolg">Soy su padre/madre, tutor/tutora o responsable legal</Option>
                            <Option value="couple">Soy su pareja</Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={24} >
                    <Form.Item className="btn-add-athlete">
                        <Button disabled={savingData} type="primary" danger onClick={props.onClose}>
                            Cancelar
                        </Button>
                        <Button disabled={savingData} type="primary" htmlType="submit" className="btn-save-athlete">
                            {
                                savingData ? <LoadingOutlined style={{marginRight: "5px"}}/> : ''
                            }
                            Guardar
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    )
}

export default AddAthleteFormComponent;