import EndPointConfig from '../../models/api/EndPointConfig'

const controller = 'Athletes'

export const AthletesEP : EndPointConfig[] =[
    new EndPointConfig()
        .setMethod('POST')
        .setController(controller)        
        .setActionName("GetUserAthletes"),
    new EndPointConfig()
        .setMethod('PUT')
        .setController(controller)        
        .setActionName("AddAthlete"),
    new EndPointConfig()
        .setMethod('PUT')
        .setController(controller)        
        .setActionName("UpdateAthlete")
]


