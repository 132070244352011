import React, { useEffect, useState } from 'react'

import './counterComponent.scss'

interface CounterComponentProps {
    seconds: number,
    title: string,
    subtitle: string,
    order: number,
    series: number
}

// Alternativa: https://codepen.io/suez/pen/dXbBGp

//Código y estilos obtenidos de: https://codepen.io/ninjascribble/pen/rHwkK
const CounterComponent = (props: CounterComponentProps) : JSX.Element => {

    const [tickMode] = useState<'normal' | 'inverse'>('inverse');
    const [one_second] = useState<number>(1000)
    const [one_minute] = useState<number>(1000 * 60)
    const [one_hour] = useState<number>(1000 * 60 * 60)
    const [startDate, setStartDate] = useState<any>(new Date())
    const [face, setFace] = useState<any>();

    const [title, setTitle] = useState<string>('');
    const [subTitle, setSubTitle] = useState<string>('');
    const [seconds, setSeconds] = useState<number>(0);
    const [order, setOrder] = useState<number>(0);
    const [series, setSeries] = useState<number>(0);


    useEffect(()=>{
        setFace(document.getElementById('lazy'))
    },[]);


    useEffect(() => {
        const timerId = setInterval(() => {

            if (seconds >= 0){
                setSeconds(seconds - 1); 
                inverseTick()
            }else{
                clearInterval(timerId);
            }
        
        }, 1000);
        return () => clearInterval(timerId);
    });

    useEffect(()=>{
        switch(tickMode){
            case 'inverse':
                inverseTick();
                break;
            case 'normal':
            default:
                tick();
                break;
        }
    }, [face])


    useEffect(()=>{
        setTitle(props.title);
        setSubTitle(props.subtitle);
        setSeconds(props.seconds);
        setOrder(props.order);
        setSeries(props.series);
    }, [props])


    // http://paulirish.com/2011/requestanimationframe-for-smart-animating/
    const requestAnimationFrame = (function() {
        return window.requestAnimationFrame       || 
               window.webkitRequestAnimationFrame || 
               function( callback ){
                 window.setTimeout(callback, 1000 / 60);
               };
      }())

    const inverseTick = () : void => {
        const hourNumber = Math.floor(seconds / 3600);
        const hourString = (hourNumber < 10)? '0' + hourNumber : hourNumber;
        const minuteNumber = Math.floor((seconds / 60) % 60);
        const minuteString = (minuteNumber < 10)? '0' + minuteNumber : minuteNumber;
        const secNumber = seconds % 60;
        const secString = (secNumber < 10)? '0' + secNumber : secNumber;

        var parts = [];

        parts[0] = hourString;
        parts[1] = minuteString;
        parts[2] = secString;

        if (face !== undefined)
            face.innerText = parts.join(':');
        
        requestAnimationFrame(inverseTick);
    }

    const tick = ():void=>{
        var now : any = new Date()
        var elapsed : number = now - startDate 
        var parts = [];

        parts[0] = '' + Math.floor( elapsed / one_hour );
        parts[1] = '' + Math.floor( (elapsed % one_hour) / one_minute );
        parts[2] = '' + Math.floor( ( (elapsed % one_hour) % one_minute ) / one_second );

        parts[0] = (parts[0].length == 1) ? '0' + parts[0] : parts[0];
        parts[1] = (parts[1].length == 1) ? '0' + parts[1] : parts[1];
        parts[2] = (parts[2].length == 1) ? '0' + parts[2] : parts[2];

        if (face !== undefined)
        face.innerText = parts.join(':');
        
        requestAnimationFrame(tick);
    }

    return(
        <div className="counter">
            <div className="timer-group">
                <div  className="timer hour">
                    <div className="hand"><span></span></div>
                    <div className="hand"><span></span></div>
                </div>
                <div className="timer minute">
                    <div className="hand"><span></span></div>
                    <div className="hand"><span></span></div>
                </div>
                <div className="timer second">
                    <div className="hand"><span></span></div>
                    <div className="hand"><span></span></div>
                </div>
                <div className="face">
                    <h2>{title}</h2>
                    <h5>Ejercicio {order}: {subTitle}</h5>
                    <p id="lazy">00:00:00</p>  
                </div>
            </div>
        </div>
    )
}

export default CounterComponent;
