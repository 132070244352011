import React, {useContext, useState} from 'react'
import { Row, Col } from 'antd'
import * as Components from '../../components/index'
import { Step } from '../../models/Step';
import { AppCfgContext } from '../../hooks/context/appConfigContext'

interface ContainerBaseProps{
    children: React.ReactNode
}

const DemoSteps : Step[] = [
    {
        id: "btn-add-athletes",
        header: "Estoy en el botón de atletas",
        body:(<div>Esto es un body!</div>),
        footer: "Footer ayuda athletes",
        position: 'right'
    },
    {
        id: "btn-add-enrollments",
        header: "Estoy en el botón de enrollments",
        body:(<div>Esto es un body!</div>),
        footer: "Footer ayuda enrollments",
        position: 'left'
    },
    {
        id: "btn-alerts",
        header: "Estoy en el botón de alertas",
        body:(<div>Esto es un body!</div>),
        footer: "Footer ayuda alertas",
        position: 'left'
    },
    {
        id: "space",
        header: "Etoy en algo",
        body:(<div>Esto es un body!</div>),
        footer: "Footer ayuda algo",
        position: 'topRight'
    }
  ];
  

const ContainerBase = (props : ContainerBaseProps) : JSX.Element => {
    const appConfigContext = useContext(AppCfgContext);

  const [updateStepByStep, setUpdateStepByStep] = useState<number>(1);
  const [showStepByStep, setShowStepByStep] = useState<boolean>(false);

    return(
        <Row className="theme" style={{backgroundImage:`url(${appConfigContext.cfg.urlsMainImg})`}} justify="center" align="middle" onScrollCapture={()=>{   setUpdateStepByStep(updateStepByStep+1); }}>
            <Components.StepByStep width="100px" height="100px" containerId="container" visible={showStepByStep} steps={DemoSteps} update={updateStepByStep} />
            <Col id="container" className="container" xs={24} sm={24} md={24} lg={20}>
                {props.children}
            </Col>
        </Row>
    )
}

export default ContainerBase;