import React, { useContext, useEffect, useState } from 'react'
import { Row, Col, Typography, Button  } from 'antd'

import * as Components from '../../components/index'


import './dashboard.scss'
import { LoginContext } from '../../hooks/context/userContext'
import { AppCfgContext } from '../../hooks/context/appConfigContext'
import Enrollment, { EmptyEnrollment } from '../../models/Enrollments'
import moment from 'moment'
import getUserIP, { IPData } from '../../utils/IPUtils'

const { Title } = Typography


const DashboardPage = (props : any) : any => {

    const appConfigContext = useContext(AppCfgContext);
    const loginContext = useContext(LoginContext);

    const [showCheckout, setShowCheckout] = useState<boolean>(false);
    const [enrollForPay, setEnrollForPay] = useState<Enrollment>(EmptyEnrollment);
    const [refreshEnrollments, setRefreshEnrollments] = useState<boolean>(false);

    const [yourIp, setYourIp] = useState<string>("")

    const getYourIP = (): void => {
        getUserIP().then((r : any)=> {
            //if (r && r.IPv4){
                if (r && r.ip){
                setYourIp(r.ip);
            }
        })
    }


    useEffect(()=>{
        getYourIP();
    },[])

    useEffect(()=>{
        getCheckoutVersion();
    },[enrollForPay]);
    


    const getShowCheckout = (showDrawer: boolean, enrollmentData: Enrollment) : void =>{
        setEnrollForPay(enrollmentData);
        setShowCheckout(showDrawer);
    }

    const getCloseCheckout = () : void => {
        setShowCheckout(false);
        setRefreshEnrollments(true);
    }

    const [checkout, setCheckout] = useState<JSX.Element>(<></>);
    const getCheckoutVersion = () : void => {
        let result : JSX.Element = <>El carrito de compra está deshabilitado. Habla con tu centro.</>
        switch(appConfigContext.cfg.cfgCheckoutVersion){
            case "v1":
                result = <Components.Checkout enrollmentData={enrollForPay} close={getCloseCheckout}/>;
                break;
            case "v3":
            default:
                result = <Components.CheckoutV3 enrollmentData={enrollForPay} close={getCloseCheckout}/>;
                break;
        }

        setCheckout(result);
    }

    return(
        <Components.Container>
                <Components.Helper location="dashboard" />

                <Components.DrawerInside showadd={showCheckout || false} donclose={getCloseCheckout} dtitle="Resumen del pago" mobilefixed={showCheckout}>
                    <Components.StripeElementWrapper>
                        {
                            checkout
                        }
                        
                        {
                            /**
                             * Antiguo Checkout
                             * <Components.Checkout enrollmentData={enrollForPay} close={getCloseCheckout}/>
                            */
                        }
                    </Components.StripeElementWrapper>
                </Components.DrawerInside>

                <Row gutter={10}>
                    <Col xs={24} className="welcome-title hello" style={{backgroundImage: `radial-gradient(circle at -13.87% 32.88%, ${appConfigContext.cfg.colorsMain} 0, ${appConfigContext.cfg.colorsMain} 25%, ${appConfigContext.cfg.colorsMain} 50%, ${appConfigContext.cfg.colorsSecondary} 75%, ${appConfigContext.cfg.colorsSecondary} 100%)`}}>
                        <img className="client-logo" src={`${appConfigContext.cfg.urlsLogoImg}`} />
                        <small className="last-connection">
                            Sesión iniciada como {loginContext.name} {loginContext.surname} {loginContext.secondSurname} <br/>
                            Última conexión: {moment().format("DD/MM/YYYY")} <br/>
                            IP: {yourIp}
                        </small>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={22} >
                        <Components.DiscountsAlert />
                    </Col>
                    <Col xs={24}>
                        <Row gutter={30}>
                            <Col xs={24} sm={24} md={24} lg={24} xl={22} >
                                <Row gutter={30}>
                                        <Col xs={24} sm={24}  xl={12} >
                                            <Components.Athletes />
                                        </Col>
                                        <Col xs={24} sm={24} xl={12} >
                                            <Components.Enrollments getShowCheckout={getShowCheckout} refreshEnrollments={refreshEnrollments} setRefreshEnrollments={setRefreshEnrollments}/> 
                                        </Col>
                                </Row>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={22} xl={11} >
                                <Components.Groups />
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={22} xl={11} >
                                <Components.Documents />
                            </Col>

                            <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />&nbsp;
                        </Row>
                    </Col>
                </Row>
        </Components.Container>
    )
}

export default DashboardPage;