import React, { useState, useEffect }from 'react'
import Exercice from '../../models/Exercice'
import Youtube from 'react-youtube'
import parse from 'html-react-parser';
import { Button } from 'antd';

import {
    SyncOutlined,
    CaretRightFilled,
    PauseOutlined,
    RetweetOutlined,
    LoadingOutlined 
  } from '@ant-design/icons';


import './exerciceBoxComponent.scss'

interface ExerciceBoxComponentProps{
    exercice?: Exercice,
    active: boolean
}

const ExerciceBoxComponent = (props: ExerciceBoxComponentProps) : JSX.Element => {
    const [exercice, setExercice] = useState<Exercice>();
    const [isActive, setIsActive] = useState<boolean>(false);
    const [myVideo, setMyVideo] = useState<any>();
    const [videoDuration, setVideoDuration] = useState<any>();
    const [timer, setTimer] = useState<any>();
 
    useEffect(()=>{
        setExercice(props.exercice);
    }, [props.exercice]);

    useEffect(()=>{
        setIsActive(props.active);
    }, [props.active]);

    const timeStringToSignal = (durationString : string) : string => {
        let result : string = '';

            switch(durationString){
                case "seconds":
                    result = '"';
                    break;
                case "minutes":
                    result = "'";
                    break;
                case "hours":
                    result = "h";
                    break;
            }

        return result;
    }

    const onPlayerReady = (event: any) => {
        setMyVideo(event.target);
    }

    const initVideo = () => {
        myVideo.seekTo(0);
        playVideo();
    }

    const playVideo = () => {
        myVideo.setVolume(100);

        if (myVideo.isVideoInfoVisible()){
            myVideo.hideVideoInfo();
        }

        myVideo.playVideo();
        
        clearInterval(timer);
        setTimer(setInterval(setDuration , 1000));
    }

    const setDuration = () => {
        const duration = myVideo.getDuration();
        const leftDuration = (duration - myVideo.getCurrentTime());

        var minutes = Math.floor(leftDuration / 60);
        var seconds = Math.floor(leftDuration - minutes * 60);

        setVideoDuration(`${minutes}' ${seconds}"`);
    }

    
    const pauseVideo = () => {
        clearInterval(timer);
        setTimer(undefined);
        myVideo.pauseVideo();
    }
    

    return(
            <>
                {
                    exercice ?
                    <div className="exercice-box">
                        
                        <div className="title">
                            <h3>
                                {exercice.name}
                            </h3>
                            <div className="extra">
                                <small className="duration">{exercice.durationTimeSeconds}{timeStringToSignal('seconds')}</small>
                                <small className="series"><SyncOutlined spin />x{exercice.series}</small>
                            </div>
                        </div>
                        <div className="description">
                            {parse(exercice.description)}
                        </div>
                        <div className="video">
                        {exercice.urlVideo && exercice.urlVideo.length > 0
                                ?
                                <div style={{height:"auto"}}>
                                        <Youtube 
                                            opts={{
                                                width: '100%',
                                                playerVars:{
                                                    autoplay: 0,
                                                    controls: 0
                                                },
                                            }}
                                            videoId={exercice.urlVideo}
                                            onReady={ onPlayerReady }
                                            onPlay={playVideo}
                                            onPause={pauseVideo}
                                        />
                                        <Button onClick={()=>{initVideo()}}>
                                            <RetweetOutlined />
                                        </Button>
                                        <Button onClick={()=>{playVideo()}}>
                                            {
                                                timer ?
                                                    <LoadingOutlined />
                                                :
                                                    <CaretRightFilled />
                                            }
                                         {videoDuration}
                                        </Button>
                                        <Button  onClick={()=>{pauseVideo()}}>
                                            <PauseOutlined />
                                        </Button>
                                </div>
                                :
                                ''
                            }

                            {exercice.urlImage && exercice.urlImage.length > 0
                                ?
                                    <img className="exercice-image" src={exercice.urlImage} alt={exercice.urlImage} title={exercice.urlImage}/>
                                :
                                ''
                            }
                        </div>
                    </div>
                :
                    ''
                }
            </>
    )
}

export default ExerciceBoxComponent;