import React, { useContext, useEffect, useState } from 'react'

import { Row, Col, Skeleton, Card, Typography, Button, Empty , Tooltip, Select } from 'antd'

import { SettingOutlined, ReconciliationOutlined, EuroCircleOutlined, 
    FrownOutlined,
    SmileOutlined,
    QuestionCircleOutlined,
    MehOutlined, 
    RocketOutlined,
    ReloadOutlined,
    AlertOutlined,
    TrophyOutlined,
    TagsOutlined,
    StarOutlined,
    StarFilled,
    BarsOutlined,
    DeleteOutlined} from '@ant-design/icons'

import Enrollment, * as EnrollmentModels from '../../models/Enrollments'; 

import DrawerInsideComponent from '../DrawerInside/DrawerInside';

import EnrollmentsAddComponent from './enrollments.add';
import EnrollmentsDetailComponent from './enrollments.detail';
import EnrollmentsFinantialDetails from './enrollments.finantial.details';

import ApiResponse from '../../models/api/ApiResponse';

import moment from 'moment';
import Season from '../../models/Season';

import './enrollments.scss'
import RestApiClient from '../../api/restApiClient';

import { LoginContext } from '../../hooks/context/userContext'
import ParamsApi from '../../models/api/ParamsApi';
import StripeElementWrapper from '../payments/stripe/StripeElementWrapper';

const { Meta } = Card
const { Option } = Select

interface EnrollmentsProps {
    getShowCheckout: any,
    refreshEnrollments: any,
    setRefreshEnrollments: any,
}

const { Title, Paragraph } = Typography


const EnrollmentsComponent = (props : EnrollmentsProps) : JSX.Element => {

    const loginContext = useContext(LoginContext);
    const restApiClient : RestApiClient = new RestApiClient();

    const [loading, setLoading] = useState<boolean>(true);
    const [loadingEnrollments, setLoadingEnrollments] = useState<boolean>(false);

    const [seasons, setSeasons] = useState<Season[]>([])
    const [actualSession, setActualSession] = useState<number>(0);
    const [enrollments, setEnrollments] = useState<EnrollmentModels.default[]>([]);
    
    const [showAdd, setShowAdd] = useState<boolean>(false);
    const [drawerWidth, setDrawerWidth] = useState<string | number>("100%");

    const [showFinancialDetails, setShowFinantialDetails] = useState<boolean>(false);
    const [showEnrollmentDetail, setShowEnrollmentDetail] = useState<boolean>(false);
    const [enrollment, setEnrollment] = useState<Enrollment>(EnrollmentModels.EmptyEnrollment);

    useEffect(()=>{
        if (props.refreshEnrollments){
            props.setRefreshEnrollments(false);
            let activeSeason = seasons.find(season => season.isActiveSeason);

            if (activeSeason !== undefined){
                getSeasonEnrollment(activeSeason.id);
            }
        }
    },[props.refreshEnrollments]);


    

    // Get user enrollments by season id.
    const getSeasonEnrollment = (elem : number) : void => {

        setActualSession(elem);

        setLoadingEnrollments(true);
        const params : ParamsApi = {
            body: {
                    sessionId: loginContext.sessionId,
                    userId: loginContext.id,
                    seasonId: elem
            }
        }

        restApiClient.fetch("GetUserEnrollments",params)
            .then(r => {
                let data : Enrollment[] = r?.data;
                setEnrollments(data);
            })
            .finally(()=>{
                setLoadingEnrollments(false);
            })
    }

    const hideEnrollmentDetailDrawer = () : void => {
        setShowEnrollmentDetail(false);
    }

    const hideFinaltialDetailsDrawer = () : void => {
        setShowFinantialDetails(false);
    }

    const hideAddDrawer = () : void => {
        setShowAdd(false);
    }

    const showAddDrawer = () : void => {
        setShowAdd(true);
    }

    const getEmpty = () : JSX.Element => <Empty
                                            image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                                            imageStyle={{
                                            height: 60,
                                            }}
                                            description={
                                            <span>
                                                Aún no has registrado ninguna inscripción ¿¡A qué esperas!?
                                            </span>
                                            }
                                        >
                                        </Empty>

    const onAddNewEnrollment = () : void => {
        setShowAdd(false);
        getSeasonEnrollment(actualSession);
    }

    const getPayModeIcon = (enrollmentData: Enrollment) : JSX.Element => {

        let result : JSX.Element = <Tooltip placement="top" title="Ups... No se ha podido determinar el tipo de inscripción.">
                                        <QuestionCircleOutlined className="enrollment-box-icon"  />
                                    </Tooltip>

        if (enrollmentData.status === "free"){
            result = <Tooltip placement="top" title="Inscripción gratuita">
                <TagsOutlined className="enrollment-box-icon" />
            </Tooltip>
        }else{
            if (enrollmentData.meta?.find(em => em.key == "payment_plan")?.value === "Subscription"){ 
                result = <Tooltip placement="top" title="Inscripción a tu manera">
                            <RocketOutlined className="enrollment-box-icon"  />
                        </Tooltip>
            }else if (enrollmentData.meta?.find(em => em.key == "payment_plan")?.value === "OnePay"){
                result = <Tooltip placement="top" title="Inscripción pago único">
                            <TrophyOutlined className="enrollment-box-icon"  />
                        </Tooltip>
            }else if (enrollmentData.meta?.find(em => em.key == "payment_plan")?.value === "PayPerUse"){
                result = <Tooltip placement="top" title="Pago mensual">
                            <EuroCircleOutlined className="enrollment-box-icon"  />
                        </Tooltip>
            }
        }

        

        return result;
    }

    const removeEnrollment = (id: number) : void => {
        setLoading(true);
        const params : ParamsApi = {
            query: {
                enrollmentId: id
            }
        }

        restApiClient.fetch("RemovePreEnrollment",params)
            .then(r => {
                getSeasonEnrollment(actualSession);
            }).finally(()=>{
                setLoading(false);
            })
    }

    const [havePedingPayments, setHavePedingPayments] = useState<boolean>(false);
    const getPendingPayments = () : void => {

        const params : ParamsApi = {}

        restApiClient.fetch("GetPendingPayments",params)
            .then(r => {
                let data : boolean = r?.data;
                setHavePedingPayments(data);
            })
    }

    const getActions = (enrollment: Enrollment) : any => {

        let result : any[] = [];

        // Opciones posibles:
        /**
         *      Si está en PRE: Solo puede pagar.
         *      Si está activa, cancelada o finalizada: puede ver el estado, 
         *                                              entrar en el detalle de la inscripción
         */

        switch(enrollment.status){
            case 'free':
                result.push(
                    <Tooltip placement="top" title="Su inscripción está activa">
                        <SmileOutlined className="enrollment-box-icon active" />
                    </Tooltip>
                )

                result.push(getPayModeIcon(enrollment))
                break;
            case 'revision':
            case 'active':
                if (enrollment.paymentData?.find(pd => pd.status == "error" || pd.status == "voided")){
                    result.push(<>Ups!</>);
                    result.push(
                        <Tooltip placement="top" title="Su inscripción tiene errores de cargo.">
                            <AlertOutlined className="enrollment-box-icon unpaid" />
                        </Tooltip>
                    );
                }else{
                    result.push(
                        <Tooltip placement="top" title="Su inscripción está activa">
                            <SmileOutlined className="enrollment-box-icon active" />
                        </Tooltip>
                    )
    
                    result.push(getPayModeIcon(enrollment))
                }
                

                break;
            case 'cancelled':
                result.push(
                    
                    <Tooltip placement="top" title="Su inscripción ha sido cancelada">
                        <FrownOutlined className="enrollment-box-icon cancelled"  />
                    </Tooltip>
                )
                
                result.push(getPayModeIcon(enrollment))
                
                break;
            case 'finished':
                result.push(
                    <Tooltip placement="top" title="Su inscripción finalizó correctamente">
                        <SmileOutlined className="enrollment-box-icon finished"  />
                    </Tooltip>
                )

                result.push(getPayModeIcon(enrollment))

                break;
            case 'pre':
                result.push(
                    <Tooltip placement="top" title="Su inscripción está pendiente de pago">
                        <MehOutlined className="enrollment-box-icon pre"  />
                    </Tooltip>
                )

                result.push(
                    <Tooltip placement="top" title="Eliminar inscripción">
                        <DeleteOutlined className="enrollment-box-icon pre remove"  onClick={()=>{removeEnrollment(enrollment.id)}}/>
                    </Tooltip>
                )
                break;
            default:
        }

        if (enrollment.status !== 'pre'){
            result.push(
                <Tooltip placement="top" title="Consulte la información de su inscripción">
                    <SettingOutlined key="setting" className="enrollment-box-icon" onClick={()=>{setEnrollment(enrollment); setShowEnrollmentDetail(true);}}/>
                </Tooltip>
            );

            if (enrollment.status !== 'cancelled' && enrollment.productInfo && enrollment.productInfo.type == "Subscription"){
                result.push(
                    <Tooltip placement="top" title="Consulte el plan de pagos">
                        <BarsOutlined key="setting" className="enrollment-box-icon" onClick={()=>{setEnrollment(enrollment); setShowFinantialDetails(true)}}/>
                    </Tooltip>
                )
            }
        }

        switch(enrollment.status){
            case 'active':
            case 'cancelled':
            case 'finished':
                break;
            case 'pre':
                result.push(                    
                    <Tooltip placement="top" title="Formalice su inscripción mediante el pago">
                        <EuroCircleOutlined className="enrollment-box-icon" key="payment" onClick={()=>{props.getShowCheckout(true, enrollment)}}/>
                    </Tooltip>
                )
                break;
            default:
        }

        return result;
    }

    // Initial charge: Get seasons and get enrollments of active season
    useEffect(()=>{

        setLoading(true);
        restApiClient.fetch("GetSeasons", {}).then((r : ApiResponse | null)=> {
            let data : Season[] = r?.data;
            setSeasons(data);

            let activeSeason = data.find(season => season.isActiveSeason);

            if (activeSeason === undefined && data.length > 0){
                activeSeason = data[0];
            }

            if (activeSeason !== undefined){
                getSeasonEnrollment(activeSeason.id);
            }

        }).finally(()=>{
            setLoading(false);
        });

        getPendingPayments();
    }, []);

    return(
        <>  
            <Row gutter={16} className="enrollments">
                {
                    // Drawer for Add new Enrollment
                }
                <DrawerInsideComponent mobilefixed={showAdd} showadd={showAdd} donclose={hideAddDrawer} dtitle="Registro de inscripciones" dwidth={"50%"}>
                    <EnrollmentsAddComponent onFinish={onAddNewEnrollment} onClose={hideAddDrawer} />
                </DrawerInsideComponent>

                {
                    // Drawer for show Enrollment Detail
                }
                <DrawerInsideComponent 
                    mobilefixed={showEnrollmentDetail} 
                    showadd={showEnrollmentDetail} 
                    donclose={hideEnrollmentDetailDrawer} 
                    dtitle="Detalle inscripción" 
                    dwidth={drawerWidth}
                >
                    <StripeElementWrapper>
                        <EnrollmentsDetailComponent data={enrollment} />
                    </StripeElementWrapper>
                    
                </DrawerInsideComponent>

                {
                    // Drawer for show Fiancial Data
                }
                <DrawerInsideComponent 
                    mobilefixed={showFinancialDetails} 
                    showadd={showFinancialDetails} 
                    donclose={hideFinaltialDetailsDrawer} 
                    dtitle="Plan de pagos inscripción" 
                    dwidth={drawerWidth}
                >
                   <EnrollmentsFinantialDetails enrollment={enrollment}/>
                </DrawerInsideComponent>
                <Col xs={24}>
                    <Title className="c-title" level={3}>
                        <ReconciliationOutlined /> &nbsp;
                        Inscripciones
                        <small className="c-sub-title">
                            Tus pases de temporada
                        </small>
                    </Title>
                    <Select placeholder="Seleccione curso" className="season-selector" onChange={getSeasonEnrollment}>
                        {
                            seasons.reverse().map(season => {
                                return <Option key={season.id} value={season.id}>{`${season.description} ${season.isActiveSeason ? '(Activa)' : '' }`}</Option>
                            })
                        }
                    </Select>
                    <Tooltip placement='bottomLeft' title={havePedingPayments ? 'Tiene pagos pendientes de temporadas anteriores. Es necesario que se ponga en contacto con su club si desea crear nuevas inscripciones.' : ''}>
                        <Button id="btn-add-enrollments" className="btn-add" type="primary" onClick={showAddDrawer} disabled={havePedingPayments}>
                            Crear
                        </Button>
                    </Tooltip>
                    <Button className="btn-reload" type="primary" onClick={()=>{getSeasonEnrollment(actualSession)}} >
                        {
                            loading ?
                                <ReloadOutlined spin />
                            :
                                <ReloadOutlined />
                        }
                    </Button>

                </Col>
                

                <Skeleton active loading={loading || loadingEnrollments}>
                    {enrollments.length === 0 ? <Col xs={24} className="empty-enrollments">{getEmpty()}</Col> : '' }
                    {enrollments.map( (e: EnrollmentModels.default) => {
                        return (
                                    <Col xs={24} md={12} lg={12} key={e.id}>
                                        <Card
                                            style={{ width: "100%", marginTop: 16 }}
                                            actions={getActions(e)}
                                            >
                                                <Skeleton loading={loading} avatar active>
                                                    {
                                                        e.status === "free" || e.meta.some(m => m.key === "stripe_coupon_amount")?<Tooltip title="Inscripción gratuita o con descuento aplicado"><StarFilled className="free-enrollment"/></Tooltip>:''
                                                    }
                                                    <Meta
                                                    title={ <>{`#INS-${e.id}`} &nbsp; <Tooltip placement="bottomRight" title="¡Este es su nº de inscripción!"><QuestionCircleOutlined /></Tooltip></>}
                                                    description={
                                                        <Paragraph className="athlete-name" ellipsis={{ rows: 2 }}>{`${e.athlete.surname} ${e.athlete.secondSurname}, ${e.athlete.name}`}</Paragraph>} />
                                                    <>
                                                            <small className="block"><span><strong>Localización: </strong>{e.location}</span></small>
                                                            <small className="block"><span><strong>Categoría: </strong>{e.category}</span></small>
                                                            <small className="block"><strong>F. Inscripción</strong>: {moment(e.enrollDate).format("DD/MM/YYYY")}</small> 
                                                        {
                                                            e.status === 'cancelled' 
                                                            ?
                                                            <small className="block"><strong>F. Cancelación</strong>: {e.meta.find(m => m.key === "cancel_date")?.value}</small>
                                                            :
                                                            ''
                                                        }
                                                    </>
                                                </Skeleton>
                                            </Card>
                                    </Col>
                                )
                    })}
                </Skeleton>
            </Row>
        </>
    )
}

export default EnrollmentsComponent;