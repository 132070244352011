import { Discount } from "../models/Discount";

export const validatePassword = (password : string) : boolean => {
    const lowerCaseCharsReg = /[a-z]/
    const upperCaseCharsReg = /[A-Z]/
    const numbersCharsReg = /[0-9]/

    return password !== undefined 
            && password !== null 
            && lowerCaseCharsReg.test(password) 
            && upperCaseCharsReg.test(password) 
            && numbersCharsReg.test(password) 
            && password.length > 6;
}

export const validateDNINIE = (dniornie: string) : boolean => {
    let numero : any;
    let letr : string;
    let letra : string;
    var expresion_regular_dni = /^[XYZ]?\d{5,8}[A-Z]$/;

    dniornie = dniornie.toUpperCase();

    if(expresion_regular_dni.test(dniornie) === true){
        numero = dniornie.substr(0,dniornie.length-1);
        numero = numero.replace('X', 0);
        numero = numero.replace('Y', 1);
        numero = numero.replace('Z', 2);
        letr = dniornie.substr(dniornie.length-1, 1);
        numero = numero % 23;
        letra = 'TRWAGMYFPDXBNJZSQVHLCKET';
        letra = letra.substring(numero, numero+1);
        if (letra != letr) {
            //alert('Dni erroneo, la letra del NIF no se corresponde');
            return false;
        }else{
            //alert('Dni correcto');
            return true;
        }
    }else{
        //alert('Dni erroneo, formato no válido');
        return false;
    }
}

export const getProductPrice = (pvp : number, discounts: Discount[]) : number => {
    return CalculateDiscount(pvp, discounts);
}

export const CalculateDiscountWithSteps = (pvp : number, discounts : Discount[], steps: number) : number => {
    let amount = pvp;

    if (discounts){
        discounts.filter(discount => discount.discountType === "fixed")?.map(discount => {
            amount -= (discount.amount / steps);
        })
    
    
        discounts.filter(discount => discount.discountType === "percent")?.map(discount => {
            amount -= amount * (discount.amount / 100);
        })
    }

    if (amount < 0){
        amount = pvp;
    }
    

    return Math.round((amount * 100)) / 100;
}

export const CalculateDiscount = (pvp : number, discounts : Discount[]) : number => {
    let amount = pvp;

    if (discounts){
        discounts.filter(discount => discount.discountType === "fixed")?.map(discount => {
            amount -= discount.amount;
        })
    
    
        discounts.filter(discount => discount.discountType === "percent")?.map(discount => {
            amount -= amount * (discount.amount / 100);
        })
    }

    if (amount < 0){
        amount = pvp;
    }
    

    return Math.round((amount * 100)) / 100;
}

export const filterDiscounts = (availableDiscounts: Discount[], productType : 'OnePay' | 'Subscription' | 'PayPerUse' | '', productIds? : number[], sportCenterId? : number) : any =>{
    let discounts : Discount[] = [];

    console.log("filterDiscounts", availableDiscounts, productType, productIds, sportCenterId);

    if (availableDiscounts){
        
        discounts = availableDiscounts.filter(
            discount => {
                let result : boolean = false;
                
                if (discount.productType == productType && discount.idProduct){
                    // El descuento está asociado a un producto concreto
                    if (discount.idSportCenter){
                        // El descuento está asociado a un producto concreto y está asociado a un centro deportivo concreto
                        result = productIds?.indexOf(discount.idProduct) !== -1 && discount.idSportCenter == sportCenterId;
                    }else{
                        // El descuento está asociado a un producto pero no a un centro deportivo
                        result = productIds?.indexOf(discount.idProduct) !== -1  && !discount.idSportCenter;
                    }
                }else if(!discount.productType){
                    // El descuento no está asociado a un producto concreto
                    if (discount.idSportCenter){
                        // El descuento está asociado a un centro deportivo pero no a un producto
                        result = !discount.idProduct && discount.idSportCenter == sportCenterId;
                    }else{
                        // el descuento no está asociado ni a un centro deportivo ni a un producto concreto
                        result = true;
                    }
    
                }
    
                return result;
            });
    }

    return discounts;
}