import React, { useState, useContext, useEffect } from 'react'
import { Row, Col, Typography, Form, Select, Input, Button, notification } from 'antd'
import { MailOutlined } from '@ant-design/icons'
import TextArea from 'antd/lib/input/TextArea'
import RestApiClient from '../../api/restApiClient';
import { AppCfgContext } from '../../hooks/context/appConfigContext'
import { LoginContext } from '../../hooks/context/userContext'

import ParamsApi from '../../models/api/ParamsApi';

import './contactForm.scss'
import ApiResponse from '../../models/api/ApiResponse';

const { Title } = Typography

const { Option } = Select
 
interface ContactFormProps{}

const ContactFormComponent = (props : ContactFormProps) : JSX.Element => {
    const loginContext = useContext(LoginContext);
    const appConfigContext = useContext(AppCfgContext);

    const restApiClient : RestApiClient = new RestApiClient();
    const [ form ] = Form.useForm();

    const [techEmail, setTechEmail] = useState<string>('');
    const [deportiveEmail, setDeportiveEmail] = useState<string>('');
    const [inscriptionEmail, setInscriptionEmail] = useState<string>('');

    const onFinish = () : void => {
        const formData = form.getFieldsValue();


        const params : ParamsApi = {
            query: {
                ... formData,
                sessionId: loginContext.sessionId
            }
        }

        restApiClient.fetch("SendContactEmail", params).then((r : ApiResponse | null) => {
            if (r && r.code === 200){
                form.resetFields();
            }
        });

        
    }

    useEffect(()=>{
        setTechEmail(appConfigContext.cfg.emailTechnicalSupport);
        setInscriptionEmail(appConfigContext.cfg.emailTechnicalSupport);;
        setDeportiveEmail(appConfigContext.contactEmail);
;    }, []);

    return (
        <Row gutter={16} className="contact-form">
            <Col xs={24}>
                <Title className="c-title"  level={3}>
                    <MailOutlined /> &nbsp;
                    Formulario de contacto
                    <small className="c-sub-title">
                        Siempre cerca de tí
                    </small>
                </Title>
            </Col>
            <Col xs={24}>
                <Form layout="horizontal" size="small"  form={form} onFinish={onFinish}>
                    <Form.Item
                        name="to"
                        className="form-item"
                        rules={[{required: true, message: 'Este campo es obligatorio'}]}
                    >
                        <Select placeholder="Indica el tipo de consulta">
                            <Option value={inscriptionEmail}>Consulta sobre inscripción (próximos pagos, cobros, etc)</Option>
                            <Option value={deportiveEmail}>Consulta deportiva (horarios, eventos, etc)</Option>
                            <Option value={techEmail}>Consulta técnica sobre el uso de la web</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="subject"
                        className="form-item"
                        rules={[{required: true, message: 'Este campo es obligatorio'}]}
                    >
                        <Input placeholder="Indica un asunto"/>
                    </Form.Item>
                    <Form.Item
                        name="body"
                        className="form-item"
                        rules={[{required: true, message: 'Este campo es obligatorio'}]}
                    >
                        <TextArea rows={5} placeholder="Escribe tu aquí tu consulta"/>
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" className="btn-send-question">
                            Enviar
                        </Button>
                    </Form.Item>
                </Form>
            </Col>
        </Row>
    )
}

export default ContactFormComponent;
