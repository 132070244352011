import EndPointConfig from '../../models/api/EndPointConfig'

const controller = 'App'

export const AppEP : EndPointConfig[] =[
    new EndPointConfig()
        .setMethod('GET')
        .setController(controller)        
        .setActionName("GetAppConfig"),
]


