import React, {useContext, useEffect, useState} from 'react'
import { Row, Col, Typography, Button, Tooltip } from 'antd'
import { AlertOutlined, ContainerOutlined, ContainerTwoTone } from '@ant-design/icons'

import * as AlertModels from '../../models/Alerts'


import './alertsComponent.scss'
import moment from 'moment'
import 'moment/locale/es' 

import ApiResponse from '../../models/api/ApiResponse'
import RestApiClient from '../../api/restApiClient'
import { LoginContext } from '../../hooks/context/userContext'
import ParamsApi from '../../models/api/ParamsApi'

const { Text, Title } = Typography

interface AlertsProps{}

const AlertsComponent = (props : AlertsProps) : JSX.Element => {
    const loginContext = useContext(LoginContext);
    const restApiClient : RestApiClient = new RestApiClient();

    const [alerts, setAlerts] = useState<AlertModels.default[]>([])

    const getTextStatus = (status : 'unread' | 'read') : string => {
        let result : string = '';

        switch(status){
            case 'unread':
                result = 'Nuevo';
                break;
            case 'read':
                result = 'Leído';
                break;
            default:
                result = '';
                break;
        }

        return result; 
    }

    const setMark = (alertId: number) : void => {
        const params : ParamsApi = {
            path: {
                sessionId: loginContext.sessionId,
                alertId: alertId,
            }
        }

        restApiClient.fetch("MarkReaded", params).finally(getMyAlerts);
    }

    const getMyAlerts = () : void => {
        const params : ParamsApi = {
            query: {
                sessionId: loginContext.sessionId,
                userId: loginContext.id,
            }
        }
        
        restApiClient.fetch("GetUserAlerts", params).then((r : ApiResponse | null)=> {
            if (r && r.data){
                setAlerts(r.data);
                let notRead = r.data.filter((d : AlertModels.default) => d.status === "unread").length;
                loginContext.notificationNumber = notRead;
            }
        });
    }

    useEffect(()=> {
        getMyAlerts();

        setInterval(getMyAlerts, 20000);
    }, []);

    return (
        <Row gutter={16} className="my-alerts">
            <Col xs={24}>
                <Title className="c-title"  level={3}>
                    <AlertOutlined /> &nbsp;
                    Alertas
                    <small className="c-sub-title">
                        Porque la información es importante
                    </small>
                </Title>
            </Col>
            {
                alerts.map((alert: AlertModels.default) => {
                    return(
                        <Row className="alert-info" gutter={{xs: 24}} key={alert.id}>
                            <Col xs={12} className={`alert-status ${alert.status}`}>
                            Estado: <span> {getTextStatus(alert.status)} </span>
                            {alert.notifyInEmail ? 
                                <Tooltip title="Esta alerta ha sido notificada también por email.">
                                    <ContainerTwoTone />
                                </Tooltip>
                                : 
                                ''
                            }
                            </Col>
                            <Col xs={12} className="alert-date">
                            {
                                <Text type="secondary">
                                     {moment(alert.creationDate).locale("es").fromNow()}
                                </Text>
                               
                            }
                            </Col>
                            <Col xs={24} className="alert-title">
                            {
                                alert.description
                            }
                            </Col>
                            {
                                alert.status === 'unread' 
                                ? 
                                <Col xs={24} className="alert-btn-accept">
                                    <Button type="dashed" size="small" onClick={()=> setMark(alert.id)}>Aceptar</Button>
                                </Col>
                                : 
                                <Col xs={24} className="alert-btn-accept">
                                    <Text disabled>Esta alerta fue leída el {moment(alert.readDate).locale("es").format("DD/MM/YYYY [a las] HH:mm:ss [horas]")}</Text>
                                </Col>
                            }
                            
                        </Row>
                    )
                })
            }
        </Row>
    )
}

export default AlertsComponent;
