import React, { useEffect, useState } from 'react'
import { PageHeader, Button, Row, Col, Descriptions } from 'antd';
import { LikeOutlined, StarOutlined } from '@ant-design/icons';

import * as Components from '../../components'

import './routine-detail-header.scss'
import { useHistory } from 'react-router-dom';
import Routine from '../../models/Routines';

import moment from "moment";

  interface RoutineDetailHeaderProps {
      routineData?: Routine  
  }

  const RoutineDetailHeaderComponent = (props: RoutineDetailHeaderProps) : JSX.Element => {

    const [routine, setRoutine] = useState<Routine>();

    const history = useHistory();

    const goDashboard = ():void => {
        history.push("/dashboard");
    }

    useEffect(()=>{
        setRoutine(props.routineData);
    }, [props.routineData])

    return(
        <PageHeader
            className="routine-detail-header"
            ghost={false}
            onBack={goDashboard}
            title="Abandonar"
            subTitle="Calentamiento"
            extra={[
                <div className="routine-actions"><StarOutlined className="routine-action-button star"/>
                <LikeOutlined className="routine-action-button like"/>
                <Button key="1" type="primary">
                    Comentar
                </Button></div>,
            ]}
            >
            <Descriptions size="small" column={{ xxl: 4, xl: 4, lg: 4, md: 2, sm: 2, xs: 1 }} bordered>
                <Descriptions.Item label="Creado por" labelStyle={{fontWeight:600}}>
                    { `${routine?.creator.name} ${routine?.creator.surname} ${routine?.creator.secondSurname}` }
                </Descriptions.Item>
                <Descriptions.Item label="Fecha de creación" labelStyle={{fontWeight:600}}>
                    { `${moment(routine?.creator.creationDate).format("DD/MM/YYYY")}` }
                </Descriptions.Item>
                <Descriptions.Item label="Ejercicios" labelStyle={{fontWeight:600}}>
                    { `${routine?.exercises.length}` }
                </Descriptions.Item>
                <Descriptions.Item label="Tiempo total" labelStyle={{fontWeight:600}}>
                    { `${routine?.totalRutine}` }
                </Descriptions.Item>
                <Descriptions.Item label="Descripción" labelStyle={{fontWeight:600}}>
                    { `${routine?.description}` }
                </Descriptions.Item>
            </Descriptions>
            </PageHeader>
    )
  }

  export default RoutineDetailHeaderComponent;