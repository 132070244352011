import React, { useEffect, useState } from 'react'

import DashboardHelperComponent from './helpers/dashboardHelper'
import LoginHelperComponent from './helpers/loginHelper'

import './helper.scss'


interface HelperProps {
    location: "login" | "dashboard",
    extendedProps?: any[]
}

const HelperComponent = (props : HelperProps) : any => {

    const [helperBalls, setHelperBalls] = useState<JSX.Element>(<LoginHelperComponent />)

    useEffect(()=> {
        if (props.location === "login"){
            setHelperBalls(<LoginHelperComponent />);
        }else{
            setHelperBalls(<DashboardHelperComponent />)
        }
    }, [])


    return (
        <>{helperBalls}</>
    )
}

export default HelperComponent;