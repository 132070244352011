import React, { useEffect, useState } from 'react'
import { Alert, Typography } from 'antd'



import './enrollments.finantial.details.scss'
import moment from 'moment';
import Enrollment from '../../models/Enrollments';


const { Title } = Typography;

interface EnrollmentFinantialDetails{
    enrollment: Enrollment,
}

const EnrollmentFinantialDetailsComponent = (props: EnrollmentFinantialDetails) : JSX.Element => {
    const { enrollment } = props;
    const [totalAmount, setTotalAmount] = useState<number>(0)


    useEffect(()=>{
        let total : number = 0;
        if (enrollment && enrollment.financingData){
            enrollment.financingData.map(data => {
                total += data.amount;
            });

            setTotalAmount(Math.round(total));
        }
    },[enrollment]);

    return (
        <div className="financing-data">
            <Title level={5} className="total-amount">
                Pagará un total de {totalAmount}€<sup>*</sup> por la inscripción.
            </Title>
             <ul>
                 {
                     enrollment.financingData?.map((data, index) => {
                         const amountDiscount = enrollment.meta.find(meta => meta.key === "stripe_coupon_amount")?.value;
                         let result : JSX.Element = <li className={`${moment(data.payDate).isBefore(moment()) ? 'payed' : ''}`} key={index}>
                                                        Pago de <strong>{data.amount}€</strong> el <strong>{moment(data.payDate).format("DD/MM/YYYY")}</strong>
                                                        {amountDiscount ? <small className="discount-apply">(Aplicado un {amountDiscount})% de descuento)</small> : ''}
                                                    </li>

                         if (data.isInitialPay){
                            result = <li className={`initial-pay ${moment(data.payDate).isBefore(moment()) ? 'payed' : ''}`} key={index}>
                                Pago inicial de <strong>{data.amount}€</strong> el <strong>{moment(data.payDate).format("DD/MM/YYYY")}</strong>
                            </li>;
                         }

                         return result;
                     })
                 }
             </ul>
             <Alert
                message="Nota"
                description={<p className="advise">Las cantidades mostradas pueden variar en céntimos debido al redondeo.</p>}
                type="warning"
                showIcon
                closable
                />
                <br/>
                <Alert
                message="Nota"
                description={<p className="advise">Algunos pagos pueden no coincidir con las fechas indicadas si el pago fue rechazado por su entidad bancaria y el pago se realiza posteriormente. Puede var más detalle de los pagos ya realizados en el detalle de su inscripción.</p>}
                type="warning"
                showIcon
                closable
                />
        </div>
    )
}


export default EnrollmentFinantialDetailsComponent;