import React from 'react';

import { Result, Button } from 'antd';
import { SmileOutlined } from '@ant-design/icons';

interface SmileyComponentProps{
    text: string,
    action: any
    buttonText: string
}

const SmileyComponent = (props: SmileyComponentProps) : JSX.Element => {

    return (
        <Result
            icon={<SmileOutlined />}
            title={props.text}
            extra={<Button type="primary" onClick={props.action}>{props.buttonText}</Button>}
        />
    );

}


export default SmileyComponent;