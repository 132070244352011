import React,  { useState, useEffect } from 'react';
import ProductInfo from '../../../../models/ProductInfo';
import { Col , Typography, Checkbox, List, Button, Spin, Result } from 'antd'
import { QuestionCircleOutlined, TrophyOutlined, RocketOutlined, EuroCircleOutlined, LoadingOutlined } from '@ant-design/icons'
import parse from 'html-react-parser';

import moment from 'moment'
import 'moment/locale/es' 
import { Discount } from '../../../../models/Discount';


import './availableProductComponent.scss';
import { filterDiscounts, getProductPrice } from '../../../../utils/ValidationsUtils';

const { Title } = Typography


interface AvailableProductComponentInterface{
    product: ProductInfo,
    id:string
    onPay: any,
    isloading: boolean,
    setselected: any,
    selected: number,
    termnsandconditions: any,
    discounts: Discount[],
    idSportCenter: number
}

const AvailableProductComponent = (props: AvailableProductComponentInterface) : JSX.Element => {

    const [product, setProduct] = useState<ProductInfo>();
    const [icon, setIcon] = useState<JSX.Element>();
    const [ enablePayMode, setEnablePayMode] = useState<boolean>(false);
    const [ loadingPayment, setLoadingPaymnt ] = useState<boolean>(false);
    const [ discounts, setDiscounts ] = useState<Discount[]>([])

    const getFeatures = () => {
        return product?.features.map((feature, index) => {
            return <small className="feature" key={index}>{feature}</small>
        });
    }

    const pay = () => {
        props.onPay();
    }

    const getIntervalString = (interval: number, intervalCount: number) : string => {
        let result : string = '';
        switch(interval){
            case 0:
                
                result = `dia${intervalCount > 1 ? '(s)' : ''}`
            break;
            case 1:
                result = `semana${intervalCount > 1 ? '(s)' : ''}`
            break;
            case 2:
                result = `mes${intervalCount > 1 ? '(es)' : ''}`
            break;
            case 3:
                result = `año${intervalCount > 1 ? '(s)' : ''}`
            break;
        }

        return result;
    }

    const isChecked = (product: ProductInfo) => {
        props.setselected(product.id); 
        props.termnsandconditions(product.termsAndConditions)

    }

    

    useEffect(()=> {
        setLoadingPaymnt(props.isloading);
    }, [props.isloading]);

    useEffect(()=>{
        setProduct(props.product);

        switch(props.product.type){
            case "OnePay":
                setIcon(<TrophyOutlined className="icon" />);
                break;
            case "PayPerUse":
                setIcon(<EuroCircleOutlined className="icon" />);
                break;
            case "Subscription":
                setIcon(<RocketOutlined className="icon" />);
                break;
            default:
                setIcon(<QuestionCircleOutlined  className="icon" />)
        }
    }, [props.product]);

    useEffect(()=>{
        if (props.discounts){
            setDiscounts(props.discounts);
        }else{
            setDiscounts([]);
        }
    },[props.discounts])


    const getPrice = () : number => {
        if (product !== undefined){
            return getProductPrice(product.pvp /100, filterDiscounts(discounts, product.type, [product.id], props.idSportCenter));
        }else{
            return 9999; //No debería pasar nunca por aquí pero así asustamos si falla algo :D
        }
    }

    return (
        <>
            {
                product === undefined ?
                'invalid product data'            
                :
                <Col xs={24} md={{span:8}} lg={{span:7, offset:0}} xl={{span:6, offset:1}} 
                                        id={props.id} 
                                        className="cart-option one-pay-mode">
                    
                    <div className="available-product">
                        {icon}

                        <Title level={2} className="title">{product.name}</Title>
                        <small className="sub-title">{product.description}</small>
                        <Title level={1} className="price">€{getPrice()}<sup>/{getIntervalString(product.interval, product.intervalCount)}</sup></Title>
                        {
                            discounts && discounts.length > 0 && discounts.some(discount => discount.idProduct === null || (discount.idProduct !== null && discount.idProduct == product.id))? 
                            <span className="discount-previous-amount">
                                Precio antes de descuentos €{product.pvp /100}<sup>/{getIntervalString(product.interval, product.intervalCount)}</sup>
                            </span> : ''
                        }
                        {
                            filterDiscounts(discounts, product.type, [product.id], props.idSportCenter)?.map((discount : Discount, index: number) => {
                                return <span className="discount-description" key={index}>{discount.discountDescription}</span>
                            })
                        }
                        <List
                            size="small"
                            header={<div>Características</div>}
                            dataSource={
                            [
                                product.initialPay > 0 ? parse(`Pago inicial de <b>${product.initialPay}</>€`) : '',
                                parse(product.type === "OnePay" ? `Pago único de <b>${getPrice()}€</b>` : `Cuota de <b>${getPrice()}€/${getIntervalString(product.interval, product.intervalCount)}</b>`), 
                                getFeatures(),
                                <Checkbox
                                    onChange={(e)=>{setEnablePayMode(e.target.checked); e.target.checked ? isChecked(product) : props.setselected(0)}}
                                >
                                    <a href="#" onClickCapture={(e)=>{e.preventDefault();}}>Acepto los términos y condiciones de esta opción de pago</a>
                                </Checkbox>
                            ]}
                            renderItem={item => <List.Item>{item}</List.Item>}
                            />
                            
                            
                    </div>
                    <div>
                    { 
                                product.fromDate !== undefined && moment(new Date()).isSameOrBefore(moment(product.fromDate))?
                                        <Button type="ghost" size="large" className="btn-pay danger">¡Disponible {moment(moment(product.fromDate)).from(new Date())}!
                                            <small style={{display:"block", fontSize: "12px"}}>A partir del {moment(product.fromDate).format("DD/MM/YYYY")}</small>
                                        </Button>
                                    :
                                    (
                                        loadingPayment && props.selected === product.id ?
                                            <Button type="primary" size="large" className="btn-pay" >
                                                <Spin indicator={<LoadingOutlined style={{ fontSize: 24, color:"white", marginRight: "5px" }} spin />} />
                                                Realizando pago, 
                                                <small style={{display:"block"}}>espere, por favor...</small>
                                            </Button> 
                                        :
                                            <Button disabled={!enablePayMode } type="primary" size="large" className="btn-pay" onClick={pay}>
                                            
                                                {
                                                    product.initialPay > 0 ?
                                                    <>¡Pagar {product.initialPay}€ y luego {getPrice()}€ al {getIntervalString(product.interval, product.intervalCount)}!</>
                                                    :
                                                    <>¡Pagar {getPrice()}€!</>
                                                }
                                                {
                                                    product.toDate !== null && product.toDate !== undefined ? 
                                                    <small style={{fontSize:"10px", display:"block"}}>Método de pago disponible hasta: {moment(product.toDate).format("DD/MM/YYYY")}</small>
                                                    :
                                                    ''
                                                }
                                            </Button> 
                                    )
                            }
                    </div>
                </Col>
            }
        </>
    );
}

export default AvailableProductComponent;