import React, { useEffect, useState, useRef } from 'react'
import { useParams } from 'react-router-dom'

import * as Components from '../../components/index'
import Routine from '../../models/Routines';
import RoutineDetailHeaderComponent from './routine-detail-header'
import { Carousel  } from 'antd'
import User from '../../models/Users';
import RoutineHelperComponent from '../../components/helper/helpers/routineHelper';

interface RoutineDetailPageProps{}


const routineDemo : Routine = {
    id: 1,
    title: 'Calentamiento',
    creationDate: new Date(),
    creator: {
        isLoged: false,
        idRol: 'trainer',
        avatar: 'https://i.pravatar.cc/300?u=a042581f4e29026704e',
        isDummyAccount: false, 
        name: 'Fabián',
        surname: 'Plaza',
        secondSurname: 'Arnau',
    } as User,    
    description: 'Ejercicios de calentamiento que servirán para que tu cuerpo entre en calor y no te lesiones cuando estés dándolo todo en la pista.',
    exercises:[
        {
            order: 1,
            name:"Marcha",
            durationTimeSeconds: 10,
            reposeTimeSeconds: 30,
            urlVideo: 'MY_gyv3ZDLE', //MY_gyv3ZDLE
            series: 1,
            repetitionsBySerie: "1 min en la misma posición. 1 min adelante, 1 min hacia atrás",
            description: "Comenzar marchando en una misma posición, luego hacia adelante y hacia atrás, moviendo los brazos hacia arriba y hacia abajo al ritmo de los pasos;<br/><br/>Comenzar marchando en una misma posición, luego hacia adelante y hacia atrás, moviendo los brazos hacia arriba y hacia abajo al ritmo de los pasos;Comenzar marchando en una misma posición, luego hacia adelante y hacia atrás, moviendo los brazos hacia arriba y hacia abajo al ritmo de los pasos;Comenzar marchando en una misma posición, luego hacia adelante y hacia atrás, moviendo los brazos hacia arriba y hacia abajo al ritmo de los pasos;<br/><br/>Comenzar marchando en una misma posición, luego hacia adelante y hacia atrás, moviendo los brazos hacia arriba y hacia abajo al ritmo de los pasos;Comenzar marchando en una misma posición, luego hacia adelante y hacia atrás, moviendo los brazos hacia arriba y hacia abajo al ritmo de los pasos;Comenzar marchando en una misma posición, luego hacia adelante y hacia atrás, moviendo los brazos hacia arriba y hacia abajo al ritmo de los pasos;",
        },
        {
            order: 2,
            name:"Talón",
            durationTimeSeconds: 190,
            reposeTimeSeconds: 30,
            urlVideo: 'jWZcScK9DFQ',
            series: 2,
            repetitionsBySerie: "15 segundos con cada pierna",
            description: "Adelanta una pierna con el talón apoyado en el suelo y el pie hacia arriba, manteniendo una ligera curva en la pierna de apoyo y los brazos elevados perpendiculares al cuero y los puños cerrados",
        },
        {
            order: 3,
            name:"Elevación de rodilla",
            durationTimeSeconds: 190,
            reposeTimeSeconds: 30,
            series: 2,
            urlImage:"https://www.iberdrola.com/wcorp/gc/prod/es_ES/comunicacion/deporte_en_casa_2_res/Deporte_en_Casa.jpg",
            repetitionsBySerie: "15 segundos con cada pierna",
            description: "Adelanta una pierna con el talón apoyado en el suelo y el pie hacia arriba, manteniendo una ligera curva en la pierna de apoyo y los brazos elevados perpendiculares al cuero y los puños cerrados",
        },
        {
            order: 4,
            name:"Giro hombros",
            durationTimeSeconds: 190,
            reposeTimeSeconds: 30,
            series: 2,
            repetitionsBySerie: "15 segundos con cada pierna",
            description: "Adelanta una pierna con el talón apoyado en el suelo y el pie hacia arriba, manteniendo una ligera curva en la pierna de apoyo y los brazos elevados perpendiculares al cuero y los puños cerrados",
        },
        {
            order: 5,
            name:"Doblar rodillas",
            durationTimeSeconds: 190,
            reposeTimeSeconds: 30,
            series: 2,
            repetitionsBySerie: "15 segundos con cada pierna",
            description: "Adelanta una pierna con el talón apoyado en el suelo y el pie hacia arriba, manteniendo una ligera curva en la pierna de apoyo y los brazos elevados perpendiculares al cuero y los puños cerrados",
        }
    ],
    totalRutine: "6 minutos",
    starts: 123,
    likes: 300,
    comments: 2,
}


const RoutineDetailPage = (props : RoutineDetailPageProps) => {

    const ref = useRef();

    const { id } = useParams<any>();
    const [routine, setRoutine] = useState<Routine>();
    const [ carouselIndex, setCarouselIndex] = useState<number>(0);
    const [ controls, setControls ] = useState<boolean>(true);
    const [ isFirstTime, setIsFirstTime ] = useState<boolean>(true);
    const [ showTrainer, setShowTrainer ] = useState<boolean>(false);

    const onCarouselChange = (currentSlide: number) : void =>{
        setCarouselIndex(currentSlide);
        showAndHideControls(true);
        setShowTrainer(false);
    }

    const showAndHideControls = (visible: boolean) : void =>{
        if (!visible && isFirstTime){
            setIsFirstTime(false);
        }else{
            setControls(visible);
        }
    }

    const startTrainer = () : void => {
        setShowTrainer(true);
    }

    useEffect(()=>{
        setRoutine(routineDemo);
        setControls(true);
    }, [id]);

    

    return (
        <Components.Container>
            
                <RoutineDetailHeaderComponent routineData={routine}/>

            { 
                showTrainer && routine ? 
                    <Components.Counter 
                        title={routine.title} 
                        subtitle={routine.exercises[carouselIndex].name} 
                        seconds={routine.exercises[carouselIndex].durationTimeSeconds} 
                        order={routine.exercises[carouselIndex].order*1} 
                        series={routine.exercises[carouselIndex].series*1}
                    /> 
                :
                    ''
            }

                <Carousel className="carousel" 
                            style={{background: "#364d79", minHeight:"300px", paddingBottom:"40px"}} 
                            slide={carouselIndex+''} 
                            beforeChange={()=>{showAndHideControls(false)}}
                            afterChange={onCarouselChange}>
                        {
                            routine?.exercises.map((exercice, index) => {
                                return <div className="carousel-item" key={index}>
                                            <Components.ExerciceBox exercice={exercice} active={ false } />
                                        </div>
                            })
                        }
                </Carousel>

                {
                    controls 
                    ?
                        <RoutineHelperComponent 
                        onPlay={startTrainer} 
                        onInit={()=>{alert("onInit")}} 
                        onPause={()=>{alert("onPause")}} 
                        onNext={()=>{alert("onNext")}} 
                        onPrev={()=>{alert("onPrev")}} 
                        
                    />
                    :
                    ''
                }
                
        </Components.Container>
    )
}

export default RoutineDetailPage;