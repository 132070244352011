import React, { useContext, useState } from 'react'
import { Row, Col, Form, Input, Typography, Button } from 'antd'


import './profileComponent.scss'
import { MailOutlined } from '@ant-design/icons';

import { LoginContext } from '../../hooks/context/userContext'

import RestApiClient from '../../api/restApiClient';
import ParamsApi from '../../models/api/ParamsApi';
import ApiResponse from '../../models/api/ApiResponse';

const { Title } = Typography;

interface ProfileComponentProps{}

const ProfileComponent = (props : ProfileComponentProps) : JSX.Element => {
    const loginContext = useContext(LoginContext);
    
    const [form] = Form.useForm();

    const restApiClient : RestApiClient = new RestApiClient();

    const onFinish = () : void => {
        let formValues : any = form.getFieldsValue();
        delete formValues.email;
        formValues.userId = loginContext.id;
        formValues.sessionId = sessionStorage.getItem("token");
        const paramsApi : ParamsApi = {
            body: formValues
        }

        restApiClient.fetch("UpdateUserProfile", paramsApi).then((r : ApiResponse | null)=> {
            if (r?.code === 200){
                // Limpiamos el formulario
                updateLoginContextData();
                
            }
        });
    }

    const updateLoginContextData = () => {
        let formValues : any = form.getFieldsValue();
        loginContext.name = formValues.name;
        loginContext.surname = formValues.surname;
        loginContext.secondSurname = formValues.secondSurname;
        loginContext.dninie = formValues.dni_nie;
        if (loginContext.contactData){
            loginContext.contactData.phone = formValues.phone1;
            loginContext.contactData.alternativeEmail = formValues.alternativeEmail;
            loginContext.contactData.phone2 = formValues.phone2;
            loginContext.contactData.address = formValues.address;
        }
        sessionStorage.setItem("user-data", JSON.stringify(loginContext));
    }


    return (
        <Row gutter={16} className="profile">
            <Col xs={24}>
                <Title className="c-title"  level={3}>
                    <MailOutlined /> &nbsp;
                    Perfil
                    <small className="c-sub-title">
                        Esto es lo que sabemos sobre tí
                    </small>
                </Title>
            </Col>
            <Col xs={24}>
                <Form layout="vertical" form={form} onFinish={onFinish}>
                    <Row gutter={16}>
                        <Col xs={24} lg={12}>
                            <Title level={5}>
                                Datos personales
                            </Title>
                            <Form.Item
                                name="name"
                                label="Nombre"
                                className="form-item"
                                initialValue={loginContext.name}
                                rules={[{required: true, message: 'Este campo es obligatorio'}]}
                            >
                                <Input placeholder="Indica un tu nombre"/>
                            </Form.Item>
                            <Form.Item
                                name="surname"
                                label="Primer apellido"
                                className="form-item"
                                initialValue={loginContext.surname}
                                rules={[{required: true, message: 'Este campo es obligatorio'}]}
                            >
                                <Input placeholder="Indica un tu primer apellido"/>
                            </Form.Item>
                            <Form.Item
                                name="secondSurname"
                                label="Segundo apellido"
                                initialValue={loginContext.secondSurname}
                                className="form-item"
                            >
                                <Input placeholder="Indica un tu segundo apellido"/>
                            </Form.Item>
                            <Form.Item
                                name="dni_nie"
                                label="DNI/NIE"
                                initialValue={loginContext.dninie}
                                className="form-item"
                                rules={[{required: true, message: 'Este campo es obligatorio'}]}
                            >
                                <Input placeholder="Indica tu documento nacional de identidad o NIE" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12}>
                            <Title level={5}>
                                Datos de contacto
                            </Title>
                            <Form.Item
                                name="email"
                                label="Email"
                                className="form-item"
                                initialValue={loginContext.username}
                                tooltip="Si necesitas cambiar tu email ponte en contacto con nosotros"
                                rules={[{ type: 'email' }]}
                            >
                                <Input placeholder="Indica tu correo electrónico" disabled/>
                            </Form.Item>
                            <Form.Item
                                name="alternativeEmail"
                                label="Email secundario"
                                className="form-item"
                                initialValue={loginContext.contactData?.alternativeEmail}
                                tooltip="Añade un segundo email para recibir notificaciones"
                                rules={[{ type: 'email' }]}
                            >
                                <Input placeholder="Indica un correo electrónico alternativo"/>
                            </Form.Item>
                            <Form.Item
                                name="phone1"
                                label="Teléfono 1"
                                initialValue={loginContext.contactData?.phone}
                                className="form-item"
                                rules={[{required: true, message: 'Este campo es obligatorio'}, {max: 9, message: 'El teléfono no puede superar los 9 caracteres'}, {min: 9, message: 'El teléfono no puede tener menos de 9 caracteres'}]}
                            >
                                <Input placeholder="Indica un teléfono de contacto"/>
                            </Form.Item>
                            <Form.Item
                                name="phone2"
                                label="Teléfono 2"
                                initialValue={loginContext.contactData?.phone2}
                                rules={[{max: 9, message: 'El teléfono no puede superar los 9 caracteres'}, {min: 9, message: 'El teléfono no puede tener menos de 9 caracteres'}]}
                                className="form-item"
                            >
                                <Input placeholder="Indica un teléfono de contacto alternativo"/>
                            </Form.Item>
                            
                            <Form.Item
                                name="address"
                                label="Dirección"
                                initialValue={loginContext.contactData?.address}
                                className="form-item"
                                rules={[{required: true, message: 'Este campo es obligatorio'}]}
                            >
                                <Input placeholder="Indica tu dirección"/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col xs={24} lg={12}>
                            <Title level={5}>
                                Cambio de password
                            </Title>
                            <Form.Item
                                name="password"
                                label="Password"
                                className="form-item"
                                tooltip="Si no quieres cambiar tu contraseña deja el campo vacío y se mantendrá la que tienes actualmente"
                                rules={
                                    [
                                        ({ getFieldValue }) => ({
                                            validator(rule, value) {
                                               const confirmPassword = getFieldValue('confirmPassword');

                                               if(value !== confirmPassword){
                                                    // yo también soy obligatorio
                                                    return Promise.reject("Debe rellenar los dos passwords");
                                                }
        
                                               
                                               return Promise.resolve();
                                              
                                            },
                                          })
                                    ]
                                }
                            >
                                <Input.Password onChange={()=>{form.validateFields(["confirmPassword"])}} placeholder="Deja en blanco para mantener el actual" />
                            </Form.Item>
                            <Form.Item
                                name="confirmPassword"
                                label="Repite tu password"
                                className="form-item"
                                rules={
                                    [
                                        ({ getFieldValue }) => ({
                                            validator(rule, value) {
                                               const password = getFieldValue('password');

                                               if(value !== password){
                                                    // yo también soy obligatorio
                                                    return Promise.reject("Debe rellenar los dos passwords");
                                                }
        
                                               
                                               return Promise.resolve();
                                              
                                            },
                                          })
                                    ]
                                }
                            >
                                <Input.Password onChange={()=>{form.validateFields(["password"])}} placeholder="Deja en blanco para mantener el actual" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col xs={24} className="form-buttons">
                            <Button type="primary" htmlType="submit">
                                Actualizar
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Col>
        </Row>
    )
}

export default ProfileComponent;