import { UsersEP } from './UsersEP'
import { TestEP } from './testEP'
import { AthletesEP } from './AthletesEP'
import { EnrollmentsEP } from './EnrollmentsEP'
import { SeasonsEP } from './SeasonsEP'
import { SportCentersEP } from './SportCentersEP'
import { ProductsEP } from './ProductsEP'
import { AlertsEP } from './AlertsEP'
import { AppEP } from './AppEP'
import { PaymentsEP } from './PaymentsEP'
import { DiscountsEP } from './DiscountsEP'
import { GroupsEP } from './GroupsEP'
import {DocumentsEP} from './DocumentsEP'

export const RegisterEndPoints = []
    .concat(UsersEP)
    .concat(TestEP)
    .concat(AthletesEP)
    .concat(EnrollmentsEP)
    .concat(SeasonsEP)
    .concat(SportCentersEP)
    .concat(ProductsEP)
    .concat(AlertsEP)
    .concat(PaymentsEP)
    .concat(DiscountsEP)
    .concat(GroupsEP)
    .concat(DocumentsEP)
    .concat(AppEP);