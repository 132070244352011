import React from 'react'

import { Col, Row } from 'antd'

interface LockScreenProps{}

const LockScreenPage = (props : LockScreenProps) => {

    return(
        <Row>
            <Col xs={24}>
                Ventana de bloqueo
            </Col>
        </Row>
    )
}

export default LockScreenPage;